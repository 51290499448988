<div class="car-chooser-teaser page-wrapper">
    <div class="silhouette">
        <img *ngIf="!vehicleSilhouette"
             src="ui/assets/img/silhouette-placeholder.jpg" alt="vehicle_fallback_image"
             loading="lazy">
        <!-- TODO: Remove 'is-scaled' class, once AMG images are corrected -->
        <img *ngIf="vehicleSilhouette"
             [ngClass]="{'is-scaled': isSelectedTypeAmg}"
             #carImage
             [src]="environment.apiUrl + vehicleSilhouette.image"
             (error)="carImage.src='ui/assets/img/fallback-565.png'"
             [alt]="vehicleSilhouette.name"
             loading="lazy">
    </div>
    <div class="car-details">
        <div class="headline">
            <wb-heading size="xs" class="headline"
                        [ngClass]="{'has-fixed-height': isCarLogged && !hasVin, 'no-break': hasVin}">
                {{selectedCarName}}
            </wb-heading>

            <div [ngClass]="isSelectedTypeAmg ? 'headline-button is-amg' : 'headline-button'"
                 *ngIf="!isMobile && isCarLogged">
                <wb-icon name="bds/pencil/16" style="--size: 16px"></wb-icon>
                <wb-text variant="{{isCarLogged ? 'secondary' : 'primary'}}"
                         (click)="toggleCarChooserLayer()">
                    {{buttonText}}
                </wb-text>
            </div>
        </div>

        <wb-text *ngIf="!hasVin" size="m"
                 class="description" [ngClass]="{'is-link': isCarLogged}"
                 (click)="toggleCarChooserLayer(true)">
            <wb-icon *ngIf="isCarLogged" name="bds/arrow-right/24"></wb-icon>
            {{description}}
        </wb-text>

        <wb-text *ngIf="hasVin" class="logged-vin" strong>
            <wb-icon name="bds/checkmark-circle/16"></wb-icon>
            {{hasVin}}
        </wb-text>
    </div>

    <wb-button *ngIf="!isMobile && !isModelStartPage" class="action-button"
               variant="{{isCarLogged ? 'secondary' : 'primary'}}"
               (click)="isCarLogged ? openModalStartPage('header') : toggleCarChooserLayer()">
        {{ isCarLogged ? highlightButton : buttonText }}
    </wb-button>

    <wb-round-button *ngIf="!isCarLogged && isMobile"
                     size="s"
                     class="action-button-link"
                     (click)="toggleCarChooserLayer()">
        <wb-icon name="bds/chevron-right/16"></wb-icon>
    </wb-round-button>

    <wb-round-button *ngIf="isCarLogged && isMobile"
                     size="s"
                     class="action-button-link"
                     (click)="showAccordionMenu = !showAccordionMenu"
                     #mobileMenuTrigger>
        <wb-icon [name]="showAccordionMenu ? 'bds/chevron-up/16' : 'bds/chevron-down/16'"></wb-icon>
    </wb-round-button>

</div>

<ng-container *ngIf="showAccordionMenu && isMobile">
    <div class="mobile-menu" #mobileMenu>
        <div *ngIf="!isModelStartPage"
             class="meni-item"
             (click)="openModalStartPage('header-menu')">
            <wb-text size="l" strong static>{{ highlightButton }}</wb-text>
            <wb-round-button size="s">
                <wb-icon name="bds/chevron-right/24"></wb-icon>
            </wb-round-button>
        </div>
        <div *ngIf="!hasVin"
             class="meni-item"
             (click)="toggleCarChooserLayer(true)">
            <wb-text size="l" strong static>{{ description }}</wb-text>
            <wb-round-button size="s">
                <wb-icon name="bds/chevron-right/24"></wb-icon>
            </wb-round-button>
        </div>
        <div class="meni-item"
             (click)="toggleCarChooserLayer()">
            <wb-text size="l" strong static>{{ buttonText }}</wb-text>
            <wb-round-button size="s">
                <wb-icon name="bds/chevron-right/24"></wb-icon>
            </wb-round-button>
        </div>
    </div>
</ng-container>

<zk-car-chooser *ngIf="carChooserLayer"
                [isVinLayer]="vinLayer">
</zk-car-chooser>
