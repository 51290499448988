<ng-container *ngFor="let article of articles">
    <ng-container *ngIf="article.appMode === appMode.ACCESSORIES">

        <!-- STATE: Available for car or unchecked -->
        <zk-cart-item *ngIf="article.fit !== false"
                      [cartItem]="article"
                      [isInModal]="true"
                      [isCompatibilityCheck]="true"
                      [hideDeleteIcon]="true">
        </zk-cart-item>

        <!-- STATE: Not available for car -->
        <div *ngIf="article.fit === false"
             class="not-fitting-articles">

            <zk-cart-item class="article"
                          [cartItem]="article"
                          [isInModal]="true"
                          [isCompatibilityCheck]="true"
                          [hideDeleteIcon]="true">
            </zk-cart-item>

            <ng-container *ngIf="checkArticleGroupAvailability(article)">
                <wb-link class="alternative"
                         variant="standalone"
                         [routerLink]="buildAlternativeGroupUrl(article)"
                         (click)="closeLayer()">
                    <wb-icon name="bds/open-new/24" style="--size: 19px"></wb-icon>
                    <span zkTranslate="GENERAL.SEARCHALTERNATIVE"></span>
                </wb-link>
            </ng-container>
        </div>
    </ng-container>
</ng-container>
