// Modules - ng
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Modules - own
import { ComponentsModule } from '@shared/components/components.module';
import { HeaderModule } from '@shared/header/header.module';
import { FooterModule } from '@shared/footer/footer.module';
import { ModalModule } from '@shared/modals/modal.module';

// Services
import { SelectedCarService } from '@shared/shared-services/selected-car/selected-car.service';
import { TeaserService } from '@shared/shared-services/teaser/teaser.service';
import { ProductGroupsService } from '@shared/shared-services/product-groups/product-groups.service';
import { ShoppingCartService } from '@shared/shared-services/shopping-cart/shopping-cart.service';
import { CompatibilityCheckService } from '@shared/shared-services/compatibility/compatibility.service';
import { CurrentRouteService } from '@shared/shared-services/current-route/current-route.service';
import { SeoService } from '@shared/shared-services/seo/seo.service';
import { GroupMapperService } from '@shared/shared-services/group-mapper/group-mapper.service';
import { UrlParameterService } from '@shared/shared-services/url-parameters-service/url-parameter.service';
import { ProductListService } from '@shared/shared-services/product-list/product-list.service';
import { GoogleAnalyticsService } from '@shared/shared-services/google-analytics/google-analytics.service';
import { Co2Service } from '@shared/shared-services/co2-label/co2.service';
import { ViewportService } from "@shared/shared-services/viewport/viewport.service";

@NgModule({
    imports: [
        CommonModule,
        ComponentsModule,
        FooterModule,
        HeaderModule,
        ModalModule
    ],
    declarations: [
    ],
    exports: [
        ComponentsModule,
        FooterModule,
        HeaderModule,
        ModalModule
    ],
    providers: [
        SelectedCarService,
        TeaserService,
        ProductGroupsService,
        ShoppingCartService,
        CompatibilityCheckService,
        CurrentRouteService,
        SeoService,
        GroupMapperService,
        UrlParameterService,
        ProductListService,
        GoogleAnalyticsService,
		Co2Service,
        ViewportService
    ]
})
export class SharedModule {}
