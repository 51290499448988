// NG
import { Injectable } from '@angular/core';

@Injectable()
export class PopupModalService {
    private _modals: any[] = [];
    private _activeModals: any[] = [];

    /**
     * Add modal to array of active modals
     * @param modal instance
     */
    add(modal: any) {
        this._modals.push(modal);
    }

    /**
     * Remove modal from array of active modals
     * @param id instance id
     */
    remove(id: any) {
        const indexToRemove: number = this._modals.findIndex((value) => value.id === id);
        this._modals.splice(indexToRemove, 1);
    }

    /**
     * Open modal specified by id
     * @param id instance id
     * @param options provide custom options
     */
    open(id: any, options: any = null) {
        const modal: any = this._modals.find((value) => value.id === id);
        if (modal) {
            this.registerActiveModal(modal);
            modal.open(options);
        }
    }

    /**
     * Close modal specified by id
     * @param id instance id
     */
    close(id: any) {
        const modal: any = this._modals.find((value) => value.id === id);
        if (modal) {
            modal.close();
            this.unregisterActiveModal();
        }
    }

    /**
     * Returns a boolean whether the modal with the given id is currently active and open
     * @param id instance id
     */
    isModalActive(id: string): boolean {
        const activeModal = this._activeModals.filter((modal) => {
            return modal.id === id;
        });

        return activeModal.length > 0;
    }

    /**
     * Adds modal to the pool
     * @param modal instance
     */
    private registerActiveModal(modal: any) {
        if (this._activeModals.length > 0) {
            this._activeModals[0].close(true);
        }

        this._activeModals.push(modal);
    }

    /**
     * Removes modal from the pool
     */
    private unregisterActiveModal() {
        this._activeModals.pop();

        if (this._activeModals.length > 0) {
            this._activeModals[0].open();
        }
    }
}
