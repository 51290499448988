// ng
import { Component, OnDestroy, OnInit, AfterViewInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

// services
import { TeaserService } from '@shared/shared-services/teaser/teaser.service';
import { SelectedCarService } from '@shared/shared-services/selected-car/selected-car.service';
import { TranslationService } from '@shared/shared-services/translate/translation.service';
import { SeoService } from '@shared/shared-services/seo/seo.service';
import { CurrentRouteService } from '@shared/shared-services/current-route/current-route.service';
import { GoogleAnalyticsService } from '@shared/shared-services/google-analytics/google-analytics.service';
import { Co2Service } from '@shared/shared-services/co2-label/co2.service';

// models
import { Article } from '@shared/global-models/article/article.model';
import { PageName } from '@shared/shared-services/google-analytics/google-analytics.model';
import { Image } from "@shared/global-models/image.model";


@Component({
    selector: 'zk-model-start-page',
    templateUrl: 'model-start-page.component.html',
    styleUrls: ['./model-start-page.component.scss']
})
export class ModelStartPageComponent implements OnInit, OnDestroy, AfterViewInit {
    image: Image;
    highlightArticle: Article;
    highlightArticles: Article[] = [];
    displayErrorMessage: boolean = false;
    errorCode: number;
	co2Label: string;

    // headlines for teasers
    headlineForSingleStageWide: string = '';

    private lastCarLineId: string = null;
    private carLineSubscription: Subscription;

	get isAmg(): boolean {
		return this.selectedCarService.vehicleType?.vehicleTypeId === 'amg';
	}

    constructor(
        private teaserService: TeaserService,
        private selectedCarService: SelectedCarService,
        private _translationService: TranslationService,
        private seoService: SeoService,
        private _currentRouteService: CurrentRouteService,
        private _gaService: GoogleAnalyticsService,
		private _co2Service: Co2Service
    ) {}

    ngOnInit() {
        if (this.selectedCarService.carLine) {
            this.lastCarLineId = this.selectedCarService.carLine.carLineId;
            this.setupPage(this.lastCarLineId);
        }

        // react to car line change
        this.carLineSubscription = this.selectedCarService.carLineSubscriber.subscribe((value) => {
            if (value && this.lastCarLineId !== value.carLineId) {
                this.lastCarLineId = value.carLineId;
                this.setupPage(this.lastCarLineId);
            }
        });

		// C02 note for AMG
		if (this.isAmg) {
		    this.co2Label = this._co2Service.getCo2Label(this.selectedCarService.modelDesign.modelDesignId, false, true);
		}

        this.selectedCarService.isModelStartPage.next(true);
    }

    ngOnDestroy() {
        if (this.carLineSubscription) {
            this.carLineSubscription.unsubscribe();
        }

        this.selectedCarService.isModelStartPage.next(false);
    }

    ngAfterViewInit() {
        this._gaService.trackPageView(PageName.MODEL_PAGE);
    }

    routeToProductDetails(article: Article): void {
        this._currentRouteService.routeToProductDetails(article);
    }

    private setupPage(carLineId: string) {
        this.getHighlightsImageData(carLineId);
        this.getHighlightArticlesData();
        this.setCO2labelNote(carLineId);
        this.getHeadlinesWithTranslations();
        // scroll up on initial page load (important for mobile)
        window.scrollTo(0, 0);
    }

    private getHeadlinesWithTranslations() {
        this.headlineForSingleStageWide = this.getHeadlineForSingleStageWide();
        this.setSeoInfo();
    }

    /**
     * Get car image
     * @param {string} carLineId
     */
    private getHighlightsImageData(carLineId: string) {
        const subscription: Subscription = this.teaserService.getHighlightsImageData(carLineId).subscribe(
            (response) => {
                subscription.unsubscribe();
                // Image is not available for every carLineId - component renders text then and removes the possible previously shown image
                this.image = response;
                // case image exists
                if (this.image) {
                    this.image.imageDescription =
                        this.selectedCarService.carClass.name + ' ' + this.selectedCarService.bodyType.name;
                }
            },
            () => {
                subscription.unsubscribe();
                // Error does not need to be handled because Image is not available for every carLineId - component renders text then
                this.image = null;
            }
        );
    }

    /**
     * Get all highlight articles and set first one in "Product Teaser Big With Price Component"
     */
    private getHighlightArticlesData(): void {
        const sub: Subscription = this.teaserService.getAllHighlightsArticles().subscribe(
            (articles: Article[]) => {
                sub.unsubscribe();
                this.highlightArticle = articles[0];
                this.highlightArticles = articles.slice(1); // removes first highlighted item from the list
            },
            (error: HttpErrorResponse) => {
                this.displayErrorMessage = true;
                this.errorCode = error.status;
            }
        );
    }

    private getHeadlineForSingleStageWide(): string {
        return (
            this._translationService.translate('GENERAL.ACCESORORIESFOR') +
            ' ' +
            this.selectedCarService.carClass.name +
            ' ' +
            this.selectedCarService.bodyType.name
        );
    }

    private getCarClassWithBodyName(): string {
        return this.selectedCarService.carClass.name + ' ' + this.selectedCarService.bodyType.name;
    }

    private getCarLineName(): string {
        return this.selectedCarService.carLine.name;
    }

    private setSeoInfo() {
        this.setPageTitle();
        this.setMetaInfo();
    }

    private setPageTitle() {
        const title: string = this.seoService.getTranslation('GENERAL.PAGES.STARTPAGE_STATICTITLE');
        const subTitle: string = this.seoService.getTranslation('GENERAL.PAGES.STARTPAGE_STATICSUBTITLE');

        // structure: ["Mercedes-Benz Genuine Accessories |"] <CarBodyLine> ["| Official Online Store"]
        this.seoService.updatePageTitle(
            title + ' | ' + this.getCarClassWithBodyName() + ' ' + this.getCarLineName() + ' | ' + subTitle
        );
    }

    private setMetaInfo() {
        const txt: string =
			this.seoService.getTranslation('SEO.METADESCRIPTION.MODELSTARTPAGE') +
            ' ' +
            this.getCarClassWithBodyName() +
            ' ' +
            this.getCarLineName();

        this.seoService.updateMetaDescription(txt);
    }

    /**
     * CO2 label for EQC and EQA cars only.
     * @param carLineId
     */
    private setCO2labelNote(carLineId: string) {
		if (carLineId === '3703' || carLineId === '4601') {
			const txt: string = this._translationService.translate('CO2LABEL.' + carLineId);
			// show nothing if translation was not provided
			this.co2Label = txt.startsWith('LOCALE') ? null : txt;
		} else {
			// react to car line changes
			this.co2Label = null;
		}
    }
}
