<div class="complete-wheels-info_tyre" *ngFor="let wheel of completeWheels">
    <div class="complete-wheels-info_tyre_basic">
        <wb-text size="l" strong [zkTranslate]="'WHEELS.AXLE.'+ wheel.axle.toUpperCase()"></wb-text>
        <wb-text size="l"><span zkTranslate="PRODUCTDETAILS.COMPLETEWHEELS.DIMENSIONS"></span>{{wheel.headline2}}</wb-text>
        <wb-text size="l" ><span zkTranslate="PRODUCTDETAILS.COMPLETEWHEELS.ARTICLENUMBER"></span>{{wheel.articleNumber}}</wb-text>
        <wb-text size="l" *ngIf="wheel.grossPriceText"><span zkTranslate="PRODUCTDETAILS.COMPLETEWHEELS.UNITPRICE"></span>{{wheel.grossPriceText}}</wb-text>

        <!-- Link is only for new EU order and if available -->
        <wb-link *ngIf="tyreLabelVersion2021 && wheel?.noticeLinks?.length > 0"
                 class="link"
                 variant="standalone"
                 (click)="openTyreLabelLink(wheel)">
            <wb-icon name="bds/chevron-right/24" slot="icon" style="--size: 20px"></wb-icon>
            <span zkTranslate="PRODUCTDETAILS.COMPLETEWHEELS.TYREBUTTON"></span>
        </wb-link>
    </div>

    <!-- New EU Label -->
    <!-- See https://youtrack.mycantaa.eu/issue/MBCS21-301#focus=Comments-74-23731.0-0 for decision of icons display for different wheel types -->
    <div *ngIf="tyreLabelVersion2021; else tyreLabelBefore2021"
         class="complete-wheels-info_tyre_properties">
        <div class="complete-wheels-info_tyre_properties_list new-version"
             [ngClass]="{'empty': completeWheelData.wheel.tyreType === 'spike'}">
            <div *ngIf="wheel.wheel.tyreType === 'nordic'"
                 class="complete-wheels-info_tyre_properties_list_icon">
                <img src="ui/assets/img/complete-wheels/5-ic-eisgriffigkeit.jpg"/>
            </div>
            <div *ngIf="wheel.wheel.tyreType === 'nordic' || wheel.wheel.tyreType === 'winter'"
                 class="complete-wheels-info_tyre_properties_list_icon">
                <img src="ui/assets/img/complete-wheels/4_ic-schneegriffigkeit.jpg"/>
            </div>
            <div *ngIf="wheel.wheel.noiseRating && wheel.wheel.noiseDB && (wheel.wheel.tyreType === 'summer' || wheel.wheel.tyreType === 'winter' || wheel.wheel.tyreType === 'nordic')"
                 class="complete-wheels-info_tyre_properties_list_icon">
                <img *ngIf="wheel.wheel.noiseRating === 'A'" src="ui/assets/img/complete-wheels/3_ic-rollgeraeusch_A.jpg"/>
                <img *ngIf="wheel.wheel.noiseRating === 'B'" src="ui/assets/img/complete-wheels/3_ic-rollgeraeusch_B.jpg"/>
                <img *ngIf="wheel.wheel.noiseRating === 'C'" src="ui/assets/img/complete-wheels/3_ic-rollgeraeusch_C.jpg"/>
                <span class="is-top">{{wheel.wheel.noiseDB}}</span>
            </div>
            <div *ngIf="wheel.wheel.wetGripRating && (wheel.wheel.tyreType === 'summer' || wheel.wheel.tyreType === 'winter' || wheel.wheel.tyreType === 'nordic')"
                 class="complete-wheels-info_tyre_properties_list_icon">
                <img src="ui/assets/img/complete-wheels/2_ic-nasshaftung.jpg"/>
                <span class="is-bottom">{{wheel.wheel.wetGripRating}}</span>
            </div>
            <div *ngIf="wheel.wheel.fuelEfficiencyRating && (wheel.wheel.tyreType === 'summer' || wheel.wheel.tyreType === 'winter' || wheel.wheel.tyreType === 'nordic')"
                 class="complete-wheels-info_tyre_properties_list_icon">
                <img src="ui/assets/img/complete-wheels/1_ic-kraftstoffeffizienz.jpg"/>
                <span class="is-bottom">{{wheel.wheel.fuelEfficiencyRating}}</span>
            </div>
        </div>

        <!-- Add single wheel button same design for both versions -->
        <wb-button variant="secondary"
                   class="complete-wheels-info_tyre_properties_button"
                   [disabled]="completeWheelData?.fit === false || singleWheelMaxReached(wheel)"
                   (click)="addWheelToCart(wheel)">
            <span zkTranslate="WHEELS.ADDWHEELWISHLIST"></span>
        </wb-button>
    </div>

    <!-- Old EU Label for wheel articles before May 2021 -->
    <ng-template #tyreLabelBefore2021>
        <div class="complete-wheels-info_tyre_properties">
            <div class="complete-wheels-info_tyre_properties_list"
                 [ngClass]="{'empty': !wheel.wheel.fuelEfficiencyRating && !wheel.wheel.wetGripRating && !wheel.wheel.noiseDB}">
                <div *ngIf="wheel.wheel.fuelEfficiencyRating"
                     class="complete-wheels-info_tyre_properties_list_icon">
                    <img src="ui/assets/img/complete-wheels/efficiency_icon.jpg"/>
                    <span>{{wheel.wheel.fuelEfficiencyRating}}</span>
                </div>
                <div *ngIf="wheel.wheel.wetGripRating"
                     class="complete-wheels-info_tyre_properties_list_icon">
                    <img src="ui/assets/img/complete-wheels/wetgrip_icon.jpg"/>
                    <span>{{wheel.wheel.wetGripRating}}</span>
                </div>
                <div *ngIf="wheel.wheel.noiseDB"
                     class="complete-wheels-info_tyre_properties_list_icon">
                    <img src="ui/assets/img/complete-wheels/rollnoise2_icon.jpg"/>
                </div>
                <div *ngIf="wheel.wheel.noiseDB"
                     class="complete-wheels-info_tyre_properties_list_icon">
                    <img src="ui/assets/img/complete-wheels/noiselevel_icon.jpg"/>
                    <span>{{wheel.wheel.noiseDB}}</span>
                </div>
            </div>

            <!-- Add single wheel button same design for both versions -->
            <wb-button variant="secondary"
                       class="complete-wheels-info_tyre_properties_button"
                       [disabled]="completeWheelData?.fit === false || singleWheelMaxReached(wheel)"
                       (click)="addWheelToCart(wheel)">
                <span zkTranslate="WHEELS.ADDWHEELWISHLIST"></span>
            </wb-button>
        </div>
    </ng-template>
</div>

<wb-heading tag="p" size="s" *ngIf="tyreLabelVersion2021 && completeWheelData.wheel.tyreType !== 'spike'"
   zkTranslate="PRODUCTDETAILS.COMPLETEWHEELS.TYRELABELHEADLINE">
</wb-heading>

<!-- Additional static information about complete wheels (legend) -->
<div *ngIf="completeWheelData.wheel.tyreType !== 'spike'"
     class="complete-wheels-info_legend">
        <img class="complete-wheels-info_legend_image"
             [src]="tyreLabelVersion2021 ?
                    (isMobile ? 'ui/assets/img/complete-wheels/new_tyre_properties_mobile.jpg' : 'ui/assets/img/complete-wheels/new_tyre_properties_desktop.png') :
                    'ui/assets/img/complete-wheels/tyre_properties_info-1.41.jpg'"
             alt="complete-wheels-legend"/>
    <ul class="complete-wheels-info_legend_hints">
        <li><wb-text size="l" class="complete-wheels-info_legend_hints_info" [zkTranslate]="'PRODUCTDETAILS.COMPLETEWHEELS.' + (tyreLabelVersion2021 ? 'LEGENDINFOS2021' : 'LEGENDINFOS') +'.HINT1'"></wb-text></li>
        <li><wb-text size="l" class="complete-wheels-info_legend_hints_info" [zkTranslate]="'PRODUCTDETAILS.COMPLETEWHEELS.' + (tyreLabelVersion2021 ? 'LEGENDINFOS2021' : 'LEGENDINFOS') +'.HINT2'"></wb-text></li>
        <li><wb-text size="l" class="complete-wheels-info_legend_hints_info" [zkTranslate]="'PRODUCTDETAILS.COMPLETEWHEELS.' + (tyreLabelVersion2021 ? 'LEGENDINFOS2021' : 'LEGENDINFOS') +'.HINT3'"></wb-text></li>
        <li><wb-text size="l" class="complete-wheels-info_legend_hints_info" zkTranslate="PRODUCTDETAILS.COMPLETEWHEELS.ADDITIONALINFO"></wb-text></li>
    </ul>
</div>
