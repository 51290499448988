// ng
import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
// services
import { ProductListService } from '@shared/shared-services/product-list/product-list.service';
import { SelectedCarService } from '@shared/shared-services/selected-car/selected-car.service';
import { GoogleAnalyticsService } from '@shared/shared-services/google-analytics/google-analytics.service';
// models
import { ArticlesPaginated } from "@shared/global-models/article/articlePaginated.model";
import { ProductGroup } from "@shared/global-models/productGroup.model";
import { ViewportService } from "@shared/shared-services/viewport/viewport.service";

@Component({
    selector: 'zk-product-list-subcategory',
    templateUrl: 'product-list-subcategory.component.html',
    styleUrls: ['./product-list-subcategory.component.scss']
})
export class ProductListSubcategoryComponent implements OnInit, OnDestroy {
    @Input()
    subGroupName: string = '';

    @Input()
    subGroupType: string = '';

    @Input()
    wheelProductGroup: ProductGroup;

    @Input()
    isGroupTypeWheels = false;

    /**
     * User changes the sub category - reload according articles.
     */
    @Input()
    set subGroupId(val: string) {
        if (val && val !== this._subGroupId) {
            this._subGroupId = val;
            this.getArticlesFromSubcategory(0);
        }
    }

    get subGroupId(): string {
        return this._subGroupId;
    }

    isMobile = false;
    articlesOfPage: ArticlesPaginated;
    isLoading: boolean = false;
    errorCode: number;

    private _carSelectionSubscription: Subscription;
    private _vpSubscription: Subscription;
    private _subGroupId: string;

    constructor(
        private _router: Router,
        private _activatedRoute: ActivatedRoute,
        private _productListService: ProductListService,
        private _selectedCarService: SelectedCarService,
        private _gaService: GoogleAnalyticsService,
        private _vpService: ViewportService
    ) {}

    ngOnInit() {
        // User selects first time or changes the car - reload the articles for the new car
        this._carSelectionSubscription = this._selectedCarService.carLineSubscriber.subscribe((newCarLine) => {
            if (newCarLine) {
                this.getArticlesFromSubcategory(0);
            }
        });

        this.isMobile = this._vpService.getCurrentViewPort() === 'mq1';
        this._vpSubscription = this._vpService.getViewportChangedObserver().subscribe((vp: string) => (this.isMobile = vp === 'mq1'));
    }

    ngOnDestroy(): void {
        this._carSelectionSubscription.unsubscribe();
        this._vpSubscription.unsubscribe();
    }

    /**
     * Handles the routing to the according wheel subcategory
     * @param groupUrlName
     */
    routeToSubcategory(groupUrlName: string) {
        this._router.navigate(['../' + groupUrlName], {relativeTo: this._activatedRoute, queryParamsHandling: 'preserve' }).then();
    }

    handlePagination(currentPage: number) {
        this.getArticlesFromSubcategory(--currentPage);
    }

    private getArticlesFromSubcategory(page: number): void {
        // prevent unnecessary API call for the two special wheel groups because they have their own API calls in their components
        if (this.subGroupType === 'complete-wheels' || this.subGroupType === 'alloy-wheels') {
            return;
        }

        this.isLoading = true;
        window.scrollTo(0, 0);

        const vehicleId = this._selectedCarService.carLine ? this._selectedCarService.carLine.carLineId : this._selectedCarService.vehicleType.vehicleTypeId;
        const sub = this._productListService.getArticlesFromSubCategory(this._subGroupId, vehicleId, page, 10).subscribe(
            (response) => {
                sub.unsubscribe();
                this.isLoading = false;
                this.articlesOfPage = response;
                this._gaService.trackProductImpressions(this.articlesOfPage.articles);
            },
            (error) => {
                sub.unsubscribe();
                this.isLoading = false;
                this.errorCode = error.status;
            }
        );
    }
}
