// NG
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Modules
import { ButtonModule } from "@shared/components/multiselect/button/button.module";

// Components
import { MultiselectItemComponent } from './multiselect-item/multiselect-item.component';
import { MultiselectComponent } from './multiselect.component';

@NgModule({
    declarations: [
        MultiselectComponent,
        MultiselectItemComponent
    ],
    imports: [
        CommonModule,
        ButtonModule
    ],
    exports: [
        MultiselectComponent,
        MultiselectItemComponent
    ],
    providers: [],
})
export class MultiselectModule {}
