<div class="error-message_wrapper"
     [ngClass]="{'is-light': lightBackground}">
    <span [zkTranslate]="translationKey"></span>
    <a [routerLink]="errorMessageLink"
       queryParamsHandling="preserve"
       class="error-message_link"
       [ngClass]="{'is-light': lightBackground}"
       *ngIf="showLink">
        <wb-icon name="bds/chevron-right/24" style="--size: 16px"></wb-icon>
        <span [zkTranslate]="errorMessageLinkText"></span>
    </a>
</div>
