// ng
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

// model
import { Image } from "@shared/global-models/image.model";

@Component({
    selector: 'zk-file-not-found-page',
    templateUrl: 'file-not-found-page.component.html',
    styleUrls: ['./file-not-found-page.component.scss']
})
export class FileNotFoundPageComponent implements OnInit {
    image: Image = {
        image: 'ui/assets/img/fallback-page/404/1440.jpg',
        image480: 'ui/assets/img/fallback-page/404/480.jpg',
        image768: 'ui/assets/img/fallback-page/404/768.jpg',
        image1024: 'ui/assets/img/fallback-page/404/1024.jpg',
        image1280: 'ui/assets/img/fallback-page/404/1280.jpg',
        imageDescription: '404 image'
    };

    constructor(private titleService: Title) {}

    ngOnInit() {
        this.titleService.setTitle('404 - Resource was not found');
    }
}
