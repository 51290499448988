import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Article } from '@shared/global-models/article/article.model';

@Component({
  selector: 'zk-product-teaser-large',
  templateUrl: './product-teaser-large.component.html',
  styleUrls: ['./product-teaser-large.component.scss']
})
export class ProductTeaserLargeComponent implements OnInit {
	/**
	 * Event that is emitted when a click on the CTA button happens.
	 * Emits the clicked article object. Can be used for routing for instance.
	 */
	@Output()
	onButtonClick = new EventEmitter<Article>();

    @Input()
    article: Article;

    constructor() {}

    ngOnInit(): void {}

	onClick() {
		this.onButtonClick.emit(this.article);
	}
}
