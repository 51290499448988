// ng
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, Observable, Subject, Subscription } from 'rxjs';

// model
import { MODE, SUBMODE } from '@shared/global-models/mode.enum';
import { MarketModel } from '@shared/global-models/market.model';
import { StorageEnum } from '@shared/shared-services/storage/storage.enum';

// services
import { TranslationService } from '@shared/shared-services/translate/translation.service';
import { ProductGroupsService } from '@shared/shared-services/product-groups/product-groups.service';
import { SelectedCarService } from '@shared/shared-services/selected-car/selected-car.service';
import { LocalStorageService } from '@shared/shared-services/storage/local-storage.service';
import { CarChooserService } from "@shared/components/car-chooser/car-chooser.service";


@Injectable()
export class AppService {
	marketsLoaded = new Subject<boolean>();

    private _allDataLoaded: Subject<boolean> = new Subject();
    private _currentMarket: MarketModel;

    private _mode: MODE;
    private _subMode: SUBMODE;

    constructor(
        private http: HttpClient,
        private _productGroupsService: ProductGroupsService,
        private _translationService: TranslationService,
        private _selectedCarService: SelectedCarService,
        private _carChooserService: CarChooserService,
		private _localStorageService: LocalStorageService
    ) {}

    set appMode(val: MODE) {
        this._mode = val;
        this._productGroupsService.appMode = val;
    }

    set appSubMode(val: SUBMODE) {
        this._subMode = val;
        this._productGroupsService.appSubMode = val;
    }

    get appMode(): MODE {
        return this._mode;
    }

	get appSubMode(): SUBMODE {
		return this._subMode;
	}

    get currentAppMode(): string {
        return this._mode + this._subMode;
    }

    get currentMarket(): MarketModel {
        return this._currentMarket;
    }

    get allDataLoadedInfo(): Observable<boolean> {
        return this._allDataLoaded.asObservable();
    }

    fetchEnabledMarkets(): void {
        const sub: Subscription = this.http.get<any>('api/market').subscribe(
            (response: MarketModel[]) => {
                sub.unsubscribe();
                let enabledLocales: string[] = [];

                response.forEach((m) => {
                    const mCode = m.marketCode;
                    m.availableLanguages.forEach((l) => {
                        enabledLocales.push(l + '-' + mCode);
                    });
                });

                if (enabledLocales.length === 0) {
                    console.error('NO ENABLED MARKETS WITH ENABLED LANGUAGES RETRIEVED');
                    enabledLocales = ['en-MB']; // default route
                }
                // console.log('Market data loaded. Allowed routes are: ', enabledLocales.toString());

                const entryUrl = window.location.pathname;
                const langMarketUrlPart: string = entryUrl.split('/')[1];
                // if the lang from the requested URL doesn't exist we set the default language as active
                this._translationService.currentLang = enabledLocales.indexOf(langMarketUrlPart) === -1 ? 'en-MB' : langMarketUrlPart ;
                // same for market
                const marketCodePart: string = langMarketUrlPart.substr(3, 2);
                const currentMarket: MarketModel = response.find((m) => m.marketCode === marketCodePart);
                this._currentMarket = currentMarket ? currentMarket : response.find((m) => m.marketCode === 'MB');

                // changing market via URL should reset everything
                if (this._localStorageService.getItem(StorageEnum.ACTIVE_MARKET) !== this.currentMarket.marketCode) {
                    this._localStorageService.clear();
                    // console.log('Market Changed - Browsers storage cleared');
                }

                // save and inform AuthGuard
                this._localStorageService.setItem(StorageEnum.ACTIVE_MARKET, this.currentMarket.marketCode);
                this.marketsLoaded.next(true);

				this.loadRequiredData();
            },
            (error) => {
                console.log('Error getting markets: ', error);
                sub.unsubscribe();
            }
        );
    }

	loadRequiredData() {

        if (this.appMode === MODE.ACCESSORIES) {
            this._selectedCarService.setInitialVehicleType(window.location.pathname);

            combineLatest([
                this._translationService.fillTranslation(),
                this._productGroupsService.groupsChangedInfo,
                this._carChooserService.getVehicleTypesAndClasses()
            ]).subscribe(() => this._allDataLoaded.next(true));
        } else {
            const sub = this._translationService.fillTranslation().subscribe(() => {
                this._allDataLoaded.next(true);
                sub.unsubscribe();
            });
        }
	}

    /**
     * Handles the possible mode switches / redirect logic (#MBCS-316).
     * 1) Switch from ACC to COLL: Always go to start page of COLL
     * 2) Switch from COLL to ACC: Redirect to the mode and model start page of the logged in car
     * 2.1) or start page of the last ACC mode if no car was logged in before
     * 2.2) Passenger cars as default mode if no mode switch happened before
     * 2.3) or Vans as default mode for market US only
     */
    switchMode(): void {
        let redirectTarget = this._translationService.currentLang;
        const carLogged = this._selectedCarService.carLine?.carLineId;

        switch(true) {
            case this.appMode === MODE.ACCESSORIES:
                redirectTarget += MODE.LIFESTYLE + SUBMODE.COLLECTION;
                break;
            case !!carLogged:
                redirectTarget += this._selectedCarService.lastVehicleType.urlPart + '/' + this._selectedCarService.carLine.urlName;
                break;
            case this._currentMarket.marketCode === 'US':
                redirectTarget += MODE.ACCESSORIES + SUBMODE.VANS;
                break;
            case !!this._selectedCarService.lastVehicleType:
                redirectTarget += this._selectedCarService.lastVehicleType.urlPart;
                break;
            default:
                redirectTarget += MODE.ACCESSORIES + SUBMODE.PASSENGERCARS;
        }

        location.href = redirectTarget;
    }
}
