<wb-card class="wrapper">
    <!-- car silhouette image is retrieved via api call -->
    <img class="silhouette"
         #silhouette
         [src]="environment.apiUrl + carLine.image"
         (error)="silhouette.src='ui/assets/img/fallback-565.png'"
         alt="Silhouette image of selected car"/>
    <div class="text">
        <wb-heading size="xs" zkTranslate="CAR_TEASER_TEXT"></wb-heading>
        <wb-button *ngIf="showActionButton"
                   class="action-button"
                   variant="secondary"
                   size="{{isMobile ? 's' : 'l'}}"
                   (click)="openModalStartPage('teaser')"
                   zkTranslate="CARCHOOSER.HIGHLIGHT_BUTTON">
        </wb-button>
    </div>
</wb-card>
