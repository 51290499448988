<div *ngIf="article" class="teaser">
    <div class="image-wrapper">
            <picture>
                <source [srcset]="article.image320"
                        media="(max-width:319.9px)"
                        *ngIf="article.image320">
                <source [srcset]="article.image400"
                        media="(max-width:399.9px)"
                        *ngIf="article.image400">
                <source [srcset]="article.image480"
                        media="(max-width:479.9px)"
                        *ngIf="article.image480">
                <source [srcset]="article.image740"
                        media="(max-width:739.9px)"
                        *ngIf="article.image740">
                <img [src]="article.image"
                     [alt]="article?.imageDescription"/>
            </picture>
    </div>

    <wb-heading size="l" class="teaser_headline spacing-s" tag="h2">{{ article.headline }}</wb-heading>
    <wb-text size="l" class="teaser_description" [innerHTML]="article.description"></wb-text>
    <wb-heading size="s" class="teaser_price">{{ article.grossPriceText }}</wb-heading>
    <wb-button variant="primary" class="teaser_button"
               zkTranslate="GENERAL.MOREINFORMATION"
               zkTrackClick="top_list_click"
               zkTrackClickPosition="model_start_page"
               (click)="onClick()">
    </wb-button>
</div>
