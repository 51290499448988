/**
 * Page Types a.k.a. Names
 */
export enum PageName {
    SPLASH_PAGE = 'splash-page',
    START_PAGE = 'home-page',
    MODEL_PAGE = 'class-overview',
    CATEGORY_PAGE = 'category-overview',
    SUBCATEGORY_PAGE = 'subcategory-overview',
    SUB_SUBCATEGORY_PAGE = 'sub_subcategory-overview',
    WHEEL_SPECIAL_PAGE = 'wheels-special',
    WHEEL_SPECIAL_DETAIL_PAGE = 'wheels-special-detail',
    PRODUCT_PAGE = 'product-view',
    CAMPAIGN_PAGE = 'campaign',
    WISHLIST = 'wishlist'
}

export interface AnalyticsArticle {
    /**
     * The product ID or SKU (e.g. P67890).
     */
    item_id: string;
    /**
     * The name of the product (e.g. Android T-Shirt).
     */
    item_name: string;
    /**
     * The list or collection to which the product belongs (e.g. Search Results).
     */
    list_name?: string;
    /**
     * The brand associated with the product (e.g. Google).
     */
    item_brand?: string;
    /**
     * The category to which the product belongs (e.g. Apparel). Use / as a delimiter to specify up to 5-levels of hierarchy (e.g. Apparel/Men/T-Shirts).
     */
    item_category?: string;
    /**
     * The variant of the product (e.g. Black).
     */
    item_variant?: string;
    /**
     * The product's position in a list or collection (e.g. 2).
     */
    list_position?: number;
    /**
     * The price of a product (e.g. 29.20).
     */
    price?: number;
    /**
     * The quantity of a product (e.g. 2).
     */
    quantity?: number;
}
