// ng
import { Injectable, OnDestroy } from '@angular/core';
import { Subject, Observable } from 'rxjs';

// needed for scrollToElement functionality
declare const window: any;
declare const document: any;

@Injectable()
export class ViewportService implements OnDestroy {
    private readonly viewports = [
        {
            name: 'mq1',
            low: 320,
            high: 479
        },
        {
            name: 'mq2',
            low: 480,
            high: 767
        },
        {
            name: 'mq3',
            low: 768,
            high: 1023
        },
        {
            name: 'mq4',
            low: 1024,
            high: 1279
        },
        {
            name: 'mq5',
            low: 1280,
            high: 1439
        },
        {
            name: 'mq6',
            low: 1440,
            high: 1679
        },
        {
            name: 'mq7',
            low: 1680,
            high: 99999
        }
    ];

    private viewportChangedSubscriber: Subject<string> = new Subject<string>();
    private viewportResizedSubscriber: Subject<number> = new Subject<number>();
    private appContentHeightChangedSubscriber: Subject<number> = new Subject<number>();
    private currentViewport: string;
    private currentAppHeight: number;

    constructor() {
        window.addEventListener('resize', this.documentSizeChanged.bind(this), { passive: true });
    }

    /**
     * Returns the current viewport MQ as string
     * @returns string
     */
    getCurrentViewPort(): string {
        if (!this.currentViewport) {
            this.currentViewport = this.convertWidthToMediaQuery(document.body.clientWidth);
        }

        return this.currentViewport;
    }

    /**
     * Provides mq´s as string
     */
    getViewportChangedObserver(): Observable<string> {
        return this.viewportChangedSubscriber.asObservable();
    }

    /**
     * Set the content height of the application.
     * @param newHeight - new height of the main application
     */
    setAppContentHeight(newHeight: number) {
        if (newHeight !== this.currentAppHeight) {
            this.currentAppHeight = newHeight;
            this.appContentHeightChangedSubscriber.next(this.currentAppHeight);
        }
    }

    /**
     * Provides the current application content height as number
     */
    getAppContentHeightObserver(): Observable<number> {
        return this.appContentHeightChangedSubscriber.asObservable();
    }

    /**
     * Provides the current window width as number
     */
    getViewportResizedObserver(): Observable<number> {
        return this.viewportResizedSubscriber.asObservable();
    }

    /**
     * Get the Media Query for the given width
     * @param width Width to get the according MQ for
     */
    convertWidthToMediaQuery(width: number) {
        let currentViewport = '';

        for (const mqs of this.viewports) {
            if (width <= mqs.high && width >= mqs.low) {
                currentViewport = mqs.name;
                break;
            }
        }

        return currentViewport;
    }

    /**
     * Helper function that scrolls to the given markup element.
     * @param el - nativeElement from markup
     * @param alignCenter - defines if the element needs to be centered on window
     * @param smoothScroll - defines the ScrollToOptions.behavior. Default is 'smooth'. Otherwise 'auto'
     */
    scrollToElement(el: any, alignCenter: boolean, smoothScroll: boolean = true) {
		let extraScrollTop = 0;

		if (alignCenter) {
			extraScrollTop = (window.innerHeight - el.clientHeight) / 2;
		}

		const scrollTopPosition = el.offsetTop - extraScrollTop;
		window.scrollTo({ left: 0, top: scrollTopPosition, behavior: smoothScroll ? 'smooth' : 'auto' });
    }

    /**
     * Scroll to the top position
     * @param top - top position value
     * @param smoothScroll - defines the ScrollToOptions.behavior. Default is 'smooth'. Otherwise 'auto'
     */
    scrollTop(top: number, smoothScroll: boolean = true) {
		window.scrollTo({ left: 0, top, behavior: smoothScroll ? 'smooth' : 'auto' });
    }

    ngOnDestroy() {
		window.removeEventListener('resize', this.documentSizeChanged);
    }

    private documentSizeChanged(event) {
        const currentWindowWidth: number = event.target.innerWidth;
        // inform who´s interested
        this.viewportResizedSubscriber.next(currentWindowWidth);

        if (currentWindowWidth > 0) {
            for (const mqs of this.viewports) {
                if (currentWindowWidth > mqs.high) {
                    continue;
                } else if ((currentWindowWidth >= mqs.low || currentWindowWidth <= mqs.high) && this.currentViewport !== mqs.name) {
                    this.currentViewport = mqs.name;
                    // inform who´s interested
                    this.viewportChangedSubscriber.next(mqs.name);
                    break;
                } else if (this.currentViewport === mqs.name) {
                    break;
                } else {
                    console.log('ERROR');
                }
            }
        }
    }
}
