// Ng
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class ResetWarningModalService {
    private _actionAllowed: Subject<boolean> = new Subject<boolean>();

    /**
     * Inform subscribers on users' decision in modal
     */
    get actionAllowed(): Subject<boolean> {
        return this._actionAllowed;
    }
}
