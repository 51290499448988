<!-- DESKTOP -->
<div *ngIf="!isMobile" class="search-wrapper" [class]="{'is-active' : showInput, 'has-result' : result || searchHistory}">
    <div class="search-background" *ngIf="showInput" (click)="close()"></div>

    <ng-container *ngTemplateOutlet="input"></ng-container>

    <wb-list-group>
        <!-- Last searches from local storage-->
        <div *ngIf="searchHistory && query.trim().length === 0">
            <wb-list-group-item>
                <span class="is-bold" zkTranslate="SEARCH.LAST_SEARCH"></span>
            </wb-list-group-item>

            <wb-list-group-item interactive
                                #historyList
                                *ngFor="let item of searchHistory"
                                (click)="onSearch(item.suggestion)">
                <wb-icon name="bds/time/24" class="group-icon"></wb-icon>
                <span>{{item.suggestion}}</span>
            </wb-list-group-item>
        </div>

        <!-- no results from search -->
        <wb-list-group-item *ngIf="!result?.article && result?.suggestions?.suggestions?.length === 0" class="no-results">
            <wb-icon name="bds/mb-star/24" class="group-icon"></wb-icon>
            <span zkTranslate="SEARCH.NO_RESULTS"></span>
        </wb-list-group-item>

        <!-- search results-->
        <wb-list-group-item interactive
                            #resultList
                            *ngFor="let item of result?.suggestions?.suggestions"
                            (click)="onSearch(item.suggestion)"
                            zkTrackClick="search_suggestion_click"
                            zkTrackClickValue="{{item.suggestion}}">
            <wb-icon name="bds/search/24" class="group-icon"></wb-icon>
            <span [innerHTML]="item.suggestion | highlight: query"></span>
        </wb-list-group-item>

        <!-- article ID result -->
        <wb-list-group-item *ngIf="result?.article"
                            interactive
                            (click)="routeToPdP(result.article)"
                            zkTrackClick="search_article_number"
                            zkTrackClickValue="{{result.article.articleId}}">
            <wb-text size="m" strong>{{result.article.headline}}</wb-text>
            <wb-text *ngIf="result.article.headline2" size="m">{{result.article.headline2}}</wb-text>
        </wb-list-group-item>

        <!-- search results exist in other mode -->
        <wb-list-group-item *ngIf="result?.existsInAccessory || result?.existsInCollection"
                            (click)="searchInOtherMode()"
                            zkTrackClick="search_other_mode"
                            zkTrackClickPosition="header"
                            zkTrackClickValue="{{query}}"
                            interactive>
            <wb-icon *ngIf="result.existsInAccessory" name="bds/vehicle-search/24" class="group-icon"></wb-icon>
            <wb-icon *ngIf="result.existsInCollection" src="ui/assets/icons/misc/search_collection.svg" class="group-icon"></wb-icon>
            <span [zkTranslate]="result.existsInCollection ? 'SEARCH.IN_COLL' : 'SEARCH.IN_ACC'"></span>
        </wb-list-group-item>
    </wb-list-group>
</div>

<!-- MOBILE -->
<div *ngIf="isMobile">
    <ng-container *ngTemplateOutlet="input"></ng-container>

    <!-- Last searches from local storage-->
    <div *ngIf="searchHistory && query.trim().length === 0">
        <wb-header-fly-in-item>
            <span class="is-bold" zkTranslate="SEARCH.LAST_SEARCH"></span>
        </wb-header-fly-in-item>

        <wb-header-fly-in-item interactive
                            *ngFor="let item of searchHistory"
                            (click)="onSearch(item.suggestion)">
            <wb-icon name="bds/time/24" class="group-icon"></wb-icon>
            <span>{{item.suggestion}}</span>
        </wb-header-fly-in-item>
    </div>

    <!-- no results from search -->
    <wb-header-fly-in-item *ngIf="!result?.article && result?.suggestions?.suggestions?.length === 0" class="no-results">
        <wb-icon class="group-icon" name="bds/mb-star/24"></wb-icon>
        <span zkTranslate="SEARCH.NO_RESULTS"></span>
    </wb-header-fly-in-item>

    <!-- search results -->
    <wb-header-fly-in-item interactive
                           *ngFor="let item of result?.suggestions?.suggestions"
                           (click)="onSearch(item.suggestion)"
                           zkTrackClick="search_suggestion_click"
                           zkTrackClickValue="{{item.suggestion}}">
        <wb-icon name="bds/search/24" class="group-icon"></wb-icon>
        <span [innerHTML]="item.suggestion | highlight: query"></span>
    </wb-header-fly-in-item>

    <!-- article ID result -->
    <wb-header-fly-in-item *ngIf="result?.article"
                           interactive
                           (click)="routeToPdP(result.article)"
                           zkTrackClick="search_article_number"
                           zkTrackClickValue="{{result.article.articleId}}">
        <div style="display: block">
            <wb-text size="m" strong>{{result.article.headline}}</wb-text>
            <wb-text *ngIf="result.article.headline2" size="m">{{result.article.headline2}}</wb-text>
        </div>
    </wb-header-fly-in-item>

    <!-- search results exists in other mode-->
    <wb-header-fly-in-item *ngIf="result?.existsInAccessory || result?.existsInCollection"
                           (click)="searchInOtherMode()"
                           zkTrackClick="search_other_mode"
                           zkTrackClickPosition="header"
                           zkTrackClickValue="{{query}}"
                           interactive>
        <wb-icon *ngIf="result.existsInAccessory" name="bds/vehicle-search/24" class="group-icon"></wb-icon>
        <wb-icon *ngIf="result.existsInCollection" src="ui/assets/icons/misc/search_collection.svg" class="group-icon"></wb-icon>
        <span [zkTranslate]="result.existsInCollection ? 'SEARCH.IN_COLL' : 'SEARCH.IN_ACC'"></span>
    </wb-header-fly-in-item>
</div>

<!-- Shared markup for WB input -->
<ng-template #input>
    <wb-input *ngIf="showInput || isMobile"
              #searchInput
              type="text"
              class="header-search-input"
              [theme]="isMobile ? 'light' : 'dark'"
              (input)="onInput()"
              ngDefaultControl
              [(ngModel)]="query"
              (keyup.escape)="close()"
              (keyup.enter)="handleEnter()"
              (keydown.arrowUp)="prevent($event)"
              (keyup)="handleKeyboard($event)">
        <span slot="label" zkTranslate="SEARCH.SEARCH_IN"></span>
        <span slot="label" [zkTranslate]="isAccessoriesMode ? 'HEADER.ACCESSORIES_MODE' : 'HEADER.COLLECTION_MODE'"></span>

        <wb-input-action slot="actions">
            <wb-round-button (click)="clearSearch()"
                             [disabled]="query.trim().length === 0"
                             size="s"
                             [theme]="isMobile ? 'light' : 'dark'">
                <wb-icon name="bds/close/24" style="--size: 24px;"></wb-icon>
            </wb-round-button>
        </wb-input-action>

        <wb-input-action slot="actions">
            <wb-round-button (click)="onSearch()"
                             [disabled]="this.isAsianLang() ? query.trim().length === 0 : query.trim().length < 3"
                             size="s"
                             [theme]="isMobile ? 'light' : 'dark'">
                <wb-icon name="bds/search/16" style="--size: 16px;"></wb-icon>
            </wb-round-button>
        </wb-input-action>
    </wb-input>
</ng-template>
