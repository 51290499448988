// ng
import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from '../../../../environments/environment';
// services
import { TranslationService } from '@shared/shared-services/translate/translation.service';

@Component({
  selector: 'zk-dealer-locator-modal',
  templateUrl: './dealer-locator-modal.component.html',
  styleUrls: ['./dealer-locator-modal.component.scss']
})
export class DealerLocatorModalComponent implements OnInit {
    initComponent = false;
    url: SafeResourceUrl;

    constructor(private _translate: TranslationService, private sanitizer: DomSanitizer) { }

    ngOnInit(): void {
		let lang = this._translate.currentLang.replace('-', '_');
        let market = this._translate.currentLang.substring(3, 5);

        // MB market re-mapping
        if (market === 'MB') {
            if (lang === 'de_MB') {
                market = 'EA'; // European Economic Area
                lang = 'de_DE';
            } else {
                market = 'HB'; // Hub Africa and Central Europe
                lang = 'en_HB';
            }
        } else {
            // Map other markets to their respective languages
            const marketLanguageMap = {
                'CZ': 'cs_CZ',
                'GR': 'el_GR',
                'RO': 'ro_RO',
                'SK': 'sk_SK'
            };

            lang = marketLanguageMap[market] || lang;
        }

		// Construct URL with dynamic parameters
		const url = `assets/dlc.html?lang=${lang}&market=${market}&prod=${environment.production}`;
		this.url = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
}
