import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
    selector: 'mba-icon',
    templateUrl: './icon.component.html',
    styleUrls: ['./icon.component.scss']
})
export class IconComponent implements OnInit, OnChanges {
    /**
     * Required: Name of the SVG icon inside your applications sprite sheet file with name 'icons.svg'.
     * Pass 'none' if no icon should be rendered.
     */
    @Input() icon: string | any;

    /**
     * Optional: Different appearance via CSS class defined in this component´s style sheet.
     */
    @Input() iconClass: string | any;

    /**
     * Optional: Different appearance via CSS inline style.
     */
    @Input() iconStyle: any;

    /**
     * Optional: Defines if "loading spinner" icon is shown. It replaces the displayed icon or adds it as a new one.
     * Useful to indicate loading processes. Default is false.
     */
    @Input() isLoading: boolean = false;

    iconToShow: string;

    constructor() {}

    ngOnInit() {}

    ngOnChanges(changes) {
        this.iconToShow = this.isLoading ? 'spinner-default' : this.icon;
    }
}
