<zk-popup-modal id="compatibility-feedback"
                class="compatibility-check"
                [headline]="headline"
                [closeOnClickOutside]="false"
                [showCloseButton]="false"
                (onClose)="onClose()"
                (onOpen)="initComponent()">

    <div modalContent *ngIf="isOpen">

        <wb-round-button size="m" class="ui-modal_close-button"
                         (click)="closeModal()">
            <wb-icon name="close"></wb-icon>
        </wb-round-button>

        <wb-text size="l" class="selected-vehicle">
            <span zkTranslate="COMPATIBILITYCHECK.YOURCAR"></span>
            <span class="selected-vehicle-text"> {{ selectedVehicleName }}</span>
        </wb-text>

        <div class="warning spacing-xs">
            <wb-notification hide-dismiss-button>
                <wb-icon src="ui/assets/icons/misc/bds_exclamation-mark-circle_24.svg"></wb-icon>
                <span zkTranslate="COMPATIBILITYCHECK.WARNING_VIN"></span>
            </wb-notification>
        </div>

        <!-- GROUP 1: Current articles that haven´t been added to the shopping cart -->
        <ng-container *ngIf="!wasAddedToCart && currentArticles?.length > 0">
            <div class="compatibility-check_articles">

                <wb-text size="l" strong class="headline" zkTranslate="COMPATIBILITYCHECK.DETAILARTICLE"></wb-text>

                <zk-article-list [articles]="currentArticles"></zk-article-list>
            </div>
            <!-- No VIN entered yet hint -->
            <wb-text *ngIf="!vinLocked && currentArticles[0].fit === undefined" size="l" class="info-text spacing-xxs">
                <span zkTranslate="PRODUCTDETAILS.VEHICLECOMPATIBILITYINFO"></span>
                <wb-link variant="standalone"
                         zkTranslate="PRODUCTDETAILS.COMPATIBILITYCHECK"
                         (click)="compatibilityCheck()">
                </wb-link>
            </wb-text>
            <!-- Current article is not fitting -->
            <p *ngIf="currentArticles[0].fit === false" class="wb-type-copy spacing-xxs" zkTranslate="COMPATIBILITYCHECK.NOTORDERABLEREASON"></p>
        </ng-container>

        <!-- GROUP 2: Compatible from wishlist -->
        <ng-container *ngIf="compatibleArticles.length > 0">
            <div class="compatibility-check_articles">
                <wb-text tag="h2" size="l" strong class="headline" zkTranslate="COMPATIBILITYCHECK.ARTICLEINWISHLIST"></wb-text>

                <zk-article-list [articles]="compatibleArticles"></zk-article-list>
            </div>
            <!-- No VIN entered yet hint -->
            <wb-text *ngIf="!vinLocked" size="l" class="info-text spacing-xxs">
                <span zkTranslate="PRODUCTDETAILS.VEHICLECOMPATIBILITYINFO"></span>
                <wb-link variant="standalone"
                         zkTranslate="PRODUCTDETAILS.COMPATIBILITYCHECK"
                         (click)="compatibilityCheck()">
                </wb-link>
            </wb-text>
        </ng-container>

        <!-- GROUP 3: Incompatible from wishlist -->
        <ng-container *ngIf="incompatibleArticles.length > 0">
            <div class="compatibility-check_articles">
                <wb-text tag="h2" size="l" strong class="headline" zkTranslate="COMPATIBILITYCHECK.ARTICLEREMOVEDWISHLIST"></wb-text>

                <zk-article-list [articles]="incompatibleArticles"></zk-article-list>
            </div>
            <p class="wb-type-copy spacing-xxs" zkTranslate="COMPATIBILITYCHECK.NOTORDERABLEREASON"></p>
        </ng-container>
    </div>

    <div modalFooter class="modal-footer">
        <wb-button variant="secondary"
                   class="button"
                   (click)="closeModal()">
            <span zkTranslate="GENERAL.CONTINUE"></span>
        </wb-button>
    </div>
</zk-popup-modal>
