<div *ngIf="isModalOpen" class="ui-modal"
     [id]="id"
     mbaGetMaxHeight="ui-modal_container"
     (sumOfHeight)="heightOfModalChanged($event)">
    <div class="ui-modal_container"
         [ngClass]="{'is-wider' : isWider, 'is-full-width' : isFullWidth}"
         [ngStyle]="{'top.px': distanceToTop}">

        <div class="ui-modal_wrapper"
             [ngClass]="{'is-wider' : isWider, 'is-full-width' : isFullWidth}">
            <!-- Header part -->
            <wb-heading *ngIf="headline" size="m" class="headline">{{headline}}</wb-heading>
            <wb-round-button *ngIf="showCloseButton" size="m" class="ui-modal_close-button"
                             (click)="closeModal(id, false)">
                <wb-icon name="close"></wb-icon>
            </wb-round-button>

            <!-- Projected content part defined by component -->
            <ng-content select="[modalContent]"></ng-content>
            <ng-content select="[modalFooter]"></ng-content>
        </div>
    </div>
    <!-- Background dimmer -->
    <div class="ui-modal_background"
         (click)="closeModal(id, true)">
    </div>
</div>
