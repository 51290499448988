// ng
import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
// services
import { GroupMapperService } from '@shared/shared-services/group-mapper/group-mapper.service';
import { ShoppingCartService } from '@shared/shared-services/shopping-cart/shopping-cart.service';
import { AppService } from '../../../app.service';
import { TranslationService } from '@shared/shared-services/translate/translation.service';
import { SelectedCarService } from '@shared/shared-services/selected-car/selected-car.service';
// model
import { Article } from '@shared/global-models/article/article.model';
import { AnalyticsArticle } from '@shared/shared-services/google-analytics/google-analytics.model';
import { ShoppingCartItemModalInfoModel } from '@shared/shared-services/shopping-cart/model/shopping-cart-item-modal-info.model';
import { ShoppingCartItemAction } from '@shared/shared-services/shopping-cart/model/shopping-cart-item-action.model';


// Google Analytics
declare let gtag: Function;

@Injectable()
export class GoogleAnalyticsService {
    private _shoppingCartChangedSub: Subscription = new Subscription();
    private _carChangedSubscription: Subscription = new Subscription();

    constructor(private _groupMapperService: GroupMapperService,
                private _shoppingCartService: ShoppingCartService,
                private _appService: AppService,
                private _translationService: TranslationService,
                private _selectedCarService: SelectedCarService) {

        // handle e-commerce related events
        this._shoppingCartChangedSub = this._shoppingCartService.shoppingCartItemAddedOrRemovedInfo.subscribe(
        (response: ShoppingCartItemModalInfoModel) => {
                if (response.action === ShoppingCartItemAction.ITEM_ADD || response.action === ShoppingCartItemAction.ITEM_QUANTITY_RAISED) {
                    this.trackAddRemoveFromCart(response.items, "add_to_cart");
                } else if (response.action === ShoppingCartItemAction.ITEM_REMOVE) {
                    this.trackAddRemoveFromCart(response.items, "remove_from_cart");
                }
             },
        error => console.log('Error: ', error));

        // listen to car logging
        this._carChangedSubscription = this._selectedCarService.carLineSubscriber.subscribe(() => {
            this.trackLoggedInCar();
        });
    }

    /**
     * NOTE: Whenever you create a custom event,
     * you need to create a corresponding custom dimension or metric in GA,
     * so you can see detailed data in GA reports.
     */

    /**
     * Send Event to Google Analytics.
     * @param eventName The value that will appear as the event action in Google Analytics Event reports.
     * @param eventPosition Position where event occurred.
     * @param eventLabel The label of the event.
     * @param eventValue Will appear as the event value.
     * Parameters will be sent only if they have value.
     * Used in Templates with "zkTrackClick" directive
     */
    public trackEvent(
        eventName: string,
        eventPosition: string,
        eventLabel: string = null,
        eventValue: string = null
    ): void {
        gtag('event', eventName, {
            ...this.getAppMode && {'app_mode': this.getAppMode},
            ...eventPosition && {'event_position': eventPosition},
            ...eventLabel && {'event_label': eventLabel},
            ...eventValue && {'value': eventValue}
        });
    }

    /**
     * Tracking page Views
     * @param pageTitle (PageType)
     */
    public trackPageView(pageTitle: string): void {
        gtag('event', 'page_view', {
             ...this.getAppMode && {'app_mode': this.getAppMode},
             'page_title': pageTitle,
             'page_location': location.href,
             'page_path': location.pathname,
             'page_market': this._appService.currentMarket.marketCode,
             'page_language': this._translationService.currentLang
        });
    }

    /**
     * Tracks information about products that have been viewed.
     * "A user views a list of one or more products."
     */
    public trackProductImpressions(articles?: Article[]): void {
        let items: AnalyticsArticle[] = [];

        if (typeof articles !== 'undefined') {
            articles.forEach((x) => items.push(this.convertArticleToAnalyticsFieldObject(x)));
        }

        gtag('event', 'view_item_list', {
            "app_mode": this.getAppMode,
            "items": items
        });
    }

    /**
     * Track information about Product Detail Views.
     * @param article
     */
    public trackProductDetailView(article: Article): void {
        const item: AnalyticsArticle = this.convertArticleToAnalyticsFieldObject(article);

        gtag('event', 'view_item', {
            "app_mode": this.getAppMode,
            "items": [item]
        });
    }

    /**
     * Track adding or removing articles in shopping cart/wishlist.
     * @param articles
     * @param event 'add_to_cart' / 'remove_from_cart'
     */
    public trackAddRemoveFromCart(articles: Article[], event: string): void {
        let items: AnalyticsArticle[] = [];
        articles.forEach((x) => items.push(this.convertArticleToAnalyticsFieldObject(x)));

        gtag('event', event, {
            "app_mode": this.getAppMode,
            "items": items
        });
    }

    /**
     * Track app mode switch.
     * @param position
     */
    public trackModeSwitch(position: string): void {
        gtag('event', 'mode_switch', {
            "app_mode": this.getAppMode,
            "position": position
        });
    }

    /**
     * Track Model start page button click
     * @param position 'header' (desktop), 'header-menu' (mobile), 'teaser'
     */
    public trackModelStartPageNavigate(position: string): void {
        gtag('event', 'model_start_page_navigate', {
            "position": position
        });
    }

    /**
     * Track undo remove article from wishlist.
     * @param article
     */
    public trackUndoRemove(article: Article): void {
        gtag('event', 'undo_remove', {
            "app_mode": this.getAppMode,
            "article": article
        });
    }

    /**
     * Track show more teaser in list pages (Discover all products in {category}).
     * @param category
     * @param level
     */
    public trackShowMore(category: string, level?: boolean): void {
        gtag('event', 'show_more_teaser', {
            "app_mode": this.getAppMode,
            "category": category,
            "level": level ? 2 : 1,
        });
    }

    /**
     * Track Stage Slider CTA click.
     * @param url
     */
    public trackStageSlider(url: string): void {
        gtag('event', 'stage_slider', {
            "app_mode": this.getAppMode,
            "slider_url": url,
        });
    }

    /**
     * Track Article Variant switch.
     * @param article current article
     * @param articleVariant article to switch
     * @param variantType color / size
     * @param position
     */
    public trackArticleVariantSwitch(article: string, articleVariant: string, variantType: string, position: string): void {
        if (article === articleVariant) return;

        gtag('event', 'article_variant', {
            "app_mode": this.getAppMode,
            "current_article": article,
            "variant_article": articleVariant,
            "variant_type": variantType,
            "position": position,
        });
    }

    /**
     * Track Search Results on Result page
     * @param term
     * @param resultsAcc
     * @param resultColl
     */
    public trackSearchResults(term: string, resultsAcc: number, resultColl: number): void {
        if (resultsAcc === 0 && resultColl === 0) {
            gtag('event', 'search_no_result', {
                "app_mode": this.getAppMode,
                "search_term": term,
            });
        } else {
            gtag('event', 'search', {
                "app_mode": this.getAppMode,
                "search_term": term,
                "result_acc": resultsAcc,
                "result_coll": resultColl,
            });
        }
    }

    /**
     * Track Search Init.
     * Indicate that user is performing search, within page, search is performed on.
     * Useful for visualising user search behaviour in combination with "search / search_no_result" events
     * @param term
     */
    public trackSearchInit(term: string): void {
        gtag('event', 'search_init', {
            "app_mode": this.getAppMode,
            "page_path": location.pathname,
            "search_term": term,
        });
    }

    /**
     * Track logged in car
     */
    public trackLoggedInCar(): void {
        if (this._selectedCarService.carClass && this._selectedCarService.carClass.name) {
            gtag('event', 'logged_car', {
                ...this._selectedCarService.vehicleType.name && {'vehicle_type': this._selectedCarService.vehicleType.name},
                ...this._selectedCarService.carClass.name && {'vehicle_class': this._selectedCarService.carClass.name},
                ...this._selectedCarService.carLine.trackingCode && {'model_code': this._selectedCarService.carLine.trackingCode},
                ...this._selectedCarService.bodyType.name && {'vehicle_body': this._selectedCarService.bodyType.name}
            })
        } else {
            gtag('event', 'logged_out_car')
        }
    }

    // PRIVATE //

    private convertArticleToAnalyticsFieldObject(article: Article): AnalyticsArticle {
        return  {
            item_id: article.articleId,
            item_name: article.headline,
            item_brand: article.productType,
            item_category: this.getCategoryFromArticle(article),
            item_variant: article.headline2,
            price: article.grossPrice as number,
            quantity: article.quantity
        }
    }

    private getCategoryFromArticle(article: Article): string {
        const mainCat = this._groupMapperService.getGroupUrlNameFromGroupId(article.groupIdPath[0]);
        const subCat = this._groupMapperService.getGroupUrlNameFromGroupId(article.groupIdPath[1]);

        return mainCat +'/'+subCat;
    }

    /**
     * Get current App Modi. Used for every event.
     * Method 'replace' used to strip / chars from modi.
     */
    get getAppMode(): string {
        return this._appService.appMode.replace(/\//g, "");
    }
}
