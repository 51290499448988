<button class="ui-button button"
        (click)="clickHandler($event)"
        [ngClass]="{'is-active': isActive,
                    'is-disabled': disabled,
                    'is-dark': darkBackground,
                    'is-darker': darkerBackground,
                    'is-fullSize': isFullSize,
                    'is-light': darkPictures,
                    'is-link': isLink,
                    'is-link_light': isLinkLight,
                    'is-link_red': isLinkRed,
                    'is-link_error': hasError,
                    'is-loading': isLoading,
                    'is-right-icon': iconRight,
                    'has-icon-only': hasIconOnly}">
    <mba-icon [icon]="icon"
              [isLoading]="isLoading"
              [ngClass]="{'has-icon-on-mobile': hasIconOnMobile}"
              [iconClass]="{'icon_in-button': true,
                            'icon_spinner': isLoading,
                            'right': isLoading && iconRight,
                            'center': isLoading && hasIconOnly,
                            'icon_bigger-size': hasBiggerIcon}"
              class="ui-button_icon {{ icon }}">
    </mba-icon>
    <ng-content></ng-content>
</button>
