<mba-button
    [isActive]="isSelected"
    [darkPictures]="theme === ComponentTheme.LIGHT"
    [darkBackground]="theme === ComponentTheme.GREY"
    [darkerBackground]="theme === ComponentTheme.DARK"
    icon="none"
    [isFullSize]="true"
    (clicked)="toggleItem()">
    {{title}}
</mba-button>
