// ng
import { Router } from "@angular/router";
import { Component } from '@angular/core';
// Service
import { TranslationService } from '@shared/shared-services/translate/translation.service';
import { PopupModalService } from "@shared/modals/popup-modal/popup-modal.service";
import { SelectedCarService } from "@shared/shared-services/selected-car/selected-car.service";

@Component({
    selector: 'zk-category-not-available-modal',
    templateUrl: 'category-not-available-modal.component.html',
    styleUrls: ['./category-not-available-modal.component.scss']
})
export class CategoryNotAvailableModalComponent {
    modalHeadline = this.translationService.translate('PRODUCTLIST.INFO.HEADLINE');

    constructor(
        private router: Router,
        private translationService: TranslationService,
        private modalService: PopupModalService,
        private selectedCarService: SelectedCarService
    ) {}

    onContinue() {
        this.redirect();
        this.modalService.close('category-not-available-modal');
    }

    /**
     * Redirects to the model start page or start page.
     * Used if category does not exist for the new logged in vehicle.
     */
    redirect() {
        const url = this.selectedCarService.carLine ? this.selectedCarService.carLine.urlName : '/';
        this.router.navigate([url]).then();
    }
}
