{
    "de": {
        "linkOutName": "Emissionsangabe",
        "linkOutUrl": "https://www.mercedes-amg.com/de/footer/emissions-statement.html",
        "carChooser": {
            "177051": "*Kraftstoffverbrauch kombiniert: 8,4 - 7,3 l/100 km | CO2-Emissionen kombiniert: 192 - 167 g/km",
            "177053": "*Kraftstoffverbrauch kombiniert: 8,4 - 7,3 l/100 km | CO2-Emissionen kombiniert: 192 - 167 g/km",
            "176052": "*Kraftstoffverbrauch kombiniert: 8,4 - 7,3 l/100 km | CO2-Emissionen kombiniert: 192 - 167 g/km",
            "177054": "*Kraftstoffverbrauch kombiniert: 8,4 - 7,3 l/100 km | CO2-Emissionen kombiniert: 192 - 167 g/km",
            "177151": "*Kraftstoffverbrauch kombiniert: 7,3 - 7,2 l/100 km | CO2-Emissionen kombiniert: 167 - 164 g/km",
            "205464": "*Kraftstoffverbrauch kombiniert: 10,4 - 9,5 l/100 km | CO2-Emissionen kombiniert: 236 - 217 g/km",
            "205486": "*Kraftstoffverbrauch kombiniert: 10,4 - 9,5 l/100 km | CO2-Emissionen kombiniert: 236 - 217 g/km",
            "205487": "*Kraftstoffverbrauch kombiniert: 10,4 - 9,5 l/100 km | CO2-Emissionen kombiniert: 236 - 217 g/km",
            "205364": "*Kraftstoffverbrauch kombiniert: 10,1 - 9,2 l/100 km | CO2-Emissionen kombiniert: 230 - 211 g/km",
            "205386": "*Kraftstoffverbrauch kombiniert: 10,1 - 9,2 l/100 km | CO2-Emissionen kombiniert: 230 - 211 g/km",
            "205387": "*Kraftstoffverbrauch kombiniert: 10,1 - 9,2 l/100 km | CO2-Emissionen kombiniert: 230 - 211 g/km",
            "205064": "*Kraftstoffverbrauch kombiniert: 9,9 - 9,1 l/100 km | CO2-Emissionen kombiniert: 227 - 208 g/km",
            "205086": "*Kraftstoffverbrauch kombiniert: 9,9 - 9,1 l/100 km | CO2-Emissionen kombiniert: 227 - 208 g/km",
            "205087": "*Kraftstoffverbrauch kombiniert: 9,9 - 9,1 l/100 km | CO2-Emissionen kombiniert: 227 - 208 g/km",
            "205264": "*Kraftstoffverbrauch kombiniert: 10 - 9,3 l/100 km | CO2-Emissionen kombiniert: 229 - 214 g/km",
            "205287": "*Kraftstoffverbrauch kombiniert: 10 - 9,3 l/100 km | CO2-Emissionen kombiniert: 229 - 214 g/km",
            "205286": "*Kraftstoffverbrauch kombiniert: 10 - 9,3 l/100 km | CO2-Emissionen kombiniert: 229 - 214 g/km",
            "118351": "*Kraftstoffverbrauch kombiniert: 8,3 - 7,2 l/100 km | CO2-Emissionen kombiniert: 189 - 164 g/km",
            "118353": "*Kraftstoffverbrauch kombiniert: 8,3 - 7,2 l/100 km | CO2-Emissionen kombiniert: 189 - 164 g/km",
            "117352": "*Kraftstoffverbrauch kombiniert: 8,3 - 7,2 l/100 km | CO2-Emissionen kombiniert: 189 - 164 g/km",
            "118354": "*Kraftstoffverbrauch kombiniert: 8,3 - 7,2 l/100 km | CO2-Emissionen kombiniert: 189 - 164 g/km",
            "117952": "*Kraftstoffverbrauch kombiniert: 8,4 - 7,4 l/100 km | CO2-Emissionen kombiniert: 191 - 168 g/km",
            "257361": "*Kraftstoffverbrauch kombiniert: 8,8 - 8,7 l/100 km | CO2-Emissionen kombiniert: 202 - 199 g/km",
            "218392": "*Kraftstoffverbrauch kombiniert: 8,8 - 8,7 l/100 km | CO2-Emissionen kombiniert: 202 - 199 g/km",
            "218374": "*Kraftstoffverbrauch kombiniert: 8,8 - 8,7 l/100 km | CO2-Emissionen kombiniert: 202 - 199 g/km",
            "218376": "*Kraftstoffverbrauch kombiniert: 8,8 - 8,7 l/100 km | CO2-Emissionen kombiniert: 202 - 199 g/km",
            "218375": "*Kraftstoffverbrauch kombiniert: 8,8 - 8,7 l/100 km | CO2-Emissionen kombiniert: 202 - 199 g/km",
            "238461": "*Kraftstoffverbrauch kombiniert: 8,9 - 8,8 l/100 km | CO2-Emissionen kombiniert: 204 - 201 g/km",
            "238361": "*Kraftstoffverbrauch kombiniert: 8,8 - 8,7 l/100 km | CO2-Emissionen kombiniert: 202 - 199 g/km",
            "213064": "*Kraftstoffverbrauch kombiniert: 10,8 - 8,6 l/100 km | CO2-Emissionen kombiniert: 245 - 197 g/km",
            "213061": "*Kraftstoffverbrauch kombiniert: 10,8 - 8,6 l/100 km | CO2-Emissionen kombiniert: 245 - 197 g/km",
            "212092": "*Kraftstoffverbrauch kombiniert: 10,8 - 8,6 l/100 km | CO2-Emissionen kombiniert: 245 - 197 g/km",
            "213088": "*Kraftstoffverbrauch kombiniert: 10,8 - 8,6 l/100 km | CO2-Emissionen kombiniert: 245 - 197 g/km",
            "212074": "*Kraftstoffverbrauch kombiniert: 10,8 - 8,6 l/100 km | CO2-Emissionen kombiniert: 245 - 197 g/km",
            "212076": "*Kraftstoffverbrauch kombiniert: 10,8 - 8,6 l/100 km | CO2-Emissionen kombiniert: 245 - 197 g/km",
            "213089": "*Kraftstoffverbrauch kombiniert: 10,8 - 8,6 l/100 km | CO2-Emissionen kombiniert: 245 - 197 g/km",
            "212075": "*Kraftstoffverbrauch kombiniert: 10,8 - 8,6 l/100 km | CO2-Emissionen kombiniert: 245 - 197 g/km",
            "213264": "*Kraftstoffverbrauch kombiniert: 10,8 - 8,7 l/100 km | CO2-Emissionen kombiniert: 246 - 200 g/km",
            "213261": "*Kraftstoffverbrauch kombiniert: 10,8 - 8,7 l/100 km | CO2-Emissionen kombiniert: 246 - 200 g/km",
            "212292": "*Kraftstoffverbrauch kombiniert: 10,8 - 8,7 l/100 km | CO2-Emissionen kombiniert: 246 - 200 g/km",
            "213288": "*Kraftstoffverbrauch kombiniert: 10,8 - 8,7 l/100 km | CO2-Emissionen kombiniert: 246 - 200 g/km",
            "212276": "*Kraftstoffverbrauch kombiniert: 10,8 - 8,7 l/100 km | CO2-Emissionen kombiniert: 246 - 200 g/km",
            "213289": "*Kraftstoffverbrauch kombiniert: 10,8 - 8,7 l/100 km | CO2-Emissionen kombiniert: 246 - 200 g/km",
            "212275": "*Kraftstoffverbrauch kombiniert: 10,8 - 8,7 l/100 km | CO2-Emissionen kombiniert: 246 - 200 g/km",
            "212274": "*Kraftstoffverbrauch kombiniert: 10,8 - 8,7 l/100 km | CO2-Emissionen kombiniert: 246 - 200 g/km",
            "463272": "*Kraftstoffverbrauch kombiniert: 13,3 - 13,1 l/100 km | CO2-Emissionen kombiniert: 304 - 299 g/km",
            "463277": "*Kraftstoffverbrauch kombiniert: 13,3 - 13,1 l/100 km | CO2-Emissionen kombiniert: 304 - 299 g/km",
            "463274": "*Kraftstoffverbrauch kombiniert: 13,3 - 13,1 l/100 km | CO2-Emissionen kombiniert: 304 - 299 g/km",
            "253364": "*Kraftstoffverbrauch kombiniert: 12,3 - 10,2 l/100 km | CO2-Emissionen kombiniert: 280 - 232 g/km",
            "253388": "*Kraftstoffverbrauch kombiniert: 12,3 - 10,2 l/100 km | CO2-Emissionen kombiniert: 280 - 232 g/km",
            "253389": "*Kraftstoffverbrauch kombiniert: 12,3 - 10,2 l/100 km | CO2-Emissionen kombiniert: 280 - 232 g/km",
            "253964": "*Kraftstoffverbrauch kombiniert: 12,4 - 10,2 l/100 km | CO2-Emissionen kombiniert: 283 - 234 g/km",
            "253988": "*Kraftstoffverbrauch kombiniert: 12,4 - 10,2 l/100 km | CO2-Emissionen kombiniert: 283 - 234 g/km",
            "253989": "*Kraftstoffverbrauch kombiniert: 12,4 - 10,2 l/100 km | CO2-Emissionen kombiniert: 283 - 234 g/km",
            "292364": "*Kraftstoffverbrauch kombiniert: 9,3 l/100 km | CO2-Emissionen kombiniert: 212 g/km",
            "292374": "*Kraftstoffverbrauch kombiniert: 9,3 l/100 km | CO2-Emissionen kombiniert: 212 g/km",
            "292375": "*Kraftstoffverbrauch kombiniert: 9,3 l/100 km | CO2-Emissionen kombiniert: 212 g/km",
            "166064": "*Kraftstoffverbrauch kombiniert: 9,3 l/100 km | CO2-Emissionen kombiniert: 212 g/km",
            "166074": "*Kraftstoffverbrauch kombiniert: 9,3 l/100 km | CO2-Emissionen kombiniert: 212 g/km",
            "167161": "*Kraftstoffverbrauch kombiniert: 9,3 l/100 km | CO2-Emissionen kombiniert: 212 g/km",
            "167188": "*Kraftstoffverbrauch kombiniert: 9,3 l/100 km | CO2-Emissionen kombiniert: 212 g/km",
            "166075": "*Kraftstoffverbrauch kombiniert: 9,3 l/100 km | CO2-Emissionen kombiniert: 212 g/km",
            "167189": "*Kraftstoffverbrauch kombiniert: 9,3 l/100 km | CO2-Emissionen kombiniert: 212 g/km",
            "217478": "*Kraftstoffverbrauch kombiniert: 11,5 l/100 km | CO2-Emissionen kombiniert: 263 g/km",
            "217488": "*Kraftstoffverbrauch kombiniert: 11,5 l/100 km | CO2-Emissionen kombiniert: 263 g/km",
            "217477": "*Kraftstoffverbrauch kombiniert: 11,5 l/100 km | CO2-Emissionen kombiniert: 263 g/km",
            "217487": "*Kraftstoffverbrauch kombiniert: 11,5 l/100 km | CO2-Emissionen kombiniert: 263 g/km",
            "217479": "*Kraftstoffverbrauch kombiniert: 11,5 l/100 km | CO2-Emissionen kombiniert: 263 g/km",
            "217378": "*Kraftstoffverbrauch kombiniert: 11,2 l/100 km | CO2-Emissionen kombiniert: 254 g/km",
            "217388": "*Kraftstoffverbrauch kombiniert: 11,2 l/100 km | CO2-Emissionen kombiniert: 254 g/km",
            "217387": "*Kraftstoffverbrauch kombiniert: 11,2 l/100 km | CO2-Emissionen kombiniert: 254 g/km",
            "217377": "*Kraftstoffverbrauch kombiniert: 11,2 l/100 km | CO2-Emissionen kombiniert: 254 g/km",
            "217379": "*Kraftstoffverbrauch kombiniert: 11,2 l/100 km | CO2-Emissionen kombiniert: 254 g/km",
            "222077": "*Kraftstoffverbrauch kombiniert: 14,2 - 11,2 l/100 km | CO2-Emissionen kombiniert: 325 - 255 g/km",
            "222188": "*Kraftstoffverbrauch kombiniert: 14,2 - 11,2 l/100 km | CO2-Emissionen kombiniert: 325 - 255 g/km",
            "222187": "*Kraftstoffverbrauch kombiniert: 14,2 - 11,2 l/100 km | CO2-Emissionen kombiniert: 325 - 255 g/km",
            "222179": "*Kraftstoffverbrauch kombiniert: 14,2 - 11,2 l/100 km | CO2-Emissionen kombiniert: 325 - 255 g/km",
            "231474": "*Kraftstoffverbrauch kombiniert: 11,6 l/100 km | CO2-Emissionen kombiniert: 264 g/km",
            "231479": "*Kraftstoffverbrauch kombiniert: 11,6 l/100 km | CO2-Emissionen kombiniert: 264 g/km",
            "172475": "*Kraftstoffverbrauch kombiniert: 8,2 l/100 km | CO2-Emissionen kombiniert: 186 g/km",
            "172466": "*Kraftstoffverbrauch kombiniert: 8,2 l/100 km | CO2-Emissionen kombiniert: 186 g/km",
            "190380": "*Kraftstoffverbrauch kombiniert: 12,4 - 11,4 l/100 km | CO2-Emissionen kombiniert: 284 - 261 g/km",
            "190377": "*Kraftstoffverbrauch kombiniert: 12,4 - 11,4 l/100 km | CO2-Emissionen kombiniert: 284 - 261 g/km",
            "190379": "*Kraftstoffverbrauch kombiniert: 12,4 - 11,4 l/100 km | CO2-Emissionen kombiniert: 284 - 261 g/km",
            "190378": "*Kraftstoffverbrauch kombiniert: 12,4 - 11,4 l/100 km | CO2-Emissionen kombiniert: 284 - 261 g/km",
            "190480": "*Kraftstoffverbrauch kombiniert: 12,5 - 11,5 l/100 km | CO2-Emissionen kombiniert: 284 - 262 g/km",
            "190477": "*Kraftstoffverbrauch kombiniert: 12,5 - 11,5 l/100 km | CO2-Emissionen kombiniert: 284 - 262 g/km",
            "190478": "*Kraftstoffverbrauch kombiniert: 12,5 - 11,5 l/100 km | CO2-Emissionen kombiniert: 284 - 262 g/km",
            "118651": "*Kraftstoffverbrauch kombiniert: 8,4 - 7,4 l/100 km | CO2-Emissionen kombiniert: 191 - 168 g/km",
            "118653": "*Kraftstoffverbrauch kombiniert: 8,4 - 7,4 l/100 km | CO2-Emissionen kombiniert: 191 - 168 g/km",
            "118654": "*Kraftstoffverbrauch kombiniert: 8,4 - 7,4 l/100 km | CO2-Emissionen kombiniert: 191 - 168 g/km",
            "247651": "*Kraftstoffverbrauch kombiniert: 7,6 - 7,5 l/100 km | CO2-Emissionen kombiniert: 173 - 171 g/km",
            "247751": "*Kraftstoffverbrauch kombiniert: 7,5 - 7,4 l/100 km | CO2-Emissionen kombiniert: 171 - 170 g/km",
            "167989": "*Kraftstoffverbrauch kombiniert: 12 - 11,9 l/100 km | CO2-Emissionen kombiniert: 273 g/km",
            "290658": "*Kraftstoffverbrauch kombiniert: 11,3 - 9,1 l/100 km | CO2-Emissionen kombiniert: 257 - 209 g/km",
            "290659": "*Kraftstoffverbrauch kombiniert: 11,3 - 9,1 l/100 km | CO2-Emissionen kombiniert: 257 - 209 g/km",
            "290661": "*Kraftstoffverbrauch kombiniert: 11,3 - 9,1 l/100 km | CO2-Emissionen kombiniert: 257 - 209 g/km",
            "290688": "*Kraftstoffverbrauch kombiniert: 11,3 - 9,1 l/100 km | CO2-Emissionen kombiniert: 257 - 209 g/km",
            "290689": "*Kraftstoffverbrauch kombiniert: 11,3 - 9,1 l/100 km | CO2-Emissionen kombiniert: 257 - 209 g/km"
        },
        "modelStartPage": {
            "177051": "*Kraftstoffverbrauch kombiniert: 7,4 - 7,3 l/100 km | CO2-Emissionen kombiniert: 169 - 167 g/km",
            "177053": "*Kraftstoffverbrauch kombiniert: 7,4 - 7,3 l/100 km | CO2-Emissionen kombiniert: 169 - 167 g/km",
            "177054": "*Kraftstoffverbrauch kombiniert: 8,4 - 8,3 l/100 km | CO2-Emissionen kombiniert: 192 - 189 g/km",
            "177151": "*Kraftstoffverbrauch kombiniert: 7,3 - 7,2 l/100 km | CO2-Emissionen kombiniert: 167 - 164 g/km",
            "205464": "*Kraftstoffverbrauch kombiniert: 9,8 - 9,5 l/100 km | CO2-Emissionen kombiniert: 223 - 217 g/km",
            "205486": "*Kraftstoffverbrauch kombiniert: 10,4 l/100 km | CO2-Emissionen kombiniert: 236 g/km ",
            "205487": "*Kraftstoffverbrauch kombiniert: 10,4 l/100 km | CO2-Emissionen kombiniert: 236 g/km ",
            "205364": "*Kraftstoffverbrauch kombiniert: 9,5 - 9,2 l/100 km | CO2-Emissionen kombiniert: 217 - 211 g/km",
            "205386": "*Kraftstoffverbrauch kombiniert: 10,1 l/100 km | CO2-Emissionen kombiniert: 230 g/km",
            "205387": "*Kraftstoffverbrauch kombiniert: 10,1 l/100 km | CO2-Emissionen kombiniert: 230 g/km",
            "205064": "*Kraftstoffverbrauch kombiniert: 9,3 - 9,1 l/100 km | CO2-Emissionen kombiniert: 213 - 208 g/km",
            "205086": "*Kraftstoffverbrauch kombiniert: 10 l/100 km | CO2-Emissionen kombiniert: 229 g/km",
            "205087": "*Kraftstoffverbrauch kombiniert: 9,9 l/100 km | CO2-Emissionen kombiniert: 227 g/km",
            "205264": "*Kraftstoffverbrauch kombiniert: 9,6 - 9,3 l/100 km | CO2-Emissionen kombiniert: 219 - 214 g/km",
            "205287": "*Kraftstoffverbrauch kombiniert: 10 l/100 km | CO2-Emissionen kombiniert: 228 g/km",
            "205286": "*Kraftstoffverbrauch kombiniert: 10 l/100 km | CO2-Emissionen kombiniert: 229 g/km",
            "118351": "*Kraftstoffverbrauch kombiniert: 7,3 - 7,2 l/100 km | CO2-Emissionen kombiniert: 167 - 164 g/km",
            "118353": "*Kraftstoffverbrauch kombiniert: 8,2 - 8,1 l/100 km | CO2-Emissionen kombiniert: 188 - 185 g/km",
            "118354": "*Kraftstoffverbrauch kombiniert: 8,3 - 8,1 l/100 km | CO2-Emissionen kombiniert: 189 - 186 g/km",
            "257361": "*Kraftstoffverbrauch kombiniert: 8,8 - 8,7 l/100 km | CO2-Emissionen kombiniert: 202 - 199 g/km",
            "238461": "*Kraftstoffverbrauch kombiniert: 8,9 - 8,8 l/100 km | CO2-Emissionen kombiniert: 204 - 201 g/km",
            "238361": "*Kraftstoffverbrauch kombiniert: 8,8 - 8,7 l/100 km | CO2-Emissionen kombiniert: 202 - 199 g/km",
            "213061": "*Kraftstoffverbrauch kombiniert: 8,7 - 8,6 l/100 km | CO2-Emissionen kombiniert: 200 - 197 g/km",
            "213088": "*Kraftstoffverbrauch kombiniert: 10,8 l/100 km | CO2-Emissionen kombiniert: 245 g/km",
            "213089": "*Kraftstoffverbrauch kombiniert: 10,8 l/100 km | CO2-Emissionen kombiniert: 245 g/km",
            "213261": "*Kraftstoffverbrauch kombiniert: 8,9 - 8,7 l/100 km | CO2-Emissionen kombiniert: 203 - 200 g/km",
            "213288": "*Kraftstoffverbrauch kombiniert: 10,8 l/100 km | CO2-Emissionen kombiniert: 246 g/km",
            "213289": "Kraftstoffverbrauch kombiniert: 10,8 l/100 km | CO2-Emissionen kombiniert: 246 g/km",
            "463272": "*Kraftstoffverbrauch kombiniert: 13,3 - 13,1 l/100 km | CO2-Emissionen kombiniert: 304 - 299 g/km",
            "253364": "*Kraftstoffverbrauch kombiniert: 10,5 - 10,2 l/100 km | CO2-Emissionen kombiniert: 240 - 232 g/km",
            "253388": "*Kraftstoffverbrauch kombiniert: 12,3 - 12,1 l/100 km | CO2-Emissionen kombiniert: 280 - 275 g/km",
            "253389": "*Kraftstoffverbrauch kombiniert: 12,3 l/100 km | CO2-Emissionen kombiniert: 280 g/km",
            "253964": "*Kraftstoffverbrauch kombiniert: 10,6 - 10,2 l/100 km | CO2-Emissionen kombiniert: 242 - 234 g/km",
            "253988": "*Kraftstoffverbrauch kombiniert: 12,4 - 12,2 l/100 km | CO2-Emissionen kombiniert: 283 - 278 g/km",
            "253989": "*Kraftstoffverbrauch kombiniert: 12,4 l/100 km | CO2-Emissionen kombiniert: 283 g/km",
            "167161": "*Kraftstoffverbrauch kombiniert: 9,3 l/100 km | CO2-Emissionen kombiniert: 212 g/km",
            "217488": "*Kraftstoffverbrauch kombiniert: 11,5 l/100 km | CO2-Emissionen kombiniert: 263 g/km",
            "217379": "*Kraftstoffverbrauch kombiniert: 11,2 l/100 km | CO2-Emissionen kombiniert: 254 g/km",
            "222077": "*Kraftstoffverbrauch kombiniert: 11,2 l/100 km | CO2-Emissionen kombiniert: 255 g/km",
            "222188": "*Kraftstoffverbrauch kombiniert: 11,2 l/100 km | CO2-Emissionen kombiniert: 255 g/km",
            "222179": "*Kraftstoffverbrauch kombiniert: 11,2 l/100 km | CO2-Emissionen kombiniert: 255 g/km",
            "231474": "*Kraftstoffverbrauch kombiniert: 11,6 l/100 km | CO2-Emissionen kombiniert: 264 g/km",
            "172466": "*Kraftstoffverbrauch kombiniert: 8,2 l/100 km | CO2-Emissionen kombiniert: 186 g/km",
            "190380": "*Kraftstoffverbrauch kombiniert: 12,4 l/100 km | CO2-Emissionen kombiniert: 284 g/km",
            "190377": "*Kraftstoffverbrauch kombiniert: 11,4 l/100 km | CO2-Emissionen kombiniert: 261 g/km ",
            "190379": "*Kraftstoffverbrauch kombiniert: 12,4 l/100 km | CO2-Emissionen kombiniert: 284 g/km",
            "190378": "*Kraftstoffverbrauch kombiniert: 11,5 l/100 km | CO2-Emissionen kombiniert: 262 g/km",
            "190480": "*Kraftstoffverbrauch kombiniert: 12,5 l/100 km | CO2-Emissionen kombiniert: 284 g/km",
            "190477": "*Kraftstoffverbrauch kombiniert: 11,5 l/100 km | CO2-Emissionen kombiniert: 262 g/km",
            "190478": "*Kraftstoffverbrauch kombiniert: 11,5 l/100 km | CO2-Emissionen kombiniert: 262 g/km",
            "118651": "*Kraftstoffverbrauch kombiniert: 7,5 - 7,4 l/100 km | CO2-Emissionen kombiniert: 171 - 168 g/km",
            "118653": "*Kraftstoffverbrauch kombiniert: 8,3 - 8,2 l/100 km | CO2-Emissionen kombiniert: 191 - 188 g/km",
            "118654": "*Kraftstoffverbrauch kombiniert: 8,4 - 8,2 l/100 km | CO2-Emissionen kombiniert: 191 - 188 g/km",
            "247651": "*Kraftstoffverbrauch kombiniert: 7,6 - 7,5 l/100 km | CO2-Emissionen kombiniert: 173 - 171 g/km",
            "247751": "*Kraftstoffverbrauch kombiniert: 7,5 - 7,4 l/100 km | CO2-Emissionen kombiniert: 171 - 170 g/km",
            "167989": "*Kraftstoffverbrauch kombiniert: 12 - 11,9 l/100 km | CO2-Emissionen kombiniert: 273 g/km",
            "290659": "*Kraftstoffverbrauch kombiniert: 9,4 - 9,1 l/100 km | CO2-Emissionen kombiniert: 215 - 209 g/km",
            "290661": "*Kraftstoffverbrauch kombiniert: 9,4 - 9,1 l/100 km | CO2-Emissionen kombiniert: 215 - 209 g/km",
            "290688": "*Kraftstoffverbrauch kombiniert: 11,2 - 11,1 l/100 km | CO2-Emissionen kombiniert: 256 - 252 g/km",
            "290689": "*Kraftstoffverbrauch kombiniert: 11,3 l/100 km | CO2-Emissionen kombiniert: 257 g/km"
        },
        "footerInfo": {
            "text1": "Weitere Informationen zum offiziellen Kraftstoffverbrauch und den offiziellen spezifischen CO2-Emissionen neuer Personenkraftwagen können dem ",
            "linkOutName1": "'Leitfaden über den Kraftstoffverbrauch, die CO2-Emissionen und den Stromverbrauch'",
            "linkOutUrl1": "https://www.dat.de/fileadmin/media/LeitfadenCO2/LeitfadenCO2.pdf",
            "text2": " neuer Personenkraftwagen entnommen werden, der an allen Verkaufsstellen und bei der Deutschen Automobil Treuhand GmbH unter ",
            "linkOutName2": "www.dat.de",
            "linkOutUrl2": "https://www.dat.de/",
            "text3": " unentgeltlich erhältlich ist."
        }
    },
    "en": {
        "linkOutName": "Emission Statement",
        "linkOutUrl": "https://www.mercedes-amg.com/en/footer/emissions-statement.html",
        "carChooser": {
            "177053": "*Fuel consumption combined: 8.4 - 7.3 l/100 km | CO2 emissions combined: 192 - 167 g/km*",
            "176052": "*Fuel consumption combined: 8.4 - 7.3 l/100 km | CO2 emissions combined: 192 - 167 g/km",
            "177051": "*Fuel consumption combined: 8.4 - 7.3 l/100 km | CO2 emissions combined: 192 - 167 g/km",
            "177054": "*Fuel consumption combined: 8.4 - 7.3 l/100 km | CO2 emissions combined: 192 - 167 g/km",
            "177151": "*Fuel consumption combined: 7.3 - 7.2 l/100 km | CO2 emissions combined: 167 - 164 g/km",
            "205464": "*Fuel consumption combined: 10.4 - 9.5 l/100 km | CO2 emissions combined: 236 - 217 g/km",
            "205486": "*Fuel consumption combined: 10.4 - 9.5 l/100 km | CO2 emissions combined: 236 - 217 g/km",
            "205487": "*Fuel consumption combined: 10.4 - 9.5 l/100 km | CO2 emissions combined: 236 - 217 g/km",
            "205364": "*Fuel consumption combined: 10.1 - 9.2 l/100 km | CO2 emissions combined: 230 - 211 g/km",
            "205386": "*Fuel consumption combined: 10.1 - 9.2 l/100 km | CO2 emissions combined: 230 - 211 g/km",
            "205387": "*Fuel consumption combined: 10.1 - 9.2 l/100 km | CO2 emissions combined: 230 - 211 g/km",
            "205064": "*Fuel consumption combined: 9.9 - 9.1 l/100 km | CO2 emissions combined: 227 - 208 g/km",
            "205086": "*Fuel consumption combined: 9.9 - 9.1 l/100 km | CO2 emissions combined: 227 - 208 g/km",
            "205087": "*Fuel consumption combined: 9.9 - 9.1 l/100 km | CO2 emissions combined: 227 - 208 g/km",
            "205264": "*Fuel consumption combined: 10 - 9.3 l/100 km | CO2 emissions combined: 229 - 214 g/km",
            "205287": "*Fuel consumption combined: 10 - 9.3 l/100 km | CO2 emissions combined: 229 - 214 g/km",
            "205286": "*Fuel consumption combined: 10 - 9.3 l/100 km | CO2 emissions combined: 229 - 214 g/km",
            "118351": "*Fuel consumption combined: 8.3 - 7.2 l/100 km | CO2 emissions combined: 189 - 164 g/km",
            "118353": "*Fuel consumption combined: 8.3 - 7.2 l/100 km | CO2 emissions combined: 189 - 164 g/km",
            "117352": "*Fuel consumption combined: 8.3 - 7.2 l/100 km | CO2 emissions combined: 189 - 164 g/km",
            "118354": "*Fuel consumption combined: 8.3 - 7.2 l/100 km | CO2 emissions combined: 189 - 164 g/km",
            "117952": "*Fuel consumption combined: 8.4 - 7.4 l/100 km | CO2 emissions combined: 191 - 168 g/km",
            "257361": "*Fuel consumption combined: 8.8 - 8.7 l/100 km | CO2 emissions combined: 202 - 199 g/km",
            "218392": "*Fuel consumption combined: 8.8 - 8.7 l/100 km | CO2 emissions combined: 202 - 199 g/km",
            "218374": "*Fuel consumption combined: 8.8 - 8.7 l/100 km | CO2 emissions combined: 202 - 199 g/km",
            "218376": "*Fuel consumption combined: 8.8 - 8.7 l/100 km | CO2 emissions combined: 202 - 199 g/km",
            "218375": "*Fuel consumption combined: 8.8 - 8.7 l/100 km | CO2 emissions combined: 202 - 199 g/km",
            "238461": "*Fuel consumption combined: 8.9 - 8.8 l/100 km | CO2 emissions combined: 204 - 201 g/km",
            "238361": "*Fuel consumption combined: 8.8 - 8.7 l/100 km | CO2 emissions combined: 202 - 199 g/km",
            "213064": "*Fuel consumption combined: 10.8 - 8.6 l/100 km | CO2 emissions combined: 245 - 197 g/km",
            "213061": "*Fuel consumption combined: 10.8 - 8.6 l/100 km | CO2 emissions combined: 245 - 197 g/km",
            "212092": "*Fuel consumption combined: 10.8 - 8.6 l/100 km | CO2 emissions combined: 245 - 197 g/km",
            "213088": "*Fuel consumption combined: 10.8 - 8.6 l/100 km | CO2 emissions combined: 245 - 197 g/km",
            "212074": "*Fuel consumption combined: 10.8 - 8.6 l/100 km | CO2 emissions combined: 245 - 197 g/km",
            "212076": "*Fuel consumption combined: 10.8 - 8.6 l/100 km | CO2 emissions combined: 245 - 197 g/km",
            "213089": "*Fuel consumption combined: 10.8 - 8.6 l/100 km | CO2 emissions combined: 245 - 197 g/km",
            "212075": "*Fuel consumption combined: 10.8 - 8.6 l/100 km | CO2 emissions combined: 245 - 197 g/km",
            "213264": "*Fuel consumption combined: 10.8 - 8.7 l/100 km | CO2 emissions combined: 246 - 200 g/km",
            "213261": "*Fuel consumption combined: 10.8 - 8.7 l/100 km | CO2 emissions combined: 246 - 200 g/km",
            "212292": "*Fuel consumption combined: 10.8 - 8.7 l/100 km | CO2 emissions combined: 246 - 200 g/km",
            "213288": "*Fuel consumption combined: 10.8 - 8.7 l/100 km | CO2 emissions combined: 246 - 200 g/km",
            "212276": "*Fuel consumption combined: 10.8 - 8.7 l/100 km | CO2 emissions combined: 246 - 200 g/km",
            "213289": "*Fuel consumption combined: 10.8 - 8.7 l/100 km | CO2 emissions combined: 246 - 200 g/km",
            "212275": "*Fuel consumption combined: 10.8 - 8.7 l/100 km | CO2 emissions combined: 246 - 200 g/km",
            "212274": "*Fuel consumption combined: 10.8 - 8.7 l/100 km | CO2 emissions combined: 246 - 200 g/km",
            "463272": "*Fuel consumption combined: 13.3 - 13.1 l/100 km | CO2 emissions combined: 304 - 299 g/km",
            "463277": "*Fuel consumption combined: 13.3 - 13.1 l/100 km | CO2 emissions combined: 304 - 299 g/km",
            "463274": "*Fuel consumption combined: 13.3 - 13.1 l/100 km | CO2 emissions combined: 304 - 299 g/km",
            "253364": "*Fuel consumption combined: 12.3 - 10.2 l/100 km | CO2 emissions combined: 280 - 232 g/km",
            "253388": "*Fuel consumption combined: 12.3 - 10.2 l/100 km | CO2 emissions combined: 280 - 232 g/km",
            "253389": "*Fuel consumption combined: 12.3 - 10.2 l/100 km | CO2 emissions combined: 280 - 232 g/km",
            "253964": "*Fuel consumption combined: 12.4 - 10.2 l/100 km | CO2 emissions combined: 283 - 234 g/km",
            "253988": "*Fuel consumption combined: 12.4 - 10.2 l/100 km | CO2 emissions combined: 283 - 234 g/km",
            "253989": "*Fuel consumption combined: 12.4 - 10.2 l/100 km | CO2 emissions combined: 283 - 234 g/km",
            "292364": "*Fuel consumption combined: 9.3 l/100 km | CO2 emissions combined: 212 g/km",
            "292374": "*Fuel consumption combined: 9.3 l/100 km | CO2 emissions combined: 212 g/km",
            "292375": "*Fuel consumption combined: 9.3 l/100 km | CO2 emissions combined: 212 g/km",
            "166064": "*Fuel consumption combined: 9.3 l/100 km | CO2 emissions combined: 212 g/km",
            "166074": "*Fuel consumption combined: 9.3 l/100 km | CO2 emissions combined: 212 g/km",
            "167161": "*Fuel consumption combined: 9.3 l/100 km | CO2 emissions combined: 212 g/km",
            "167188": "*Fuel consumption combined: 9.3 l/100 km | CO2 emissions combined: 212 g/km",
            "166075": "*Fuel consumption combined: 9.3 l/100 km | CO2 emissions combined: 212 g/km",
            "167189": "*Fuel consumption combined: 9.3 l/100 km | CO2 emissions combined: 212 g/km",
            "217478": "*Fuel consumption combined: 11.5 l/100 km | CO2 emissions combined: 263 g/km",
            "217488": "*Fuel consumption combined: 11.5 l/100 km | CO2 emissions combined: 263 g/km",
            "217477": "*Fuel consumption combined: 11.5 l/100 km | CO2 emissions combined: 263 g/km",
            "217487": "*Fuel consumption combined: 11.5 l/100 km | CO2 emissions combined: 263 g/km",
            "217479": "*Fuel consumption combined: 11.5 l/100 km | CO2 emissions combined: 263 g/km",
            "217378": "*Fuel consumption combined: 11.2 l/100 km | CO2 emissions combined: 254 g/km",
            "217388": "*Fuel consumption combined: 11.2 l/100 km | CO2 emissions combined: 254 g/km",
            "217387": "*Fuel consumption combined: 11.2 l/100 km | CO2 emissions combined: 254 g/km",
            "217377": "*Fuel consumption combined: 11.2 l/100 km | CO2 emissions combined: 254 g/km",
            "217379": "*Fuel consumption combined: 11.2 l/100 km | CO2 emissions combined: 254 g/km",
            "222077": "*Fuel consumption combined: 14.2 - 11.2 l/100 km | CO2 emissions combined: 325 - 255 g/km",
            "222188": "*Fuel consumption combined: 14.2 - 11.2 l/100 km | CO2 emissions combined: 325 - 255 g/km",
            "222187": "*Fuel consumption combined: 14.2 - 11.2 l/100 km | CO2 emissions combined: 325 - 255 g/km",
            "222179": "*Fuel consumption combined: 14.2 - 11.2 l/100 km | CO2 emissions combined: 325 - 255 g/km",
            "231474": "*Fuel consumption combined: 11.6 l/100 km | CO2 emissions combined: 264 g/km",
            "231479": "*Fuel consumption combined: 11.6 l/100 km | CO2 emissions combined: 264 g/km",
            "172475": "*Fuel consumption combined: 8.2 l/100 km | CO2 emissions combined: 186 g/km",
            "172466": "*Fuel consumption combined: 8.2 l/100 km | CO2 emissions combined: 186 g/km",
            "190380": "*Fuel consumption combined: 12.4 - 11.4 l/100 km | CO2 emissions combined: 284 - 261 g/km",
            "190377": "*Fuel consumption combined: 12.4 - 11.4 l/100 km | CO2 emissions combined: 284 - 261 g/km",
            "190379": "*Fuel consumption combined: 12.4 - 11.4 l/100 km | CO2 emissions combined: 284 - 261 g/km",
            "190378": "*Fuel consumption combined: 12.4 - 11.4 l/100 km | CO2 emissions combined: 284 - 261 g/km",
            "190480": "*Fuel consumption combined: 12.5 - 11.5 l/100 km | CO2 emissions combined: 284 - 262 g/km",
            "190477": "*Fuel consumption combined: 12.5 - 11.5 l/100 km | CO2 emissions combined: 284 - 262 g/km",
            "190478": "*Fuel consumption combined: 12.5 - 11.5 l/100 km | CO2 emissions combined: 284 - 262 g/km"
        },
        "modelStartPage": {
            "177051": "*Fuel consumption combined: 7.4 - 7.3 l/100 km | CO2 emissions combined: 169 - 167 g/km",
            "177053": "*Fuel consumption combined: 7.4 - 7.3 l/100 km | CO2 emissions combined: 169 - 167 g/km",
            "177054": "*Fuel consumption combined: 8.4 - 8.3 l/100 km | CO2 emissions combined: 192 - 189 g/km",
            "177151": "*Fuel consumption combined: 7.3 - 7.2 l/100 km | CO2 emissions combined: 167 - 164 g/km",
            "205464": "*Fuel consumption combined: 9.8 - 9.5 l/100 km | CO2 emissions combined: 223 - 217 g/km",
            "205486": "*Fuel consumption combined: 10.4 l/100 km | CO2 emissions combined: 236 g/km ",
            "205487": "*Fuel consumption combined: 10.4 l/100 km | CO2 emissions combined: 236 g/km ",
            "205364": "*Fuel consumption combined: 9.5 - 9.2 l/100 km | CO2 emissions combined: 217 - 211 g/km",
            "205386": "*Fuel consumption combined: 10.1 l/100 km | CO2 emissions combined: 230 g/km",
            "205387": "*Fuel consumption combined: 10.1 l/100 km | CO2 emissions combined: 230 g/km",
            "205064": "*Fuel consumption combined: 9.3 - 9.1 l/100 km | CO2 emissions combined: 213 - 208 g/km",
            "205086": "*Fuel consumption combined: 10 l/100 km | CO2 emissions combined: 229 g/km",
            "205087": "*Fuel consumption combined: 9.9 l/100 km | CO2 emissions combined: 227 g/km",
            "205264": "*Fuel consumption combined: 9.6 - 9.3 l/100 km | CO2 emissions combined: 219 - 214 g/km",
            "205287": "*Fuel consumption combined: 10 l/100 km | CO2 emissions combined: 228 g/km",
            "205286": "*Fuel consumption combined: 10 l/100 km | CO2 emissions combined: 229 g/km",
            "118351": "*Fuel consumption combined: 7.3 - 7.2 l/100 km | CO2 emissions combined: 167 - 164 g/km",
            "118353": "*Fuel consumption combined: 8.2 - 8.1 l/100 km | CO2 emissions combined: 188 - 185 g/km",
            "118354": "*Fuel consumption combined: 8.3 - 8.1 l/100 km | CO2 emissions combined: 189 - 186 g/km",
            "257361": "*Fuel consumption combined: 8.8 - 8.7 l/100 km | CO2 emissions combined: 202 - 199 g/km",
            "238461": "*Fuel consumption combined: 8.9 - 8.8 l/100 km | CO2 emissions combined: 204 - 201 g/km",
            "238361": "*Fuel consumption combined: 8.8 - 8.7 l/100 km | CO2 emissions combined: 202 - 199 g/km",
            "213061": "*Fuel consumption combined: 8.7 - 8.6 l/100 km | CO2 emissions combined: 200 - 197 g/km",
            "213088": "*Fuel consumption combined: 10.8 l/100 km | CO2 emissions combined: 245 g/km",
            "213089": "*Fuel consumption combined: 10.8 l/100 km | CO2 emissions combined: 245 g/km",
            "213261": "*Fuel consumption combined: 8.9 - 8.7 l/100 km | CO2 emissions combined: 203 - 200 g/km",
            "213288": "*Fuel consumption combined: 10.8 l/100 km | CO2 emissions combined: 246 g/km",
            "213289": "*Fuel consumption combined: 10.8 l/100 km | CO2 emissions combined: 246 g/km",
            "463272": "*Fuel consumption combined: 13.3 - 13.1 l/100 km | CO2 emissions combined: 304 - 299 g/km",
            "253364": "*Fuel consumption combined: 10.5 - 10.2 l/100 km | CO2 emissions combined: 240 - 232 g/km",
            "253388": "*Fuel consumption combined: 12.3 - 12.1 l/100 km | CO2 emissions combined: 280 - 275 g/km",
            "253389": "*Fuel consumption combined: 12.3 l/100 km | CO2 emissions combined: 280 g/km",
            "253964": "*Fuel consumption combined: 10.6 - 10.2 l/100 km | CO2 emissions combined: 242 - 234 g/km",
            "253988": "*Fuel consumption combined: 12.4 - 12.2 l/100 km | CO2 emissions combined: 283 - 278 g/km",
            "253989": "*Fuel consumption combined: 12.4 l/100 km | CO2 emissions combined: 283 g/km",
            "167161": "*Fuel consumption combined: 9,3 l/100 km | CO2 emissions combined: 212 g/km",
            "217488": "*Fuel consumption combined: 11.5 l/100 km | CO2 emissions combined: 263 g/km",
            "217379": "*Fuel consumption combined: 11.2 l/100 km | CO2 emissions combined: 254 g/km",
            "222077": "*Fuel consumption combined: 11.2 l/100 km | CO2 emissions combined: 255 g/km",
            "231474": "*Fuel consumption combined: 11.6 l/100 km | CO2 emissions combined: 264 g/km",
            "172466": "*Fuel consumption combined: 8.2 l/100 km | CO2 emissions combined: 186 g/km",
            "190380": "*Fuel consumption combined: 12.4 l/100 km | CO2 emissions combined: 284 g/km",
            "190377": "*Fuel consumption combined: 11.4 l/100 km | CO2 emissions combined: 261 g/km ",
            "190379": "*Fuel consumption combined: 12.4 l/100 km | CO2 emissions combined: 284 g/km",
            "190378": "*Fuel consumption combined: 11.5 l/100 km | CO2 emissions combined: 262 g/km",
            "190480": "*Fuel consumption combined: 12.5 l/100 km | CO2 emissions combined: 284 g/km",
            "190477": "*Fuel consumption combined: 11.5 l/100 km | CO2 emissions combined: 262 g/km",
            "190478": "*Fuel consumption combined: 11.5 l/100 km | CO2 emissions combined: 262 g/km"
        },
        "footerInfo": {
            "text1": "The figures are provided in accordance with the German regulation 'PKW-EnVKV' and apply to the German market only. Further information on official fuel consumption figures and the official specific CO₂ emissions of new passenger cars can be found in the EU guide ",
            "linkOutName1": "'Leitfaden über den Kraftstoffverbrauch, die CO2-Emissionen und den Stromverbrauch'",
            "linkOutUrl1": "https://www.dat.de/fileadmin/media/LeitfadenCO2/GuideCO2.pdf",
            "text2": ", which is available free of charge at all sales dealerships, from DAT Deutsche Automobil Treuhand GmbH and at ",
            "linkOutName2": "www.dat.de",
            "linkOutUrl2": "https://www.datgroup.com/",
            "text3": "."
        }
    }
}
