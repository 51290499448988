const CONFIG = {
  attributes: true,
  childList: false,
  subtree: false,
  attributeFilter: ['dir']
};
/**
 * @name DirectionService
 *
 * @description Class that informs about dir attribute changes on the given targetEl. One instance of this is globally registered and observes dir attribute changes on the html element.
 *
 * @example
 * import { DirectionService } from '@workbench/core';
 *
 * const myDirectionService = new DirectionService(document.documentElement);
 *
 * // to get the current direction
 * myDirectionService.dir; // "ltr"
 *
 * // to check if the current direction context is rtl
 * myDirectionService.isRtl; // false
 *
 * // to check if the current direction context is ltr
 * myDirectionService.isLtr; // true
 *
 * // to listen for direction changes
 * document.addEventListener('wbdirchange', (event) => {
 *  const currentDir = event.detail.dir;
 * });
 *
 * // to set the direction
 * myDirectionService.setDir('rtl');
 */
class DirectionService {
  constructor(targetEl) {
    this.targetEl = targetEl;
    this.createObserver();
    this.observe();
  }
  setDir(dir) {
    this.targetEl.setAttribute('dir', dir);
  }
  get dir() {
    return this.targetEl.getAttribute('dir');
  }
  get isRtl() {
    return this.dir === 'rtl';
  }
  get isLtr() {
    return !this.isRtl;
  }
  dispatchDirChangeEvent() {
    const event = new CustomEvent('wbdirchange', {
      detail: {
        dir: this.dir
      }
    });
    document.dispatchEvent(event);
  }
  createObserver() {
    this.observer = new MutationObserver(() => this.dispatchDirChangeEvent());
  }
  observe() {
    this.observer.observe(this.targetEl, CONFIG);
  }
}

// note: the below css class has to be publicly available
const SCROLL_LOCK_CLASS = 'wb-scroll-lock'; // eslint-disable-line workbench/tag-name-transform
/**
 * @name ScrollLockService
 *
 * @description Locks/unlocks scrolling by adding/removing the `.wb-scroll-lock` class to the documentElement. Used for preventing scrolling of the background behind an open modal
 *
 * @example
 * import { ScrollLockService } from '@workbench/core';
 *
 * const scrollLockService = new ScrollLockService();
 *
 * // lock scrolling
 * scrollLockService.lock();
 *
 * // unlock scrolling
 * scrollLockService.unlock();
 */
class ScrollLockService {
  constructor(targetEl = document.documentElement) {
    this.targetEl = targetEl;
    this.scrollTopBeforeLock = 0;
  }
  lock() {
    if (this.isLocked) {
      return;
    }
    this.scrollTopBeforeLock = this.targetEl.scrollTop;
    this.targetEl.style.top = `-${this.scrollTopBeforeLock}px`;
    this.targetEl.classList.add(SCROLL_LOCK_CLASS);
    this.isLocked = true;
  }
  unlock() {
    if (!this.isLocked) {
      return;
    }
    this.targetEl.classList.remove(SCROLL_LOCK_CLASS);
    this.targetEl.style.top = '';
    this.targetEl.scrollTop = this.scrollTopBeforeLock;
    this.isLocked = false;
  }
}

/**
 * @name ScrollService
 *
 * @description Let's you register callbacks for a shared scroll event listener to improve performance
 *
 * @example
 * import { ScrollService } from '@workbench/core';
 *
 * const scrollService = new ScrollService();
 *
 * const scrollFn = (e: Event) => console.log('USER SCROLLED', e);
 *
 * // register listener
 * scrollService.register(scrollFn);
 *
 * // unregister listener
 * scrollService.unregister(scrollFn);
 */
class ScrollService {
  constructor() {
    this.registeredCallbacks = [];
    this.onScroll = event => {
      this.registeredCallbacks.filter(Boolean).forEach(callback => callback(event));
    };
    window.addEventListener('scroll', this.onScroll);
  }
  register(callback) {
    this.registeredCallbacks.push(callback);
  }
  unregister(callback) {
    this.registeredCallbacks = this.registeredCallbacks.filter(c => c !== callback);
  }
}
export { DirectionService as D, ScrollService as S, ScrollLockService as a, SCROLL_LOCK_CLASS as b };

