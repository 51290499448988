// ng
import { Component, Input, OnInit } from '@angular/core';

// model
import { Image } from '@shared/global-models/image.model';

@Component({
    selector: 'zk-single-image-stage',
    templateUrl: './single-image-stage.component.html',
    styleUrls: ['./single-image-stage.component.scss']
})
export class SingleImageStageComponent implements OnInit {
	/**
	 * Required: Image data.
	 */
    @Input()
    image: Image;

	/**
	 * Required: Define headline text.
	 */
    @Input()
    headline: string = '';

	/**
	 * Optional: Define headline color as black for MQ3+. Used to display text better on bright images.
	 * Default is false. Headline color is white for MQ3+ if image data exists.
	 */
    @Input()
    headlineColorBlack: boolean = false;

    constructor() {}

    ngOnInit(): void {
    }
}
