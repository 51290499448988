<a
   [href]="url"
   class="link"
   target="_blank"
   rel="noopener noreferrer"
   zkTrackClick="shop_teaser_pdp"
   zkTrackClickValue="{{url}}">
    <wb-card class="wb-card hydrated" tabindex="-1" interactive>
        <wb-card-layout-nba class="wb-card-layout-nba hydrated">
            <wb-icon name="bds/shopping-cart/24" style="--size: 40px;" class="wb-icon hydrated"></wb-icon>
            <wb-text size="l" strong class="wb-text hydrated" tag="div"
                     [zkTranslate]="'SHOP_LINK_HEADLINE_' + (isAccessories ? 'ACC' : 'COLL')">
            </wb-text>
        </wb-card-layout-nba>
    </wb-card>
</a>
