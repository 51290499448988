<wb-card class="wb-card hydrated"
         tabindex="-1"
         interactive
         zkTrackClick="dealer_locator_click"
         zkTrackClickPosition="pdp"
         (click)="openDealerLocatorModal()">

    <wb-card-layout-nba class="wb-card-layout-nba hydrated">
        <wb-icon name="bds/location-dealer/24" style="--size: 40px;" class="wb-icon hydrated"></wb-icon>
        <wb-text size="l" strong class="wb-text hydrated" tag="div"
                 zkTranslate="DEALER_LOCATOR.BUTTON_TEXT">
        </wb-text>
    </wb-card-layout-nba>

</wb-card>
