// Ng
import { AfterContentInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Subscription } from "rxjs";
import { HttpResponse } from "@angular/common/http";
import { Router } from "@angular/router";
// Services
import { SelectedCarService } from "@shared/shared-services/selected-car/selected-car.service";
import { TranslationService } from "@shared/shared-services/translate/translation.service";
import { CarChooserService } from "@shared/components/car-chooser/car-chooser.service";
import { CompatibilityCheckService } from "@shared/shared-services/compatibility/compatibility.service";
import { ShoppingCartService } from "@shared/shared-services/shopping-cart/shopping-cart.service";
import { ResetWarningModalService } from "@shared/modals/reset-warning-modal/reset-warning-modal.service";
import { PopupModalService } from "@shared/modals/popup-modal/popup-modal.service";
import { LocalStorageService } from "@shared/shared-services/storage/local-storage.service";
import { CurrentRouteService } from "@shared/shared-services/current-route/current-route.service";
// Models
import { NewCarDataSet, VIN, VinLockProgressStepsModel } from "../models";
import {
    CompatibilityCheckProgressStepsModel
} from "@shared/modals/compatibility-feedback-modal/models/compatibility-check-progress-steps.model";
import { ShoppingCartData } from "@shared/shared-services/shopping-cart/model/shopping-cart-data.model";
import { StorageEnum } from "@shared/shared-services/storage/storage.enum";
import { CompatibilityCheck } from "@shared/modals/compatibility-feedback-modal/models/compatibility-check.model";

@Component({
    selector: 'zk-vin-lock',
    templateUrl: './vin-lock.component.html',
    styleUrls: ['./vin-lock.component.scss']
})
export class VinLockComponent implements OnInit, AfterContentInit {
    @ViewChild('inputEl', {static: false}) inputEl: ElementRef;

    @Input()
    set newCar(newCar: NewCarDataSet) {
        this._newCar = newCar;
        this.errorMessage = null;
        this.focusInput();
    }

    private _newCar: NewCarDataSet = null;

    placeholder = this.translationService.translate('VINLOCK.PLACEHOLDER');
    showLoadingSpinner = false;
    errorMessage: string | null = null;
    loadingSpinnerText = '';
    progressFeedbackText = '';
    showDescription = false;
    hasNotFittingArticles = false;
    disableLogin = true;
    maxLength: number = null;

    constructor(
        private router: Router,
        private _carChooserService: CarChooserService,
        private translationService: TranslationService,
        private _selectedCarService: SelectedCarService,
        private compatibilityCheckService: CompatibilityCheckService,
        private _modalService: PopupModalService,
        private _localStorageService: LocalStorageService,
        private _shoppingCartService: ShoppingCartService,
        private _resetWarningService: ResetWarningModalService,
        private _currentRouteService: CurrentRouteService
    ) {
    }

    ngOnInit(): void {
        // vin description image is only for certain markets needed
        switch (this.translationService.currentLang) {
            case 'en-GB':
                this.showDescription = false;
                this.maxLength = null;
                break;
            case 'de-DE':
            case 'de-MB':
            case 'en-MB':
            case 'de-CH':
            case 'fr-CH':
            case 'it-CH':
                this.showDescription = true;
                this.maxLength = 17; // VINs only
                break;
        }
    }

    ngAfterContentInit(): void {
        this.focusInput();
    }

    onLockVin(vin: string) {
        if (this.disableLogin) return;

        const hasShoppingCartItems = this._shoppingCartService.currentShoppingCart && this._shoppingCartService.currentShoppingCart.items.length > 0;

        if (this._newCar.vehicleTypeChanged && hasShoppingCartItems) {
            this._modalService.open('reset-warning-modal');

            const sub: Subscription = this._resetWarningService.actionAllowed.subscribe((action: boolean) => {
                sub.unsubscribe();

                if (action) {
                    this._selectedCarService.resetLoggedCarData();
                    this._shoppingCartService.clearShoppingCart();

                    this.handleVin(vin);
                }
            })
        } else {
            this.handleVin(vin);
        }
    }

    private handleVin(vin: string) {
        this.showLoadingSpinner = true;
        this.errorMessage = null;

        this._selectedCarService.clearVin();
        this._carChooserService.setActiveClassTree(this._newCar.carChooserVehicleType);
        this._selectedCarService.vehicleType = this._newCar.carChooserVehicleType

        if (this.compatibilityCheckService.currentArticles && !this._newCar.vehicleTypeChanged) {
            this.checkCompatibility(vin);
        } else {
            this.requestVinCheck(vin);
        }
    }

    private requestVinCheck(inputVin: string) {
        const sub: Subscription = this._carChooserService.lockVehicleByVin(inputVin, true).subscribe(
            (response: HttpResponse<VinLockProgressStepsModel>) => {
                if (response.status === 202) {
                    // give feedback to the user about the current step (because this call takes some time)
                    this.progressFeedbackText = this.translationService.translate('LOADINGSPINNER.PROGRESS.' + response.body.name.toUpperCase());
                    this.loadingSpinnerText = response.body.step + '/' + response.body.totalSteps;

                    // trigger next step
                    if (sub) sub.unsubscribe();

                    this.requestVinCheck(inputVin);
                } else {
                    // Finished: If it is not 202 we expect it to be 200
                    if (sub) sub.unsubscribe()

                    this.showLoadingSpinner = false;

                    this._selectedCarService.VIN = inputVin;

                    this._selectedCarService.selectCarByVINResponse(response.body, this._newCar.isAmg);

                    // reload current page with newly logged in car - if it was successful
                    if (!this._selectedCarService.carLine) {
                        this.errorMessage = this.translationService.translate('VINLOCK.NOCARFOUND');
                    } else {

                        // If a new car belongs to a different vehicle group, we navigate to vehicle model start page
                        if (this._newCar.vehicleTypeChanged) {
                            const url = `/${this.translationService.currentLang}${this._newCar.carChooserVehicleType.urlPart}/${this._selectedCarService.carLine.urlName}`;
                            window.location.replace(url);
                        } else {

                            // Don't redirect if in Cart
                            if (!this.router.url.includes('/wishlist')) {
                                this.router.navigate([this._currentRouteService.updateCurrentRoute(this._selectedCarService.carLine)], {
                                    queryParamsHandling: 'preserve'
                                });
                            }

                            this._carChooserService.toggleLayer(false);
                        }
                    }
                }
            },
            (error) => {
                if (sub) sub.unsubscribe();

                this.showLoadingSpinner = false;

                switch (error.status) {
                    case 400: this.errorMessage = this.translationService.translate('VINLOCK.NOCARFOUND'); break;
                    case 404: this.errorMessage = this.translationService.translate('VINLOCK.NOCARFOUND'); break;
                    case 500: this.errorMessage = error.error.detail; break;
                    default: this.errorMessage = this.translationService.translate('ERRORMESSAGE.SERVICEUNAVAILABLE');
                }

                console.log('Error getting VIN: ', error);
            }
        );
    }


    /**
     * * Compatibility Check Logic:
     * * Checks if current Article or Articles in Cart fits to given VIN, and adjust them accordingly.
     * * If any not fitting articles, show modal with them.
     * * Log a car by VIN in subsequent calls.
     */

    /**
     * Check the compatibility with the given VIN number and current detail page article
     * @param vin
     */
    checkCompatibility(vin: string) {

        // listen to feedback of the loading/checking process
        const sub = this.compatibilityCheckService.compatibilityStepFeedback.subscribe(
            (response: CompatibilityCheckProgressStepsModel) => {
                // check for feedback step
                if (response.step !== undefined) {
                    this.progressFeedbackText = this.translationService.translate('LOADINGSPINNER.PROGRESS.' + response.name.toUpperCase());
                    this.loadingSpinnerText = response.step + '/' + response.totalSteps;
                }
                // check for result
                if (response.articles !== undefined && response.vin !== undefined) {
                    sub.unsubscribe();

                    this.showLoadingSpinner = false;
                    this._selectedCarService.VIN = vin;

                    // Update current Article and Articles in shopping cart with CC response
                    // will add 'fit' property with boolean value if Article fit or not
                    this.updateCurrentArticle(response);
                    this.compatibilityCheckService.updateShoppingCart(response.articles);

                    // If article not fit, show them
                    if (this.articlesNotFit(response)) {
                        this.hasNotFittingArticles = true;
                    }

                    this.getVehicleByVIN(vin);
                }
                // case: Entered licence plate/VIN could not be identified
                if (response.errorMessage) {
                    sub.unsubscribe();

                    this.errorMessage = response.errorMessage
                    this.showLoadingSpinner = false;
                }
            }
        );

        // trigger request
        this.compatibilityCheckService.checkCompatibility(vin);
    }

    /**
     * Uses the VIN to identify the vehicle type and log it and updates the VIN in the local storage.
     */
    private getVehicleByVIN(vin: string) {
        this.showLoadingSpinner = true;
        const sub = this.compatibilityCheckService.getCarByVin(vin).subscribe(
            (response: VIN) => {
                sub.unsubscribe();
                this.showLoadingSpinner = false;

                this._selectedCarService.vinVehicle = response;
                this._selectedCarService.selectCarByVINResponse(response);

                this._carChooserService.toggleLayer(false);

                if (this.hasNotFittingArticles) {
                    this._modalService.open('compatibility-feedback')
                }
            },
            (error) => {
                sub.unsubscribe();
                this.showLoadingSpinner = false;

                switch (error.status) {
                    case 400: this.errorMessage = this.translationService.translate('COMPATIBILITYCHECK.MODAL.VEHICLENOTIDENTIFIABLE'); break;
                    case 500: this.errorMessage = error.error.detail; break;
                    default: this.errorMessage = this.translationService.translate('ERRORMESSAGE.SERVICEUNAVAILABLE');
                }

                console.log('Fail to login a car by VIN: ', error);
            }
        );
    }

    // update current PDP article(s) that haven't been added to the cart yet, with the result of the check
    private updateCurrentArticle(compatibilityCheckResponse: CompatibilityCheck) {
        if (this.compatibilityCheckService.currentArticles && this.compatibilityCheckService.currentArticles.length > 0) {
            for (const currentArticle of this.compatibilityCheckService.currentArticles) {
                const tested = compatibilityCheckResponse.articles.find(testArticle => testArticle.articleId === currentArticle.articleId);
                if (tested) {
                    currentArticle.fit = tested.fit;
                }
            }
        }
    }

    // If at least one article not fit, show CC modal with articles
    private articlesNotFit(compatibilityCheckResponse: CompatibilityCheck): boolean {
        const shoppingCart: ShoppingCartData = this._localStorageService.getItem(StorageEnum.SHOPPING_CART);

        const cartArticleNotFit = shoppingCart?.items.some(article => !article.fit) ?? false;
        const articleNotFit = compatibilityCheckResponse.articles.some(article => !article.fit);

        return cartArticleNotFit || articleNotFit;
    }

    focusInput() {
        setTimeout(() => {
            this.inputEl.nativeElement.getInputReference().then((input: { focus: () => any; }) => input.focus());
        })
    }

    resetInputField() {
        this.inputEl.nativeElement.getInputReference().then((input: { value: string; }) => input.value = null);
        this.disableLogin = true
        this.errorMessage = null;
    }

    sanitizeInput(event: any) {
        let inputValue = event.target.value.trim();

        // Remove any disallowed characters
        inputValue = inputValue.replace(/[^a-zA-Z0-9 ]/g, '');

        // Update the input value with the sanitized value
        event.target.value = inputValue;

        // Disable login button if inputValue is empty or not 17 chars length (if maxLength is defined)
        this.disableLogin = !inputValue || (this.maxLength !== null && inputValue.length !== this.maxLength);
    }
}
