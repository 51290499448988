// ng
import {
    AfterViewInit, ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

// model
import { MODE } from '@shared/global-models/mode.enum';
import { MarketModel } from '../../global-models/market.model';
import { ShoppingCartData } from '@shared/shared-services/shopping-cart/model/shopping-cart-data.model';
import { ProductGroup } from "@shared/global-models/productGroup.model";
import { VehicleType } from "@shared/components/car-chooser/models";
import { environment } from '../../../../environments/environment';

// services
import { SelectedCarService } from '@shared/shared-services/selected-car/selected-car.service';
import { ShoppingCartService } from '@shared/shared-services/shopping-cart/shopping-cart.service';
import { AppService } from '../../../app.service';
import { ResetWarningModalService } from '@shared/modals/reset-warning-modal/reset-warning-modal.service';
import { ProductGroupsService } from "@shared/shared-services/product-groups/product-groups.service";
import { MediaQuery } from '@workbench/core';
import { WbMediaQueryService } from "@shared/shared-services/media-query/wb-media-query.service";
import { ScrollLockService } from '@workbench/core';
import { SearchService } from "@shared/header/header-base/search.service";
import { PopupModalService } from "@shared/modals/popup-modal/popup-modal.service";
import { GoogleAnalyticsService } from "@shared/shared-services/google-analytics/google-analytics.service";
import { CarChooserService } from "@shared/components/car-chooser/car-chooser.service";
import { TranslationService } from "@shared/shared-services/translate/translation.service";

@Component({
    selector: 'zk-header-base',
    templateUrl: './header-base.component.html',
    styleUrls: ['./header-base.component.scss']
})
export class HeaderBaseComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('header') headerEl!: ElementRef;
    @ViewChild('navigation') navigationEl!: ElementRef;
    scrollLockService = new ScrollLockService();

    isAccessoriesMode = true;
    isSplashPage: boolean = false;
    isMenuActive: boolean = true;
    isMobile: boolean;
    isTablet: boolean;

    marketData: MarketModel;
    groups: ProductGroup[];
    activeSubgroup: ProductGroup | null;
    vehicleTypes: VehicleType[];
    cartItemsCount = 0;
    activeFlyOut: string | null;
    baseUrl: string;
    environment = environment;
    searchSlot = 'desktop-meta';
    brandsLocale: string;

    // subscriptions
    private _cartChangedSubscription: Subscription;
    private _userJourneySubscription: Subscription;
    private _marketSubscription: Subscription;
    private _shoppingCartSubscription: Subscription;
    private _routerSubscription: Subscription;
    private _groupsSub: Subscription;
    private _mediaQuerySub: Subscription;
    private _vehicleDataSub: Subscription;

    get loggedCar() {
        return this._selectedCarService.carClass;
    }

    get isAmg(): boolean {
        return this._selectedCarService.vehicleType?.vehicleTypeId === 'amg';
    }

    constructor(
        private _selectedCarService: SelectedCarService,
        private _router: Router,
        private _shoppingCartService: ShoppingCartService,
        private _resetWarningService: ResetWarningModalService,
        private _appService: AppService,
        private _productGroupService: ProductGroupsService,
        private _changeDetectorRef: ChangeDetectorRef,
        private _mediaQueryService: WbMediaQueryService,
        private _modalService: PopupModalService,
        private _searchService: SearchService,
        private _gaService: GoogleAnalyticsService,
        private _carChooserService: CarChooserService,
        private _translationService: TranslationService
    ) {
    }

    ngOnInit() {
        this.isAccessoriesMode = this._appService.appMode === MODE.ACCESSORIES;
        this.marketData = this._appService.currentMarket;
        this.isSplashPage = !this._appService.appMode;

        this.groups = this._productGroupService.productGroups;
        this.activeSubgroup = this.groups[0];
        this.brandsLocale = this._translationService.translate('HEADER.OUR_BRANDS');

        if (this._selectedCarService.vehicleTypes && this.isAccessoriesMode && !this.isSplashPage) {
            this.vehicleTypes = this._selectedCarService.vehicleTypes;
            this.setActiveType();
        }

        this.setUrlBase();
        this.setupSubscriptions();
    }

    ngOnDestroy() {
        if (this._cartChangedSubscription) {
            this._cartChangedSubscription.unsubscribe();
        }
        if (this._userJourneySubscription) {
            this._userJourneySubscription.unsubscribe();
        }
        if (this._marketSubscription) {
            this._marketSubscription.unsubscribe();
        }
        if (this._shoppingCartSubscription) {
            this._shoppingCartSubscription.unsubscribe();
        }
        if (this._mediaQuerySub) {
            this._mediaQuerySub.unsubscribe();
        }
        if (this._vehicleDataSub) {
            this._vehicleDataSub.unsubscribe();
        }
    }

    ngAfterViewInit(): void {
        this.initHeaderListeners();
    }

    /**
     * Opens custom fly outs
     * @param type
     */
    openFlyOut(type: string) {
        const flyOuts = {
            brands: 'brands',
            categories: 'categories',
            carChooser: 'carChooser'
        };

        this.activeFlyOut = flyOuts[type];
    }

    openSearch = () =>  {
        this.closeFlyOutMenu(); // close eventual open tablet menu flyout first
        this._searchService.openSearch.next();
    }

    selectSubgroup = (group: ProductGroup) => this.activeSubgroup = group;

    switchMode(mode: string): void {
        if ((mode === 'acc' && !this.isAccessoriesMode) || (mode === 'coll' && this.isAccessoriesMode)) {
            this._gaService.trackModeSwitch("header");
            this._appService.switchMode();
        }
    }

    setVehicleType(vehicleType: VehicleType) {
        if (vehicleType.isActive) {
            return;
        }

        // ZKDEV-1622
        const hasShoppingCartItems =
            this._shoppingCartService.currentShoppingCart && this._shoppingCartService.currentShoppingCart.items.length > 0;

        if (hasShoppingCartItems || this.loggedCar) {
            this._modalService.open('reset-warning-modal');

            const sub: Subscription = this._resetWarningService.actionAllowed.subscribe((action: boolean) => {
                sub.unsubscribe();

                if (action) {
                    this._shoppingCartService.clearShoppingCart();
                    this._selectedCarService.resetLoggedCarData();

                    this.switchVehicleType(vehicleType);
                }
            });
        } else {
            this.switchVehicleType(vehicleType);
        }
    }

    closeFlyOutMenu() {
        const flyIns = this.headerEl.nativeElement.querySelectorAll('wb-header-desktop-fly-in, wb-header-mobile-fly-in');
        const flyInContainer = this.headerEl.nativeElement.querySelector('wb-header-fly-in-container');

        flyIns.forEach((flyIn: any) => flyIn.open = false);
        flyInContainer.open = false;
    }

    openDealerLocatorModal() {
        this._modalService.open('dealer-locator-modal');
    }

    /**
     *   PRIVATE
     */

    private setupSubscriptions() {
        this._cartChangedSubscription = this._shoppingCartService.shoppingCartChanged.subscribe((response) =>
            this.getCartItemsCountForMenuBadge(response)
        );

        this._groupsSub = this._productGroupService.groupsChangedInfo.subscribe(() => {
            this.groups = this._productGroupService.productGroups;
            this.activeSubgroup = this.groups[0];

            this.isMenuActive = false;
            this._changeDetectorRef.detectChanges();
            this.isMenuActive = true;

            setTimeout(() => this.initHeaderListeners());
        });

        this.getCartItemsCountForMenuBadge(this._shoppingCartService.currentShoppingCart);

        this._routerSubscription = this._router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.isAccessoriesMode = this._appService.appMode === MODE.ACCESSORIES;
                this.isSplashPage = !this._appService.appMode;
                this.activeFlyOut = null;

                this.scrollLockService?.isLocked ? this.scrollLockService.unlock() : null;
            }
        });

        this._vehicleDataSub = this._carChooserService.vehicleDataReady.subscribe(()=> {
                this.vehicleTypes = this._selectedCarService.vehicleTypes;
                this.setActiveType();
            },
            (error) => {
                this._vehicleDataSub.unsubscribe()
                console.log('Error requesting vehicle types:', error);
            }
        )

        this._mediaQuerySub = this._mediaQueryService.mediaQuery.subscribe((mq: MediaQuery) => {
            this.isMobile = mq === 'mq1' || mq === 'mq2';
            this.isTablet = mq === 'mq3' || mq === 'mq4' || mq === 'mq5';

            if (mq === 'mq5') {
                this.searchSlot = 'desktop-meta';
            }
            if (mq === 'mq3' || mq === 'mq4') {
                this.searchSlot = 'tablet-meta-end';
            }
        });
    }

    private getCartItemsCountForMenuBadge(cart: ShoppingCartData) {
        this.cartItemsCount = 0;
        if (cart && cart.items) {
            for (const singleShoppingCartItem of cart.items) {
                this.cartItemsCount += singleShoppingCartItem.quantity;
            }
        }
    }

    private setUrlBase() {
        if (!this._selectedCarService.carClass || !this._selectedCarService.carLine || !this.isAccessoriesMode) {
            this.baseUrl = '/products/';
        } else {
            const selectedCar: string = this._selectedCarService.carLine.urlName;
            this.baseUrl = '/' + selectedCar + '/';
        }
    }


    private initHeaderListeners() {

        /** wb-header-navigation  */
        const items = this.navigationEl?.nativeElement.querySelectorAll('wb-header-navigation-item');

        if (items) {
            items.forEach((item) => {
                item.addEventListener('click', () => {
                    const previouslyActive = this.navigationEl.nativeElement.querySelector('wb-header-navigation-item[active]');

                    if (previouslyActive) {
                        previouslyActive.active = false;
                    }

                    item.active = true;
                });
            });
        }

        /** wb-header-fly-ins  */
        const flyInToggles = this.headerEl.nativeElement.querySelectorAll('[toggles-fly-in]');
        const flyIns = this.headerEl.nativeElement.querySelectorAll('wb-header-desktop-fly-in, wb-header-mobile-fly-in');
        const flyInContainer = this.headerEl.nativeElement.querySelector('wb-header-fly-in-container');

        const isLastOpen = () => Array.from(flyIns).filter((flyIn: any) => flyIn.open).length === 1;
        const unsetActiveToggles = () => flyInToggles.forEach((toggle: any) => toggle.removeAttribute('active'));


        // handle wbclose events emitted when a close button or the ESC key was clicked
        document.addEventListener('wbclose', (e: any) => {
            const targetTagName = e.target.tagName.toLowerCase();
            const headerElements = ['wb-header-desktop-fly-in', 'wb-header-mobile-fly-in', 'wb-header-fly-in-container'];
            if (headerElements.includes(targetTagName)) {
                flyIns.forEach((flyIn) => (flyIn.open = false));
                flyInContainer.open = false;
                unsetActiveToggles();

                this.scrollLockService?.isLocked ? this.scrollLockService.unlock() : null;
            }
        });

        // handle wbback events emitted when a close button or the ESC key was clicked
        flyIns.forEach((flyIn: any) => {
            flyIn.addEventListener('wbback', () => {
                if (isLastOpen()) {
                    flyInContainer.open = false;
                }
                flyIn.open = false;
                unsetActiveToggles();
            });
        });


        flyInToggles.forEach((toggle: any) => {
            toggle.addEventListener('click', () => {
                const flyInIdentifier = toggle.getAttribute('toggles-fly-in');
                const flyInsToToggle = Array.from(flyIns).filter((flyIn: any) => flyIn.classList.contains(flyInIdentifier));

                flyInsToToggle.forEach((flyInToToggle) => {
                    // @ts-ignore
                    const newOpenState = !flyInToToggle.open;

                    if (newOpenState) {
                        flyInContainer.open = true; // make sure fly-in container is also open
                    }

                    if (isLastOpen() && !newOpenState) {
                        flyInContainer.open = false; // close the container if the first level closes
                    }

                    // @ts-ignore
                    flyInToToggle.open = newOpenState;
                });

                toggle.toggleAttribute('active');

                (this.isTablet && this.scrollLockService) ? this.scrollLockService.lock() : null;
            });
        });
    }

    private setActiveType() {
        const activeId: string = this._selectedCarService.vehicleType.vehicleTypeId;
        this.vehicleTypes.forEach((x: VehicleType) => (x.isActive = x.vehicleTypeId === activeId));
    }

    private switchVehicleType(vehicleType: VehicleType): void {
        this._selectedCarService.vehicleType = vehicleType;
        this._carChooserService.setActiveClassTree(vehicleType);
        // update view
        this.setActiveType();
        // route to individual start page of vehicle type / sub brand
        this._router.navigate([vehicleType.urlPart], { queryParamsHandling: 'preserve' });
    }


    @HostListener('document:click', ['$event'])
    private listenToDocumentClick(event) {
        if (!this.headerEl.nativeElement.contains(event.target)) {
            this.activeFlyOut = null;
        }
    }
}
