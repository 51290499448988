<section class="file-not-found wb-grid-container">
    <picture *ngIf="image">
        <source *ngIf="image.image768" media="(max-width:479.9px)" [srcset]="image.image768">
        <source *ngIf="image.image1440" media="(max-width:1439.9px)" [srcset]="image.image1440">
        <img class="ui-picture image-wide_image" alt="{{image.imageDescription}}" [src]="image.image">
    </picture>

    <wb-heading tag="h1" size="l" class="file-not-found_headline"
                zkTranslate="ERROR.404.HEADLINE">
    </wb-heading>
    <wb-text tag="p" size="l" class="file-not-found_text"
             zkTranslate="ERROR.404.TEXT">
    </wb-text>

    <wb-button variant="secondary" theme="dark"
               [routerLink]="'/'"
               zkTranslate="GENERAL.TOSTARTPAGE">
    </wb-button>
</section>
