// Ng
import { Injectable } from '@angular/core';
import { forkJoin, Observable, of, Subject } from "rxjs";
import { catchError, switchMap, tap } from "rxjs/operators";
import { HttpClient, HttpParams } from "@angular/common/http";
// Services
import { TranslationService } from "@shared/shared-services/translate/translation.service";
import { SelectedCarService } from "@shared/shared-services/selected-car/selected-car.service";
// Models
import { CarClass, VehicleType, VinLockProgressStepsModel, VIN } from "./models";


interface vehicleTypesClasses {
    car: CarClass[]
    transporter: CarClass[]
    amg: CarClass[]
}

@Injectable({
    providedIn: 'root'
})
export class CarChooserService {
    vehicleDataReady= new Subject<boolean>();
    toggleCarChooserLayer= new Subject<{open: boolean, isVin: boolean}>();

    /**
     * Holds All ClassTrees for all available VehicleTypes
     */
    private _carClasses: vehicleTypesClasses = {
        car: null,
        transporter: null,
        amg: null
    }

    constructor(
        private httpClient: HttpClient,
        private _translationService: TranslationService,
        private _selectedCarService: SelectedCarService) {
    }

    /**
     * Returns proper ClassTree for chosen VehicleType
     * Returns object copy, so it won't mutate origin
     */
    getCarClasses(type: VehicleType) {
        return JSON.parse(JSON.stringify(this._carClasses[type.vehicleTypeId]));
    }

    /**
     * Get all available VehicleTypes and ClassTree for every of it
     */
    getVehicleTypesAndClasses(): Observable<any> {
        const currentLanguage: string = this._translationService.currentLang;

        return this.httpClient.get<VehicleType[]>(`api/basic/vehicle-types/${this._translationService.currentLang}`).pipe(
            switchMap((vehicleTypes: VehicleType[]) => {
                if (!vehicleTypes) return;

                // MBCS-949 - map to valid name used in API calls
                const passengerCars = vehicleTypes.find((type: VehicleType) => type.vehicleTypeId === 'passengercars');
                if (passengerCars) passengerCars.vehicleTypeId = 'car';

                this.addLocalisationKeys(vehicleTypes);

                this._selectedCarService.vehicleTypes = vehicleTypes;

                // Array to hold all the observables
                const observables = vehicleTypes.map((type: VehicleType) => {
                    return this.httpClient.get<CarClass[]>(`api/vehicle/tree/${currentLanguage}/${type.vehicleTypeId}`);
                });

                // Use forkJoin to wait for all observables to complete
                return forkJoin(observables).pipe(
                    tap((responses: CarClass[][]) => {
                        responses.forEach((response: CarClass[], index: number) => {
                            const type = vehicleTypes[index];
                            this._carClasses[type.vehicleTypeId] = response;
                        });

                        this.setActiveClassTree();

                        this.vehicleDataReady.next();
                    }),
                    catchError(error => {
                        console.error('An error occurred by fetching CarClasses:', error);
                        return of(null);
                    })
                );
            }),
            catchError(error => {
                console.error('An error occurred by fetching VehicleTypes:', error);
                return of(null);
            })
        );
    }

    lockVehicleByVin(vin: string, showProgress: Boolean) {
        const vehicleType = this._selectedCarService.vehicleType.vehicleTypeId;

        if (showProgress) {
            const param: HttpParams = new HttpParams().set('progress', 'true');

            return this.httpClient.get<VinLockProgressStepsModel>(`api/vehicle/data/${vin}/${this._translationService.currentLang}/${vehicleType}`, {
                observe: 'response',
                params: param
            });
        }

        return this.httpClient.get<VIN>(`api/vehicle/data/${vin}/${this._translationService.currentLang}/${vehicleType}`, { observe: 'response' });
    }

    setActiveClassTree(vehicleType?: VehicleType) {
        const vt = vehicleType ? vehicleType : this._selectedCarService.vehicleType;
        this._selectedCarService.carClassesTree = this.getCarClasses(vt);
    }

    addLocalisationKeys(vehicleTypes: VehicleType[]) {
        vehicleTypes.map((type: VehicleType) => {
            if(type.vehicleTypeId === 'car') {
                type.localisationKey = 'BRANDS.CAR';
            }

            if(type.vehicleTypeId === 'transporter') {
                type.localisationKey = 'BRANDS.VAN';
            }

            if(type.vehicleTypeId === 'amg') {
                type.localisationKey = 'BRANDS.AMG';
            }
        })
    }

    /**
     * Toggles the car chooser layer.
     * @param {boolean} open - Specifies whether to open or close the layer.
     * @param {boolean} isVin - Specifies whether the layer opens VIN.
     */
    toggleLayer(open: boolean, isVin?: boolean): void {
        this.toggleCarChooserLayer.next({open: open, isVin: isVin});
    }


    /**
     *
     * Helpers
     * Used to scroll to next designation after one is selected
     *
     */

    private scrollToBottom(el: string): void {
        setTimeout(() => {
            document.getElementById(el)?.scrollIntoView(false);
        }, 100)
    }

    private scrollToElement(el: string): void {
        setTimeout(() => {
            document.getElementById(el)?.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
        }, 100)
    }

    public scrollToEl(el: string, toBottom: boolean = false): void {
        if (toBottom) {
            this.scrollToBottom(el);
            return;
        }

        if (el) {
            this.scrollToElement(el);
            return;
        }
    }
}
