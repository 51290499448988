<div class="collection">
	<wb-slider *ngIf="slides && slides.length > 0"
               show-dot-navigation
               scroll-snap
               dot-navigation-position="bottom"
               auto-rotate-interval-ms="7000"
               [theme]="theme"
               (wbchange)="handleTheme($event)">
		<wb-slider-item *ngFor="let s of slides">
            <wb-stage [theme]="s.isTextBlack ? 'light' : 'dark'">
                <wb-aspect-ratio slot="media" ratio="9x4">
                    <picture>
                        <source [srcset]="s.image480"
                                media="(max-width:480px)">
                        <source [srcset]="s.image768"
                                media="(max-width:768px)">
                        <source [srcset]="s.image1024"
                                media="(max-width:1024px)">
                        <source [srcset]="s.image1280"
                                media="(max-width:1280px)">
                        <source [srcset]="s.image1440"
                                media="(max-width:1440px)">
                        <source [srcset]="s.image1680"
                                media="(max-width:1680px)">
                        <img [src]="s.image"
                             alt="{{s.imageDescription ? s.imageDescription : s?.description}}">
                    </picture>
                </wb-aspect-ratio>

                <wb-heading tag="h2" size="xl">{{s.headline}}</wb-heading>
                <wb-text tag="p" size="l" class="wb-margin-top-s">{{s.description}}</wb-text>

                <wb-button slot="buttons" theme="dark" size="l" variant="primary"
                           [routerLink]="s.buttonLink"
                           (click)="trackStageSlider(s.buttonLink)">
                    {{s.buttonText}}
                </wb-button>
            </wb-stage>
        </wb-slider-item>
    </wb-slider>

    <div class="page-wrapper spacing-m">
        <zk-collection-category-teaser></zk-collection-category-teaser>

        <div *ngIf="textTeaser"
             class="text-teaser spacing-m">

            <wb-heading size="m" class="headline" [innerHTML]="textTeaser.headline"></wb-heading>

            <p size="l" class="text" [innerHTML]="textTeaser.text"></p>
        </div>

    </div>

    <wb-stage *ngIf="productTeaserBig" theme="dark">
        <wb-aspect-ratio slot="media" ratio="9x4">
            <picture>
                <source [srcset]="productTeaserBig.image480"
                        media="(max-width:480px)">
                <source [srcset]="productTeaserBig.image768"
                        media="(max-width:768px)">
                <source [srcset]="productTeaserBig.image1024"
                        media="(max-width:1024px)">
                <source [srcset]="productTeaserBig.image1280"
                        media="(max-width:1280px)">
                <source [srcset]="productTeaserBig.image1440"
                        media="(max-width:1440px)">
                <source [srcset]="productTeaserBig.image1680"
                        media="(max-width:1680px)">
                <img [src]="productTeaserBig.image"
                     alt="{{productTeaserBig?.imageDescription}}">
            </picture>
        </wb-aspect-ratio>

        <wb-heading tag="h2" size="xl">{{productTeaserBig.headline}}</wb-heading>
        <wb-text tag="p" size="l" class="wb-margin-top-s" [innerHTML]="productTeaserBig.text"></wb-text>

        <wb-button slot="buttons" theme="dark" size="l" variant="primary"
                   [routerLink]="productTeaserBig.buttonLink">{{productTeaserBig.buttonLabel}}</wb-button>
    </wb-stage>
</div>
