// ng
import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

// services
import { CompatibilityCheckService } from '@shared/shared-services/compatibility/compatibility.service';
import { CarChooserService } from "@shared/components/car-chooser/car-chooser.service";
import { ShoppingCartService } from '@shared/shared-services/shopping-cart/shopping-cart.service';
import { SelectedCarService } from '@shared/shared-services/selected-car/selected-car.service';
import { AppService } from '../../../../../../../app.service';

// models
import { WheelDetail } from '@shared/global-models/wheelDetail.model';
import { Article } from '@shared/global-models/article/article.model';
import { MarketModel } from '@shared/global-models/market.model';

@Component({
    selector: 'zk-wheel-more-info',
    templateUrl: './wheel-more-info.component.html',
    styleUrls: ['./wheel-more-info.component.scss']
})
export class WheelMoreInfoComponent implements OnInit, OnDestroy {
    @Input()
    wheel: WheelDetail;

    @Output()
    toggleMoreInfoEvent = new EventEmitter<null>();

    compatibilityChecked: boolean = false;
    marketData: MarketModel;

    private selectedCarChangedSubscription: Subscription;

    constructor(
        private _compatibilityService: CompatibilityCheckService,
        private _carChooserService: CarChooserService,
        private _shoppingCartService: ShoppingCartService,
        private _selectedCarService: SelectedCarService,
        private _appService: AppService
    ) {}

    ngOnInit() {
        this.compatibilityChecked = this._selectedCarService.loggedInWithVIN;
        this.marketData = this._appService.currentMarket;

        this.listenToChanges();
    }

    ngOnDestroy() {
        if (this.selectedCarChangedSubscription) {
            this.selectedCarChangedSubscription.unsubscribe();
        }
    }

    clickClose() {
        this.toggleMoreInfoEvent.next();
    }

    /**
     * Builds rim set articles and shows them in the compatibility check modal as current articles
     */
    clickCompatibilityCheck() {
        const rimSet: Article[] = this._shoppingCartService.buildRimSetFromWheelArticle(this.wheel);

        // only display rim set articles when not yet added to the shopping cart
        let rimSetWasAddedToCart: boolean = false;
        for (const item of rimSet) {
            rimSetWasAddedToCart = this._shoppingCartService.getArticleFromCartById(item.articleId) !== null;
        }

        if (!rimSetWasAddedToCart) {
            this._compatibilityService.currentArticles = rimSet;
        }

        this._carChooserService.toggleLayer(true, true);
    }

    printPDF(): void {
        this._shoppingCartService.proceedToDownloadPDF(this.wheel);
    }

    private listenToChanges() {
        this.selectedCarChangedSubscription = this._selectedCarService.carLineSubscriber.subscribe((newCarLine) => {
            if (newCarLine) {
                // compatible articles are given when logged in with VIN
                this.compatibilityChecked = this._selectedCarService.loggedInWithVIN;
            }
        });
    }
}
