<zk-single-image-stage [image]="image"
                       [headline]="headline">
</zk-single-image-stage>

<!-- All other categories except wheels -->
<zk-product-list [mainCategoryId]="mainGroupId"
                 [ngClass]="carLogged ? 'product-list has-margin-bottom' : 'product-list'">
</zk-product-list>

<zk-highlights-teaser *ngIf="carLogged"></zk-highlights-teaser>
