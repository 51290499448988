<div class="start-page">
    <wb-slider *ngIf="slides && slides.length > 0"
               [ngClass]="isCarLogged ? 'slider has-margin-bottom' : 'slider'"
               show-dot-navigation
               auto-rotate-interval-ms="7000"
               scroll-snap
               dot-navigation-position="bottom"
               [theme]="theme"
               (wbchange)="handleTheme($event)">
        <wb-slider-item *ngFor="let s of slides">
            <wb-stage [theme]="s.isTextBlack ? 'light' : 'dark'">
                <wb-aspect-ratio slot="media" ratio="9x4">
                    <picture>
                        <source [srcset]="s.image480"
                                media="(max-width:480px)">
                        <source [srcset]="s.image768"
                                media="(max-width:768px)">
                        <source [srcset]="s.image1024"
                                media="(max-width:1024px)">
                        <source [srcset]="s.image1280"
                                media="(max-width:1280px)">
                        <source [srcset]="s.image1440"
                                media="(max-width:1440px)">
                        <source [srcset]="s.image1680"
                                media="(max-width:1680px)">
                        <img [src]="s.image"
                             alt="{{s.imageDescription ? s.imageDescription : s?.description}}">
                    </picture>
                </wb-aspect-ratio>

                <wb-heading tag="h2" size="xl">{{s.headline}}</wb-heading>
                <wb-text tag="p" size="l" class="wb-margin-top-s">{{s.description}}</wb-text>

                <wb-button slot="buttons" theme="dark" size="l" variant="primary"
                           [routerLink]="s.buttonLink"
                           (click)="trackStageSlider(s.buttonLink)">
                    {{s.buttonText}}
                </wb-button>
            </wb-stage>
        </wb-slider-item>
    </wb-slider>

    <zk-highlights-teaser *ngIf="isCarLogged"></zk-highlights-teaser>

</div>
