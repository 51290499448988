<div class="header" *ngIf="isMenuActive">

    <div *ngIf="!isSplashPage && marketData.collectionMarket" class="header_mode-switch">
        <span class="switch-button {{isAccessoriesMode ? 'is-active' : ''}}" (click)="switchMode('acc')">
            <wb-icon name="car-xs" class="icon-car"></wb-icon>
            <span zkTranslate="HEADER.ACCESSORIES_MODE"></span>
        </span>
        <span class="switch-button {{!isAccessoriesMode ? 'is-active' : ''}}" (click)="switchMode('coll')">
            <wb-icon name="navigation-coach" class="icon-coach"></wb-icon>
            <span zkTranslate="HEADER.COLLECTION_MODE"></span>
        </span>
    </div>

    <!--	WorkBench HEADER	-->
    <div class="header_main" #header>
        <wb-header-bar star-href="/" star-label="Go to home page">

            <!--    Desktop buttons     -->
            <wb-header-language-menu slot="desktop-meta" aria-label="Language Menu"
                                     *ngIf="marketData?.availableLanguages.length > 1">
                    <zk-language-switch style="display: flex" [marketData]="marketData"></zk-language-switch>
            </wb-header-language-menu>

            <wb-header-button *ngIf="!isSplashPage"
                              slot="desktop-meta"
                              zkTrackClick="dealer_locator_click"
                              zkTrackClickPosition="header"
                              (click)="openDealerLocatorModal()">
                <wb-icon name="bds/location-dealer/16"></wb-icon>
            </wb-header-button>

            <wb-header-button *ngIf="!isSplashPage"
                              slot="desktop-meta" label="Open wishlist" class="wishlist-button"
                              routerLink="/wishlist">
                <wb-icon name="bds/star/16"></wb-icon>
                <span class="wishlist-button_counter" *ngIf="cartItemsCount > 0">
                    {{ cartItemsCount }}
                </span>
            </wb-header-button>

            <!--    Replace WB logo with custom one  -->
            <div slot="desktop-meta" class="logo-overlay" routerLink="/">
                <img src="ui/assets/icons/header/logo-desktop.svg" *ngIf="!isAmg" alt="">
                <img src="ui/assets/icons/header/logo-desktop-amg.svg" *ngIf="isAmg" alt="">
            </div>


            <!--    TABLET buttons     -->
            <wb-header-button *ngIf="!isSplashPage"
                              slot="tablet-meta-start" toggles-fly-in="level-1" label="Open model menu">
                <wb-icon name="bds/menu/16"></wb-icon>
            </wb-header-button>

            <wb-header-language-menu slot="tablet-meta-end" aria-label="Language Menu"
                                     *ngIf="marketData?.availableLanguages.length > 1">
                <zk-language-switch style="display: flex" [marketData]="marketData"></zk-language-switch>
            </wb-header-language-menu>

            <wb-header-button *ngIf="!isSplashPage"
                              slot="tablet-meta-end"
                              zkTrackClick="dealer_locator_click"
                              zkTrackClickPosition="header"
                              (click)="openDealerLocatorModal()">
                <wb-icon name="bds/location-dealer/16"></wb-icon>
            </wb-header-button>

            <wb-header-button *ngIf="!isSplashPage"
                              slot="tablet-meta-end" label="Open wishlist" class="wishlist-button"
                              routerLink="/wishlist">
                <wb-icon name="bds/star/16"></wb-icon>
                <span class="wishlist-button_counter" *ngIf="cartItemsCount > 0">
                    {{ cartItemsCount }}
                </span>
            </wb-header-button>

            <!-- Search button should be the last element in header on desktop and tablet -->
            <wb-header-button *ngIf="!isSplashPage && marketData.searchMarket"
                              [slot]="searchSlot" label="Open search" (click)="openSearch()">
                <wb-icon name="bds/search/16"></wb-icon>
            </wb-header-button>

            <zk-search *ngIf="!isMobile"
                       [slot]="searchSlot"
                       [isAccessoriesMode]="isAccessoriesMode">
            </zk-search>

            <!--    Replace WB logo with custom one   -->
            <div slot="tablet-meta-start" class="logo-overlay is-tablet" routerLink="/">
                <img src="ui/assets/icons/header/logo-tablet.svg" *ngIf="!isAmg" alt="">
                <img src="ui/assets/icons/header/logo-tablet-amg.svg" *ngIf="isAmg" alt="">
            </div>


            <!--    Mobile buttons     -->
            <wb-header-button slot="mobile-meta-start" toggles-fly-in="level-1" label="Open model menu">
                <wb-icon name="bds/menu/16"></wb-icon>
            </wb-header-button>

            <wb-header-button *ngIf="!isSplashPage"
                              slot="mobile-meta-start"
                              zkTrackClick="dealer_locator_click"
                              zkTrackClickPosition="header"
                              (click)="openDealerLocatorModal()">
                <wb-icon name="bds/location-dealer/16"></wb-icon>
            </wb-header-button>

            <wb-header-button *ngIf="!isSplashPage"
                              slot="mobile-meta-end" label="Open wishlist" class="wishlist-button"
                              routerLink="/wishlist">
                <wb-icon name="bds/star/16"></wb-icon>
                <span class="wishlist-button_counter" *ngIf="cartItemsCount > 0">
                    {{ cartItemsCount }}
                </span>
            </wb-header-button>

            <wb-header-button *ngIf="!isSplashPage && marketData.searchMarket"
                              slot="mobile-meta-end" label="Open search" toggles-fly-in="search" (click)="openSearch()">
                <wb-icon name="bds/search/16"></wb-icon>
            </wb-header-button>

            <!--    Logo overlay so it can be clicked   -->
            <div slot="mobile-meta-start" class="logo-overlay is-mobile" routerLink="/"></div>
        </wb-header-bar>

        <wb-header-navigation *ngIf="!isSplashPage" class="header_main_nav" aria-label="Main Navigation" #navigation>
            <wb-header-navigation-item class="link is-brand-icon" active
                                       *ngIf="isAccessoriesMode"
                                       (click)="openFlyOut('brands')">
                <wb-heading style="font-size: 20px" zkTranslate="HEADER.OUR_BRANDS"></wb-heading>
            </wb-header-navigation-item>

            <wb-header-navigation-item class="link"
                                       [active]="activeFlyOut === 'categories'"
                                       (click)="openFlyOut('categories')">
                <span zkTranslate="HEADER.ALL_CATEGORIES"></span>
            </wb-header-navigation-item>
        </wb-header-navigation>


        <!--    Not part of the WB header - custom FlyOut that holds categories and brands   -->
        <div class="header_main_sub-nav" *ngIf="!isSplashPage">
            <div class="flyout is-{{activeFlyOut}}">

                <div class="flyout-wrapper">

                    <!-- BRANDS FlyOut -->
                    <ng-container *ngIf="activeFlyOut === 'brands' && isAccessoriesMode">
                        <wb-horizontal-scroll>
                            <wb-subnavigation>
                                <wb-subnavigation-item *ngFor="let type of vehicleTypes"
                                                       (click)="setVehicleType(type)"
                                                       [active]="type.isActive"
                                                       zkTrackClick="brand_change"
                                                       zkTrackClickValue="{{type.name}}">
                                    <span zkTranslate="{{type.localisationKey}}"></span>
                                </wb-subnavigation-item>
                            </wb-subnavigation>
                        </wb-horizontal-scroll>
                    </ng-container>

                    <!-- Product Categories Menu / FlyOut -->
                    <ng-container *ngIf="activeFlyOut === 'categories'">
                        <div class="categories">
                            <wb-horizontal-scroll>
                                <wb-subnavigation>
                                    <wb-subnavigation-item *ngFor="let group of groups"
                                                           (mouseover)="selectSubgroup(group)"
                                                           [routerLink]="baseUrl + group.urlName">
                                        {{group.name}}
                                    </wb-subnavigation-item>
                                </wb-subnavigation>
                            </wb-horizontal-scroll>

                            <wb-heading size="l" class="categories-heading">{{activeSubgroup?.name}}</wb-heading>
                            <div class="categories-lists">
                                <div class="list">
                                    <wb-text size="m" [routerLink]="baseUrl + activeSubgroup?.urlName"
                                             zkTranslate="GENERAL.SHOW_ALL">
                                    </wb-text>
                                </div>
                                <div class="list">
                                    <wb-text *ngFor="let subGroup of activeSubgroup?.subGroups" size="m"
                                             [routerLink]="baseUrl + activeSubgroup?.urlName + '/' + subGroup.urlName">
                                        {{subGroup.name}}
                                    </wb-text>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>

            </div>
        </div>

        <wb-header-fly-in-container>

            <!--    Mobile FlyIn Menu  -->
            <wb-header-mobile-fly-in class="level-1" level="1" headline="Mobile Level 1"
                                     show-star star-href="#" star-label="Go to homepage">

                <wb-header-button slot="top-bar-start" toggles-fly-in="level-1"
                                  class="logo-overlay is-in-menu">
                    <img src="ui/assets/icons/header/logo-mobile-menu.svg" *ngIf="!isAmg" alt="">
                    <img src="ui/assets/icons/header/logo-mobile-menu-amg.svg" *ngIf="isAmg" alt="">
                </wb-header-button>

                <wb-header-button slot="top-bar-end" toggles-fly-in="level-1" label="Close fly-in level 1">
                    <wb-icon name="bds/close/24"></wb-icon>
                </wb-header-button>

                <div *ngIf="marketData.collectionMarket" class="header_mode-switch is-mobile">
                    <span class="switch-button {{isAccessoriesMode ? 'is-active' : ''}}"
                          (click)="switchMode('acc')">
                        <wb-icon name="car-xs" class="icon-car"></wb-icon>
                            <wb-text size="l" zkTranslate="HEADER.ACCESSORIES_MODE"></wb-text>
                    </span>
                    <span class="switch-button {{!isAccessoriesMode ? 'is-active' : ''}}"
                          (click)="switchMode('coll')">
                        <wb-icon name="navigation-coach" class="icon-coach"></wb-icon>
                            <wb-text size="l" zkTranslate="HEADER.COLLECTION_MODE"></wb-text>
                    </span>
                </div>

                <div *ngIf="marketData.collectionMarket" class="spacer"></div>

                <!--    Brands / VehicleTypeSwitch 1th Level    -->
                <wb-header-fly-in-item *ngIf="isAccessoriesMode" toggles-fly-in="brands">
                    <wb-heading size="m" style="font-size: 20px; line-height: 24px" zkTranslate="HEADER.OUR_BRANDS">
                    </wb-heading>
                    <wb-icon slot="icon" name="bds/chevron-right/24" flip-rtl></wb-icon>
                </wb-header-fly-in-item>

                <!--    Categories 1st Level menu items    -->
                <ng-container *ngIf="!isSplashPage">
                    <wb-header-fly-in-item toggles-fly-in="groups"
                                           *ngFor="let group of groups"
                                           (click)="selectSubgroup(group)">
                    <span class="group-icon">
                        <svg><use [attr.href]="'assets/icons/icons.svg#' + group.iconReference"></use></svg>
                    </span>
                        {{group.name}}
                        <wb-icon slot="icon" name="bds/chevron-right/24" flip-rtl></wb-icon>
                    </wb-header-fly-in-item>
                </ng-container>

                <wb-header-language-menu slot="footer" aria-label="Language Menu"
                                         *ngIf="marketData?.availableLanguages.length > 1">
                    <zk-language-switch style="display: flex" [marketData]="marketData"></zk-language-switch>
                </wb-header-language-menu>
            </wb-header-mobile-fly-in>

            <!--    Brands / VehicleTypeSwitch 2nd Level    -->
            <wb-header-mobile-fly-in *ngIf="isAccessoriesMode"
                                     class="brands" level="2" headline="{{brandsLocale}}" back-button-label="Back"
                                     close-button-label="Close" show-back-button show-close-button>
                <wb-header-fly-in-item *ngFor="let type of vehicleTypes"
                                       (click)="setVehicleType(type); closeFlyOutMenu()">
                        <span zkTranslate="{{type.localisationKey}}"></span>
                </wb-header-fly-in-item>
            </wb-header-mobile-fly-in>

            <!--    Categories 2nd Level menu items    -->
            <wb-header-mobile-fly-in class="groups" level="2" headline="{{activeSubgroup?.name}}" back-button-label="Back"
                                     close-button-label="Close" show-back-button show-close-button>
                <wb-header-fly-in-item [routerLink]="baseUrl + activeSubgroup?.urlName"
                                       (click)="closeFlyOutMenu()" zkTranslate="GENERAL.SHOW_ALL">
                </wb-header-fly-in-item>
                <wb-header-fly-in-item *ngFor="let subGroup of activeSubgroup?.subGroups"
                                       [routerLink]="baseUrl + activeSubgroup?.urlName + '/' + subGroup.urlName"
                                       (click)="closeFlyOutMenu()">
                    {{subGroup.name}}
                </wb-header-fly-in-item>

            </wb-header-mobile-fly-in>

            <!-- Search Mobile & Tablet / FlyIn -->
            <wb-header-mobile-fly-in class="search" level="2" headline="Back" back-button-label="Back" show-back-button>
                <zk-search *ngIf="isMobile"
                           [isAccessoriesMode]="isAccessoriesMode"
                           (closeMobileFlyIn)="this.closeFlyOutMenu()">
                </zk-search>
            </wb-header-mobile-fly-in>

            <!--    Tablet FlyIn Menu  -->
            <wb-header-desktop-fly-in class="level-1" level="1" back-button-label="Back" close-button-label="Close">
                <div class="header_mode-switch is-tablet" *ngIf="!isSplashPage && marketData.collectionMarket">
                    <span class="switch-button {{isAccessoriesMode ? 'is-active' : ''}}"
                          (click)="switchMode('acc')">
                        <wb-icon name="car-xs" class="icon-car"></wb-icon>
                            <wb-text size="l" zkTranslate="HEADER.ACCESSORIES_MODE"></wb-text>
                    </span>
                    <span class="switch-button {{!isAccessoriesMode ? 'is-active' : ''}}"
                          (click)="switchMode('coll')">
                        <wb-icon name="navigation-coach" class="icon-coach"></wb-icon>
                            <wb-text size="l" zkTranslate="HEADER.COLLECTION_MODE"></wb-text>
                    </span>
                </div>

                <wb-header-fly-in-item *ngIf="isAccessoriesMode" toggles-fly-in="brands">
                    <wb-heading size="m" style="font-size: 20px; line-height: 24px" zkTranslate="HEADER.OUR_BRANDS">
                    </wb-heading>
                    <wb-icon slot="icon" name="bds/chevron-right/24" flip-rtl></wb-icon>
                </wb-header-fly-in-item>

                <wb-header-fly-in-item toggles-fly-in="groups"
                                       *ngFor="let group of groups"
                                       (click)="selectSubgroup(group)">
                    {{group.name}}
                    <wb-icon slot="icon" name="bds/chevron-right/24" flip-rtl></wb-icon>
                </wb-header-fly-in-item>
            </wb-header-desktop-fly-in>

            <wb-header-desktop-fly-in *ngIf="isAccessoriesMode"
                                      class="brands" level="2" headline="{{brandsLocale}}"
                                      back-button-label="Back" close-button-label="Close">
                <wb-header-fly-in-item *ngFor="let type of vehicleTypes"
                                       (click)="setVehicleType(type); closeFlyOutMenu()">
                    <span zkTranslate="{{type.localisationKey}}"></span>
                </wb-header-fly-in-item>
            </wb-header-desktop-fly-in>

            <wb-header-desktop-fly-in class="groups" level="2" headline="{{activeSubgroup?.name}}"
                                      back-button-label="Back" close-button-label="Close">
                <wb-header-fly-in-item [routerLink]="baseUrl + activeSubgroup?.urlName"
                                       (click)="closeFlyOutMenu()" zkTranslate="GENERAL.SHOW_ALL">
                </wb-header-fly-in-item>
                <wb-header-fly-in-item *ngFor="let subGroup of activeSubgroup?.subGroups"
                                       [routerLink]="baseUrl + activeSubgroup?.urlName + '/' + subGroup.urlName"
                                       (click)="closeFlyOutMenu()">
                    {{subGroup.name}}
                </wb-header-fly-in-item>
            </wb-header-desktop-fly-in>

        </wb-header-fly-in-container>
    </div>
</div>
<zk-car-chooser-teaser *ngIf="!isSplashPage && isAccessoriesMode"></zk-car-chooser-teaser>

