import { Injectable } from '@angular/core';
import { Router } from "@angular/router";

import { TranslationService } from "@shared/shared-services/translate/translation.service";
import { environment } from "../../../../environments/environment";

declare let gtag: Function;

interface consentModel {
    analytics_storage: consentState;
}

enum consentState {
    GRANTED = 'granted',
    DENIED = 'denied'
}

@Injectable({
    providedIn: 'root',
})
export class ConsentHandlerService {

    private currentLanguage: string;
    private currentMarket: string;

    private disabledMarkets = ["RU", "CN"];
    private UCDisabled = !window || window.navigator.userAgent.indexOf('Prerender') !== -1 || environment.testEnv;

    constructor(private _router: Router,
                private _translationService: TranslationService
    ) {
        this.setLandAndMarket();
    }

    /**
     * Set up the Usercentrics consent script
     */
    initUsercentricsScript(): void {
        if (this.UCDisabled || this.disabledMarkets.includes(this.currentMarket)) {
			console.log('Usercentrics disabled');

            return;
        }

        const head: HTMLHeadElement = document.querySelector('head');
        const s: HTMLScriptElement = document.createElement('script');
        s.id = 'usercentrics-cmp';
        s.src = 'https://app.usercentrics.eu/browser-ui/latest/loader.js';

        // Set your settings ID and language here
        s.setAttribute('data-settings-id', 'h9KTWzjk7');
        s.setAttribute('data-language', this.currentLanguage);
        s.async = true;

        // Wait till script is initialized
        window.addEventListener('UC_UI_INITIALIZED', () => {
            this.listenToUserCentricEvents();
        });

        // Add script to the head
        head.appendChild(s);
    }

    /**
     * listen for user consent decision (info: 'cmp_event' is the name of the window event defined in the usercentrics admin interface)
     */
    private listenToUserCentricEvents(): void {

        window.addEventListener('cmp_event', (consent: any) => {
            if (consent.detail && consent.detail.event == 'consent_status') {
                let ucAnalyticsService = 'Google Analytics';

                if (consent.detail.hasOwnProperty(ucAnalyticsService)) {
                    this.setConsent(consent.detail[ucAnalyticsService]);
                } else {
                    this.setConsent(false);

                    // If denied, pass minimal information about clicks through URL parameters
                    gtag("set", "url_passthrough", true);
                }
            }
        });

        this.overrideUCLinks();
    }

    /**
     * Inform Google Analytics about users consent action
     * @param state
     */
    private setConsent(state: boolean): void {
        const consent: consentModel = {
            'analytics_storage': state ? consentState.GRANTED : consentState.DENIED
        };

        // Custom event
        gtag('event', state ? 'user_consent_granted' : 'user_consent_denied', {
            "page_path": location.href,
        });

        // GA Consent update
        gtag('consent', 'update', consent);

        // State will be saved in local storage for further use
        localStorage.setItem('consentStatus', JSON.stringify(consent));
    }

    /**
     * UC provides two custom events for the Imprint and Privacy Policy URL fields in the Admin Interface.
     * Set #onUcPrivacyClick for the Privacy Policy URL field
     * and #onUcImprintClick for the Imprint URL field.
     * With this, custom logic can be used for those links.
     * Here, links from footer are set to be same as links in Consent layer
     * Note: this listeners will be active only if input fields contain anchors #onUcPrivacyClick.
     * Custom links still can be used there, if desired, but should be avoided,
     * to be consistent (single point of true) and to be easier to maintain.
     */
    private overrideUCLinks(): void {

        // Listen for consent layer ImprintClick event
        window.addEventListener('onUcImprintClick', () => {
            const linkUrl = this._translationService.translate('POLICIES.LEGAL.URL');
            window.open(linkUrl, '_blank', 'noopener');
        });

        // Listen for consent layer PrivacyClick event
        window.addEventListener('onUcPrivacyClick', () => {
            this._router.navigate(['/legal/privacy']).then();
        });
    }

    private setLandAndMarket(): void {
        const languageAndMarket: string = window.location.pathname.split('/')[1];
        [this.currentLanguage, this.currentMarket] = languageAndMarket.split('-');
    }

}
