// ng
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
// own
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';


if (environment.production) {
    enableProdMode();
}

/**
 * Disable Google Analytics for specific stage.
 */
if (environment.disableTracking) {
    window['ga-disable-G-JSFVBVBS6X'] = true;
}

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch((err) => console.error(err));
