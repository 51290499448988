// NG
import { Component } from '@angular/core';
import { Router } from "@angular/router";
// Models
import { Article } from '@shared/global-models/article/article.model';
import { ShoppingCartData } from '@shared/shared-services/shopping-cart/model/shopping-cart-data.model';
import { MODE } from '@shared/global-models/mode.enum';
// Services
import { CompatibilityCheckService } from '@shared/shared-services/compatibility/compatibility.service';
import { PopupModalService } from "@shared/modals/popup-modal/popup-modal.service";
import { ShoppingCartService } from '@shared/shared-services/shopping-cart/shopping-cart.service';
import { SelectedCarService } from '@shared/shared-services/selected-car/selected-car.service';
import { TranslationService } from '@shared/shared-services/translate/translation.service';
import { CarChooserService } from "@shared/components/car-chooser/car-chooser.service";
import { CurrentRouteService } from "@shared/shared-services/current-route/current-route.service";


@Component({
    selector: 'zk-compatibility-feedback-modal',
    templateUrl: 'compatibility-feedback-modal.component.html',
    styleUrls: ['./compatibility-feedback-modal.component.scss']
})

export class CompatibilityFeedbackModalComponent {
    isOpen = false;
    currentArticles: Article[] = [];
	incompatibleArticles: Article[] = [];
	compatibleArticles: Article[] = [];
    wasAddedToCart: boolean = false;
    headline: string;
    selectedVehicleName: string;
    vinLocked: boolean = false;

    private _articlesInCart: ShoppingCartData = null;

    constructor(
        private router: Router,
        private _compatibilityCheckService: CompatibilityCheckService,
        private _modalService: PopupModalService,
        private _shoppingCartService: ShoppingCartService,
        private _selectedCarService: SelectedCarService,
        private _translationService: TranslationService,
        private _carChooserService: CarChooserService,
        private _routeService: CurrentRouteService
    ) {}

    initComponent() {
        this.headline = this._translationService.translate('PRODUCTDETAILS.VEHICLECOMPATIBILITY');
        this.currentArticles = this._compatibilityCheckService.currentArticles;
        this._articlesInCart = this._shoppingCartService.currentShoppingCart;
        this.vinLocked = this._selectedCarService.VIN !== null;

		// check for app mode to exclude COLL articles
		if (this._articlesInCart) {
			// Compatibility via VIN checked or check in background via car change
			this.compatibleArticles = this._articlesInCart.items.filter((a: Article) => (this.vinLocked ? a.fit === true : a.fit === undefined) && a.appMode === MODE.ACCESSORIES);
			this.incompatibleArticles = this._articlesInCart.items.filter((a: Article) => a.fit === false && a.appMode === MODE.ACCESSORIES);
		}

        if (this.currentArticles) {
            this.wasAddedToCart = !!this._shoppingCartService.getArticleFromCartById(this.currentArticles[0].articleId);
        }

        this.selectedVehicleName = this._selectedCarService.VIN
            ? this._selectedCarService.VIN
            : this._selectedCarService.getSelectedCarName();


        this.isOpen = true;
    }

    closeModal() {
        this.isOpen = false;
        this._modalService.close('compatibility-feedback');

        // PDP only: If current PDP Article not fit, redirect to its sub/category.
        // If no category, show category-not-available-modal
        if (this.currentArticles && this.currentArticles[0].fit === false) {
            this._routeService.handlePDPArticleRedirect(this.currentArticles[0]);

            return;
        }

        // Cart only: Stay in cart if any fitting articles,
        // if no fitting articles, redirect to proper category for selected vehicle
        if (this.router.url.includes('/wishlist') && !this._shoppingCartService.currentShoppingCart) {
            this.router.navigate([this._routeService.updateCurrentRoute(this._selectedCarService.carLine)], {
                queryParamsHandling: 'preserve'
            });

            return;
        }
    }

    compatibilityCheck() {
        this._modalService.close('compatibility-feedback');

        if (this._modalService.isModalActive('category-not-available-modal')) {
            this._modalService.close('category-not-available-modal');
        }

        this._carChooserService.toggleLayer(true, true);
    }

    onClose() {
        this.isOpen = false;
        this._shoppingCartService.removeNotFittingItems();
    }
}
