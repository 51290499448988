<div class="sticky-footer_wrapper"
     (click)="togglePriceInfo()">
    <div class="sticky-footer_headline">
            <wb-text size="l"
                  zkTranslate="STICKYFOOTER.HEADLINE">
            </wb-text>
        <wb-icon [name]="isInfoOpen ? 'bds/chevron-down/16' : 'bds/chevron-up/16'" slot="icon"
                 class="sticky-footer_icon">
        </wb-icon>
    </div>
    <div class="sticky-footer_content"
         [ngClass]="{'is-open': applyMargin}"
         [@stickyFooterAnimation]="isInfoOpen">
            <wb-text size="l" *ngIf="isAccessoriesMode" class="sticky-footer_content_defaultText"
                  zkTranslate="FOOTNOTES.UVP">
            </wb-text>

            <wb-text size="l"  *ngIf="!isAccessoriesMode" class="sticky-footer_content_defaultText"
                  zkTranslate="LIFESTYLE.STICKYFOOTER.CONTENT">
            </wb-text>
    </div>
</div>
