<zk-single-image-stage [image]="image"
                       [headline]="headlineForSingleStageWide"
                       [headlineColorBlack]="image?.textColor === 'black'">
</zk-single-image-stage>

<div class="page-wrapper">
    <div *ngIf="co2Label && !isAmg" class="co2">
        <wb-text size="l">{{co2Label}}</wb-text>
        <wb-text size="m" zkTranslate="CO2LABEL.HINT" class="co2Hint"></wb-text>
    </div>
    <wb-text *ngIf="co2Label && isAmg" [innerHTML]="co2Label"
             class="co2"
             size="l">
        {{co2Label}}
    </wb-text>

    <zk-error-message *ngIf="displayErrorMessage && errorCode"
                      [errorCode]="errorCode">
    </zk-error-message>

    <wb-heading tag="h2" size="m" zkTranslate="GENERAL.OURHIGHLIGHTFORYOU" class="heading-with-line"></wb-heading>

    <zk-product-teaser-large [article]="highlightArticle"
                             (onButtonClick)="routeToProductDetails($event)"
                             class="spacing-m">
    </zk-product-teaser-large>

    <zk-product-teaser-cards class="spacing-m">
        <zk-product-teaser-card *ngFor="let article of highlightArticles"
                                [article]="article"
                                [isCollection]="false"
                                zkTrackClick="highlights-click"
                                zkTrackClickPosition="model_start_page">
        </zk-product-teaser-card>
    </zk-product-teaser-cards>
</div>

<zk-highlights-teaser [showActionButton]="false"></zk-highlights-teaser>

<div class="page-wrapper no-top-padding">
    <wb-heading tag="h3" size="m" zkTranslate="GENERAL.DISCOVER_CATEGORIES" class="spacing-l heading-with-line"></wb-heading>
    <zk-product-overview class="spacing-s" [showDefaultHeadline]="false"></zk-product-overview>
</div>
