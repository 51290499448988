// NG
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';

// Models
import { Article } from '@shared/global-models/article/article.model';
import { VariantPropertyCollection } from '@shared/global-models/article/articleVariantCollection.model';
import { RimAxleType } from '../../global-models/rimAxleType.model';
import { MODE, SUBMODE } from '@shared/global-models/mode.enum';
import { Notification, NotificationIcons, NotificationTypes } from '@shared/components/notification/notification.model';

// Services
import { PopupModalService } from "@shared/modals/popup-modal/popup-modal.service";
import { ShoppingCartService } from '@shared/shared-services/shopping-cart/shopping-cart.service';
import { TranslationService } from '@shared/shared-services/translate/translation.service';
import { AppService } from '../../../app.service';
import { GroupMapperService } from '@shared/shared-services/group-mapper/group-mapper.service';
import { ProductGroupsService } from '@shared/shared-services/product-groups/product-groups.service';
import { SelectedCarService } from '@shared/shared-services/selected-car/selected-car.service';
import { ProductListService } from '@shared/shared-services/product-list/product-list.service';
import { NotificationService } from '@shared/components/notification/notification.service';
import { GoogleAnalyticsService } from "@shared/shared-services/google-analytics/google-analytics.service";

@Component({
    selector: 'zk-cart-item',
    templateUrl: 'cart-item.component.html',
    styleUrls: ['./cart-item.component.scss']
})
export class CartItemComponent implements OnInit {
    /**
     * Article to show
     */
    @Input()
    cartItem: Article = null;

    /**
     * Indicates that it is the compatibility-feedback-modal
     */
    @Input()
    isCompatibilityCheck: boolean = false;

    /**
     * Item not yet added to cart - preview article in the compatibility check modal
     */
    @Input()
    isDetailItem: boolean = false;

    /**
     * Indicates that it is the shopping-cart-modal that is shown after an item was added to cart
     */
    @Input()
    isAddedToCartFeedback: boolean = false;

    /**
     * Item not yet added to cart - Indicates that it is the rim-set-quantity modal
     */
    @Input()
    isRimSetPreviewLayer: boolean = false;

    /**
     * Case: If item is complete-wheel special set or preview article - hides the delete button in the card
     */
    @Input() hideDeleteIcon: boolean = false;

	/**
	 * Cart Item in modal needs to be less wide so style is adjusted accordingly
	 */
	@Input()
	isInModal: boolean = false;

    /**
     * Event that informs when the item was removed - currently only bind if "isRimSetPreviewLayer" is true
     */
    @Output()
    onDeleteEvent: EventEmitter<Article> = new EventEmitter<Article>();

    /**
     * Event that informs when the quantity of the item was changed by the user via the dropdown
     */
    @Output()
    onQuantityChangedEvent: EventEmitter<Article> = new EventEmitter<Article>();

	/**
	 * Event that emits new updated Article
	 */
	@Output()
	onArticleUpdateEvent: EventEmitter<Article> = new EventEmitter<Article>();

	dateAdded: number;
	cartItemPrice: string;
	cartItemPriceTotal: string;

    itemQuantityOptions: number[] = [];
    showPrice: boolean;

	get appMode() {
		return MODE;
	}

    constructor(
        private shoppingCartService: ShoppingCartService,
        private selectedCarService: SelectedCarService,
        private popupModalService: PopupModalService,
        public translationService: TranslationService,
        private router: Router,
        private _groupMapperService: GroupMapperService,
        private _appService: AppService,
        private _productGroupsService: ProductGroupsService,
		private _pdlService: ProductListService,
		private _notificationService: NotificationService,
        private _gaService: GoogleAnalyticsService
    ) {}

    ngOnInit() {
        this.populateItemAmountOptions();
		this.setArticlePrice();
        this.showPrice = this._appService.currentMarket.showPrice;
		this.dateAdded = this.cartItem.dateAdded;
    }

    onCartItemRemove(e: MouseEvent, article: Article) {
        if (e && article.articleId) {
            e.stopImmediatePropagation();

			this.shoppingCartService.removeItemFromShoppingCart([article.articleId], true);

			this.onDeleteEvent.emit(article);
        }
    }

    onItemQuantityChange() {
        // case: article not yet in shopping cart - compatibility check or rim set modal on PDP
        if (this.isDetailItem || this.isRimSetPreviewLayer) {
            const sub: Subscription = this.shoppingCartService.updateSingleItem(this.cartItem).subscribe(
                (response) => {
                    sub.unsubscribe();
                    // update model and member used in template
                    this.cartItem.totalGrossPriceText = response.totalGrossPriceText;
                    this.cartItemPriceTotal = response.totalGrossPriceText;
                },
                (error) => {
                    console.log('Error updating single item total price due to quantity change:', error);
                    sub.unsubscribe();
					this._notificationService.triggerNotification(
						new Notification(NotificationTypes.ERROR, NotificationIcons.WARNING, this.translationService.translate('ERRORMESSAGE.SERVICEUNAVAILABLE')));
                }
            );
        } else {
            // don´t trigger feedback modal when item quantity was changed via dropdown
            this.shoppingCartService.updateItemFromShoppingCart(this.cartItem.articleId, this.cartItem.quantity);
        }

        // inform
        this.onQuantityChangedEvent.emit(this.cartItem);
    }

    /**
     * Handles the click on the Article, closes the modal if it´s the CC.
     * Builds the route to the article and redirects to the Product Detail Page of lifestyle or accessories mode.
     * Note: If article is a wheel article then the Product Detail Page will redirect to the Wheel Special Page of accessories.
     */
    routeToArticle() {
        if (this.isCompatibilityCheck) {
            this.popupModalService.close('compatibility-feedback');
        }

        const baseUrl = this.buildBaseUrl(this.cartItem);
        // case: cart item belongs to active app mode
        if (this.cartItem.appMode === this._appService.appMode) {
            this.buildUrlToProductDetailsPage(baseUrl);

            return;
        }

        // case: cart item belongs to a different app mode - Update product groups is required first
        this._appService.appMode = this.cartItem.appMode;

        const sub: Subscription = this._productGroupsService.getAllProductGroups().subscribe(
            (response) => {
                if (response) {
                    sub.unsubscribe();
                    this.buildUrlToProductDetailsPage(baseUrl);
                }
            },
            (error) => {
                console.log('Failed updating product groups:', error);
                sub.unsubscribe();
            }
        );
    }

    getRimTypeHeadline(axleType: string): string {
        switch (axleType) {
            case RimAxleType.FRONT:
                return this.translationService.translate('WHEELS.AXLE.FRONT');
            case RimAxleType.REAR:
                return this.translationService.translate('WHEELS.AXLE.REAR');
            case RimAxleType.FAL:
                return this.translationService.translate('WHEELS.AXLE.FRONTLEFT');
            case RimAxleType.FAR:
                return this.translationService.translate('WHEELS.AXLE.FRONTRIGHT');
            case RimAxleType.RAL:
                return this.translationService.translate('WHEELS.AXLE.REARLEFT');
            case RimAxleType.RAR:
                return this.translationService.translate('WHEELS.AXLE.REARRIGHT');
            case RimAxleType.ALL:
                return (
                    this.translationService.translate('WHEELS.AXLE.FRONT') + ' & ' + this.translationService.translate('WHEELS.AXLE.REAR')
                );
        }
    }


	getVariantArticleData(variant: VariantPropertyCollection) {
        this._gaService.trackArticleVariantSwitch(this.cartItem.articleId, variant.articleId, "size", "cart_item");

		let selectedArticleVariant: Article;

		if (this.cartItem.sizeSelected === variant.name) {
			return;
		}

		if (this.cartItem.articleId === variant.articleId) {
			selectedArticleVariant = this.cartItem
		} else {
			selectedArticleVariant = this.cartItem.variantArticles.find((article: Article) => {
				return article.articleId === variant.articleId
			});
		}

		// If variant Article is selected, we need to fetch that article again, because variantArticle doesn't have
		// it's variantArticles therefore switching variants in cart would not be possible
		const sub: Subscription = this._pdlService.getCollectionArticleDetails(selectedArticleVariant.articleId).subscribe(
			(response: Article) => {
				if (response) {
					let selectedVariantWithVariants: Article = response;

					// Set date added from previous variant, in order to remain sort order after size is changed
					selectedVariantWithVariants.dateAdded = this.dateAdded;
					selectedVariantWithVariants.sizeSelected = variant.name;

                    // keep same mode. Bugfix for MBCS-396
                    selectedVariantWithVariants.appMode = MODE.LIFESTYLE;
                    selectedVariantWithVariants.appSubMode = SUBMODE.COLLECTION;

					// delete current article and afterward add selected one
					this.shoppingCartService.removeArticleFromLocalStorage(this.shoppingCartService.currentShoppingCart,[this.cartItem.articleId]);
					this.shoppingCartService.addItemsToShoppingCart([selectedVariantWithVariants], this.cartItem.quantity);
				}

				sub.unsubscribe();
			},
			(error) => {
				sub.unsubscribe();
				console.log('Failed to fetch article data: ', error);
			}
		);
	}

	private setArticlePrice(): void {
		const pricePrefix: string = this.translationService.translate('LIFESTYLE.GENERAL.FROM') + ' ';
		const activeColor = this.cartItem.variantPropertiesCollection?.colors.find(color => { return color.hint === 'self'});

		if (this.cartItem.appMode === MODE.LIFESTYLE) {
			if (!this.cartItem.sizeSelected && activeColor?.prices?.various) {
				this.cartItemPrice = pricePrefix + this.cartItem.grossPriceText;
				this.cartItemPriceTotal = pricePrefix + this.cartItem.totalGrossPriceText;
			} else {
				this.cartItemPrice = this.cartItem.grossPriceText;
				this.cartItemPriceTotal = this.cartItem.totalGrossPriceText;
			}
		} else {
			this.cartItemPrice = this.cartItem.grossPriceText;
			this.cartItemPriceTotal = this.cartItem.totalGrossPriceText;
		}
	}

    /**
     * Builds the base URL where clicked article belongs to.
     */
    private buildBaseUrl(cartItem: Article): string {
        if (cartItem.appMode === MODE.LIFESTYLE) {
            return this.translationService.currentLang + MODE.LIFESTYLE + SUBMODE.COLLECTION + '/products';
        }

        if (cartItem.appMode === MODE.ACCESSORIES) {
            let url = this.translationService.currentLang + MODE.ACCESSORIES + SUBMODE[cartItem.appSubMode.toUpperCase()];
            url += this.selectedCarService.carLine ? `/${this.selectedCarService.carLine.urlName}` : '/products';

            return url;
        }
    }

    /**
     * Builds the URL with category names the article belongs to and triggers routing.
     */
    private buildUrlToProductDetailsPage(baseUrl: string) {
        let alloyWheelsGroup: boolean = false;

        // second part - category names the article belongs to
        for (const groupId of this.cartItem.groupIdPath) {
            const groupName: string = this._groupMapperService.getGroupUrlNameFromGroupId(groupId);
            baseUrl += `/${groupName}`;

            if (this._groupMapperService.getGroupTypeById(groupId) === 'alloy-wheels') {
                alloyWheelsGroup = true;
            }
        }

        // third part - url name of the article | edge case handling for rear axles required (see ticket ZKDEV-953)
        if (this.cartItem.axle && this.cartItem.axle === RimAxleType.REAR && !alloyWheelsGroup) {
            baseUrl += `/${this.cartItem.otherAxleUrlName}`;
        } else if (!alloyWheelsGroup) {
            baseUrl += `/${this.cartItem.urlName}`;
        }

        this.router.navigate([baseUrl]).then();
    }

    private populateItemAmountOptions() {
        for (let i: number = 1; i <= 10; i++) {
            this.itemQuantityOptions.push(i);
        }
    }
}
