// ng
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

// services
import { ViewportService } from '@shared/shared-services/viewport/viewport.service';
import { SelectedCarService } from '@shared/shared-services/selected-car/selected-car.service';
import { ProductGroupsService } from '@shared/shared-services/product-groups/product-groups.service';
import { GroupMapperService } from '@shared/shared-services/group-mapper/group-mapper.service';
import { GoogleAnalyticsService } from '@shared/shared-services/google-analytics/google-analytics.service';
import { ProductListService } from "@shared/shared-services/product-list/product-list.service";

// models
import { WheelsCategory } from '@shared/global-models/wheelsCategory.model';
import { ProductGroup } from "@shared/global-models/productGroup.model";
import { WheelFilterDiameter } from './wheel-filter/wheel-filterbar.model';
import { PageName } from '@shared/shared-services/google-analytics/google-analytics.model';

@Component({
    selector: 'zk-wheel-special',
    templateUrl: './wheel-special.component.html',
    styleUrls: ['./wheel-special.component.scss']
})
export class WheelSpecialComponent implements OnInit, OnDestroy {
    wheelsData: WheelsCategory;
    showLoadingSpinner = true;
    isMobile = false;

    private _productGroupsConfig: ProductGroup[] = [];
    private _currentCategoryName = '';
    private _currentDiameterFilters: string = null;

    private _selectedCarChangedSubscription: Subscription;
    private _routerSubscription: Subscription;
    private _vpSubscription: Subscription;

    constructor(
        private _selectedCarService: SelectedCarService,
        private _productGroupsService: ProductGroupsService,
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _viewPortService: ViewportService,
        private _groupMapperService: GroupMapperService,
        private _gaService: GoogleAnalyticsService,
        private _pdlService: ProductListService
    ) {}

    /**
     * Initializing the component
     */
    ngOnInit() {
        this._currentCategoryName = this._activatedRoute.snapshot.params.category;
        this._productGroupsConfig = this._productGroupsService.groups;
        this.isMobile = this._viewPortService.getCurrentViewPort() === 'mq1';

        this.getWheelSpecialData(0);
        this.listenToChanges();
    }

    /**
     * On destroying the component
     */
    ngOnDestroy() {
        if (this._selectedCarChangedSubscription) {
            this._selectedCarChangedSubscription.unsubscribe();
        }

        if (this._routerSubscription) {
            this._routerSubscription.unsubscribe();
        }

        if (this._vpSubscription) {
            this._vpSubscription.unsubscribe();
        }
    }

    handlePagination(currentPage: number) {
        this.getWheelSpecialData(--currentPage);
    }

    handleDiameterFilter(diameterIds: string) {
        this._currentDiameterFilters = diameterIds.length === 0 ? null : diameterIds;
        this.getWheelSpecialData(0);
    }

    /**
     * Sets up all subscription that alter the component.
     */
    private listenToChanges() {
        // car selection triggers a reload of the groups. We use this info to receive only wheels that are fitting for the new car.
        this._selectedCarChangedSubscription = this._productGroupsService.groupsChangedInfo.subscribe((newGroups: ProductGroup[]) => {
            this._productGroupsConfig = newGroups;
            // On car change we want to request from API the complete set of new articles and not limit it by previous filters
            this._currentDiameterFilters = null;
            this.getWheelSpecialData(0);
        });

        // Handles the case when the main category changes - for instance from Wheels to AMG Accessories.
        // Note: To prevent duplicated reaction/API calls when route changes because of a car selection the category is compared.
        this._routerSubscription = this._router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                const newCategoryName: string = this._activatedRoute.snapshot.params.category;
                if (newCategoryName !== this._currentCategoryName) {
                    this._currentCategoryName = newCategoryName;
                    this.getWheelSpecialData(0);
                }
            }
        });

        this._vpSubscription = this._viewPortService.getViewportChangedObserver().subscribe((vp: string) => (this.isMobile = vp === 'mq1'));
    }

    /**
     * Get the data for the wheel special (light-alloy wheels)
     */
    private getWheelSpecialData(page: number) {
        const lightMetalWheelsSubcategoryId = this.getSubcategoryId();
        // case light-alloy wheels don´t exist for this car / market
        if (!lightMetalWheelsSubcategoryId) {
            return;
        }

        this.fetchWheelData(lightMetalWheelsSubcategoryId, page);
    }

    /**
     * Get alloy-wheel subcategory Id if it exists for this car / market. If not null is returned.
     */
    private getSubcategoryId(): string {
        // check if main group wheels and its sub group alloy-wheels exists
        const mainCategoryName = this._groupMapperService.getGroupNameFromUrlName(this._activatedRoute.snapshot.params.category);
        const mainCategoryId = this._groupMapperService.getGroupIdFromGroupName(mainCategoryName);
        const groupType: string = this._groupMapperService.getGroupTypeById(mainCategoryId);
        const groupModel: ProductGroup = this._productGroupsConfig.find((x) => x.type === groupType);
        // edge case: car change that has not the group wheels
        if (groupModel === undefined) {
            return null;
        }
        const subGroupModel: ProductGroup = groupModel.subGroups.find((x) => x.type === 'alloy-wheels');
        if (subGroupModel === undefined) {
            return null;
        }

        // get group id from name
        const lightMetalWheelsSubcategoryName: string = this._groupMapperService.getGroupNameFromUrlName(
            groupModel.urlName,
            subGroupModel.urlName
        );

        return this._groupMapperService.getGroupIdFromGroupName(mainCategoryName, lightMetalWheelsSubcategoryName);
    }

    /**
     * Triggers the API call to receive the wheel data.
     * @param subcategoryId - id of the sub category
     * @param page - page number, starting with 0
     */
    private fetchWheelData(subcategoryId: string, page: number) {
        this.showLoadingSpinner = true;
        const vehicleTypeId: string = this._selectedCarService.carLine ? this._selectedCarService.carLine.carLineId
                                                                       : this._selectedCarService.vehicleType.vehicleTypeId;

        const sub: Subscription = this._pdlService.getLightAlloyWheelsWithFilter(subcategoryId, vehicleTypeId, page, 10, this._currentDiameterFilters).subscribe(
            (response) => {
                sub.unsubscribe();
                this.handleWheelData(response);
            },
            (error) => {
                sub.unsubscribe();
                console.log('Fetching wheel data failed:', error);
                this.showLoadingSpinner = false;
            }
        );
    }

    /**
     * Handles the API response and updates the view of the components.
     * @param response
     */
    private handleWheelData(response: WheelsCategory) {
        this.wheelsData = response;
        this.setLastUsedFilter();
        this.updateTrackingProducts();

        this.showLoadingSpinner = false;
        window.scrollTo(0, 0);
    }

    /**
     * Updates shown articles for Tracking
     * @private
     */
    private updateTrackingProducts() {
        this._gaService.trackPageView(PageName.WHEEL_SPECIAL_PAGE);
        this._gaService.trackProductImpressions(this.wheelsData.articles);
    }

    /**
     * Sets the filter of the new data set to the state that it had before if this filter still exists for the new data (car).
     * Updates the view of the filter component.
     */
    private setLastUsedFilter() {
        if (this._currentDiameterFilters) {
            const currentFilters: Array<string> = this._currentDiameterFilters.split(',')
            for (const filterId of currentFilters) {
                const x: WheelFilterDiameter = this.wheelsData.rimSizes.find((item) => item.diameterId === filterId);
                if (x) x.active = true;
            }
        }
    }
}
