<div class="category-teaser" *ngIf="config">
    <div class="category-teaser-card">
        <a [routerLink]="config.left.link"
           zkTrackClick="collection_category_teaser"
           zkTrackClickPosition="1"
           zkTrackClickValue="{{config.left.text}}">
            <img class="image"
                 [src]="config.left.image.image"
                 [alt]="config.left.image.imageDescription">
            <span class="text">{{config.left.text}}</span>
        </a>
    </div>
    <div class="category-teaser-card">
        <a [routerLink]="config.middle.link"
           zkTrackClick="collection_category_teaser"
           zkTrackClickPosition="2"
           zkTrackClickValue="{{config.middle.text}}">
            <img class="image"
                 [src]="config.middle.image.image"
                 [alt]="config.middle.image.imageDescription">
            <span class="text">{{config.middle.text}}</span>
        </a>
    </div>
    <div class="category-teaser-card">
        <a [routerLink]="config.right.link"
           zkTrackClick="collection_category_teaser"
           zkTrackClickPosition="3"
           zkTrackClickValue="{{config.right.text}}">
            <img class="image"
                 [src]="config.right.image.image"
                 [alt]="config.right.image.imageDescription">
            <span class="text">{{config.right.text}}</span>
        </a>
    </div>
</div>

<div class="category-teaser-link">
    <wb-link variant="standalone" (click)="scrollToCategoryFooter()">
        <span zkTranslate="LIFESTYLE.PDL.TOALLCATEGORIES"></span>
    </wb-link>
</div>
