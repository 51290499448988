import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Components
import { IconComponent } from './icon.component';

@NgModule({
    imports: [CommonModule],
    exports: [IconComponent],
    declarations: [IconComponent],
    providers: []
})
export class IconModule {}
