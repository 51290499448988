import { ArticlePropertiesCollection } from '@shared/global-models/article/articlePropertiesCollection.model';
import { VariantPropertyCollection } from '@shared/global-models/article/articleVariantCollection.model';
import { VariantImages } from '@shared/global-models/article/articleVariantImages.model';
import { VariantPrices } from '@shared/global-models/article/articleVariantPrices.model';
import { MODE, SUBMODE } from '@shared/global-models/mode.enum';
import { Image } from "@shared/global-models/image.model";

export interface Article extends Image {
	articleId: string;
	articleNumber: string;
	assembleCosts: boolean;
	axle?: string;
	currency?: string;
	carLineIds?: string[];
	contentsVolumeInfo?: ContentVolumeInfo;
	description?: string;
    dimensions?: ItemDimensions;
	fit?: boolean;
	grossPrice?: string | number;
	grossPriceText?: string;
	groupIdPath: string[];
	groupUrlNamePath?: string[];
	headline?: string;
	headline1?: string;
	headline2?: string;
	images?: VariantImages[];
	outletPrice: boolean;
	otherAxleUrlName?: string;
	noticeLinks?: string[];
	notRemovable?: boolean;
	productType?: string;
	totalGrossPrice?: string | number;
	totalGrossPriceText?: string;
	variants?: number;
	variantArticles?: Article[];
	variantProperties?: VariantProperties[];
	variantPropertiesCollection?: VariantPropertiesCollection;
	urlName: string;
	quantity?: number;
	quantityAdded?: number;
	hazardSymbols?: PictogramInfoModel[];
	hazardWarnings?: PictogramInfoModel[];
	hazardHeadlines?: string[];
	propertiesCollection: ArticlePropertiesCollection;
	materialText: string;

	// Intern
	appMode: MODE;
	appSubMode: SUBMODE;
	dateAdded: number;
	sizeSelected: string;
	colorSelected: string;
}

interface PictogramInfoModel {
	designation: string;
	symbolUrl: string;
}

interface ContentVolumeInfo {
	contentText: string;
	contentMilliliterText: string;
	contentProLiterText: string;
	contentsVolumeInMl: number;
	grossPricePerContentsVolume: number;
	grossPricePerContentsVolumeText: string;
	priceVolumeUnit: string;
}

interface VariantPropertiesCollection {
	colors: VariantPropertyCollection[];
	sizes: VariantPropertyCollection[];
	prices: VariantPrices;
}

interface VariantProperties {
	nameKey?: string;
	value?: string;
	values?: VariantProperties[];
	available?: boolean;
}

export enum VariantType {
	COLOR = "COLOR",
	SIZE = "SIZE"
}

interface ItemDimensions {
    item?: Dimensions;
    shipping?: Dimensions;
}

interface Dimensions {
    height?: string;
    length?: string;
    weight?: string;
    width?: string;
}
