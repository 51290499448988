import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

// services
import { ProductListService } from '@shared/shared-services/product-list/product-list.service';
import { ProductGroupsService } from '@shared/shared-services/product-groups/product-groups.service';

// model
import { ArticlesPaginated } from '@shared/global-models/article/articlePaginated.model';
import { ProductGroup } from "@shared/global-models/productGroup.model";
import { ViewportService } from "@shared/shared-services/viewport/viewport.service";

@Component({
    selector: 'zk-collection-products-list',
    templateUrl: './collection-products-list.component.html',
    styleUrls: ['./collection-products-list.component.scss']
})
export class CollectionProductsListComponent implements OnInit, OnDestroy {
    articlesOfPage: ArticlesPaginated;
    activeProductGroup: ProductGroup;
    isMobile = false;
    isLoading = false;

    private _vpSubscription: Subscription;
    private _categoryChangeSubscription: Subscription;

    constructor(
        private _pdlService: ProductListService,
        private _activatedRoute: ActivatedRoute,
        private _productGroupsService: ProductGroupsService,
        private _vpService: ViewportService,
        private _router: Router
    ) {}

    ngOnInit(): void {
        this._categoryChangeSubscription = this._activatedRoute.params.subscribe((params) => {
            const mainGroup: ProductGroup = this._productGroupsService.groups.find((x: ProductGroup) => x.urlName === params['category']);
            const subGroup: ProductGroup = mainGroup.subGroups.find((x: ProductGroup) => x.urlName === params['subcategory']);

            // invalid url handling
            if (!subGroup) {
                this._router.navigate(['lifestyle/404']);

                return;
            }

            // render articles from sub or sub-sub group
            this.activeProductGroup = subGroup.subGroups
                ? subGroup.subGroups.find((x: ProductGroup) => x.urlName === params['subsubcategory'])
                : subGroup;

            // prevent NPE caused by switch between products groups with 1 or 2 level sub groups
            if (this.activeProductGroup) {
                this.getArticlesFromSubgroup(0);
            }
        });

        this.isMobile = this._vpService.getCurrentViewPort() === 'mq1';
        this._vpSubscription = this._vpService.getViewportChangedObserver().subscribe((vp: string) => (this.isMobile = vp === 'mq1'));
    }

    ngOnDestroy(): void {
        this._vpSubscription.unsubscribe();
        this._categoryChangeSubscription.unsubscribe();
    }

    handlePagination(currentPage: number) {
        this.getArticlesFromSubgroup(--currentPage);
    }

    private getArticlesFromSubgroup(page: number): void {
        // empty page on category change
        if (this.articlesOfPage) {
            this.articlesOfPage.articles = [];
        }

        window.scrollTo(0, 0);
        this.isLoading = true;

        const sub = this._pdlService.getCollectionSubcategoryArticles(this.activeProductGroup.groupId, page, 50).subscribe(
            (response: ArticlesPaginated) => {
                sub.unsubscribe();
                this.articlesOfPage = response;
                this.isLoading = false;
            },
            (error) => {
                sub.unsubscribe();
                console.log('Failed to fetch subcategory articles: ', error);
            }
        );
    }
}
