// ng
import { Component, OnInit } from '@angular/core';
// Service
import { TranslationService } from '@shared/shared-services/translate/translation.service';
import { PopupModalService } from "@shared/modals/popup-modal/popup-modal.service";
import { ResetWarningModalService } from '@shared/modals/reset-warning-modal/reset-warning-modal.service';
import { ShoppingCartService } from "@shared/shared-services/shopping-cart/shopping-cart.service";
import { ScrollLockService } from "@workbench/core";

@Component({
    selector: 'zk-reset-warning-modal',
    templateUrl: 'reset-warning-modal.component.html',
    styleUrls: ['./reset-warning-modal.component.scss']
})
export class ResetWarningModalComponent implements OnInit {
    modalHeadline: string = '';
    modalContentText: string = '';

    constructor(
        private _translationService: TranslationService,
        private _modalService: PopupModalService,
        private _resetWarningService: ResetWarningModalService,
        private _shoppingCartService: ShoppingCartService
    ) {}

    private _scrollLockService = new ScrollLockService();

    ngOnInit() {}

    initComponent() {
        const hasShoppingCartItems = this._shoppingCartService.currentShoppingCart && this._shoppingCartService.currentShoppingCart.items.length > 0;

        this.modalHeadline = hasShoppingCartItems
            ? this._translationService.translate('HEADER.MODAL.REMOVEVEHICLEANDWISHLISTHEADLINE')
            : this._translationService.translate('HEADER.MODAL.REMOVEVEHICLEHEADLINE');

        this.modalContentText = hasShoppingCartItems
            ? this._translationService.translate('HEADER.MODAL.REMOVEVEHICLEANDWISHLISTFORVEHICLECHANGETEXT')
            : this._translationService.translate('HEADER.MODAL.REMOVEVEHICLEFORSTARTPAGETEXT');

        this._scrollLockService ? this._scrollLockService.lock() : null;
    }

    onCancel() {
        this._scrollLockService?.isLocked ? this._scrollLockService.unlock() : null;
        this._modalService.close('reset-warning-modal');

        this._resetWarningService.actionAllowed.next(false);
    }

    onContinue() {
        this._scrollLockService?.isLocked ? this._scrollLockService.unlock() : null;
        this._modalService.close('reset-warning-modal');

        this._resetWarningService.actionAllowed.next(true);
    }
}
