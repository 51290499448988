<footer class="footer"
        [ngClass]="{'add-height': showPriceNotification}">

    <wb-link variant="standalone" theme="dark"
             class="switch-mode-link"
             *ngIf="switchModeVisible"
             (click)="switchMode()">
        <wb-icon slot="icon" name="bds/chevron-right/16"></wb-icon>
        <span [zkTranslate]="isAccessoriesMode ? 'GENERAL.LINKTOLIFESTYLE' : 'GENERAL.LINKTOACCESSORIES'"></span>
    </wb-link>

    <div class="links">
        <div class="footer-links-wrapper">
            <!-- Provider -->
            <div class="footer-links-wrapper_container provider">
                <a class="ui-standalone-link is-small no-padding is-light-grey"
                   (click)="linkClicked(footerLinks[0])"
                   [attr.target]="footerLinks[0].openLinkInTab ? '_blank' : null"
                   [attr.rel]="footerLinks[0].openLinkInTab ? 'noopener' : null"
                   [attr.href]="footerLinks[0].openLinkInTab ? footerLinks[0].linkUrl : null">
                    {{footerLinks[0].linkName}}
                </a>
            </div>
            <!-- 1 to N additional links (excluding provider on 1st place)-->
            <div class="footer-links-wrapper_container">
                <ul class="footer-links-wrapper_container_list">
                    <li *ngFor="let item of footerLinks | slice:1"
                        class="footer-links-wrapper_container_list_item">
                        <a class="ui-standalone-link is-small no-padding is-light-grey"
                           (click)="linkClicked(item)"
                           [attr.target]="item.openLinkInTab ? '_blank' : null"
                           [attr.rel]="item.openLinkInTab ? 'noopener' : null"
                           [attr.href]="item.openLinkInTab ? item.linkUrl : null">
                            {{item.linkName}}
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <!--Additional infos for the CO2 labels-->
    <div *ngIf="co2Label && isAmg">
        <wb-text tag="div" size="l" class="description-co2" [innerHTML]="co2Label"></wb-text>
    </div>
</footer>

<zk-sticky-footer *ngIf="showPriceNotification"></zk-sticky-footer>
