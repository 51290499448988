// NG
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
// services
import { ShoppingCartService } from '@shared/shared-services/shopping-cart/shopping-cart.service';
import { ViewportService } from '@shared/shared-services/viewport/viewport.service';
// model
import { WheelDetail } from '@shared/global-models/wheelDetail.model';
import { Article } from '@shared/global-models/article/article.model';
import { AppService } from '../../../../../app.service';

@Component({
    selector: 'zk-complete-wheels-info',
    templateUrl: './complete-wheels-info.component.html',
    styleUrls: ['./complete-wheels-info.component.scss']
})
export class CompleteWheelsInfoComponent implements OnInit, OnDestroy {
    @Input('completeWheelData')
    set completeWheelData(val: WheelDetail) {
        if (val) {
            this._cwData = val;
            this.tyreLabelVersion2021 = val.wheel.tyreLabelVersion === '2021';
            this.createCompleteWheelsData();
        }
    }

    get completeWheelData(): WheelDetail {
        return this._cwData;
    }

    completeWheels: WheelDetail[];
    isMobile: boolean = false;
    tyreLabelVersion2021: boolean = false;

    private _viewPortSubscription: Subscription;
    private _cwData: WheelDetail;

    constructor(
        private _shoppingCartService: ShoppingCartService,
        private _viewPortService: ViewportService,
		private _appService: AppService
    ) {}

    ngOnInit() {
        const vp: string = this._viewPortService.getCurrentViewPort();
        this.isMobile = vp === 'mq1' || vp === 'mq2';

        this.listenToChanges();
    }

    ngOnDestroy(): void {
    }

    addWheelToCart(completeWheelData) {
        // add single wheel to the cart
        this._shoppingCartService.addItemsToShoppingCart([completeWheelData], 1, true);
    }

    openTyreLabelLink(wheel: WheelDetail) {
        window.open(wheel.noticeLinks[0], '_blank');
    }

    singleWheelMaxReached(wheel: WheelDetail): boolean {
        const singleWheelInCart: Article = this._shoppingCartService.getArticleFromCartById(wheel.articleId);
        if (singleWheelInCart) {
            return singleWheelInCart.quantity === 10;
        } else {
            return false;
        }
    }

    // Create array from the data needed for the Complete wheels
    private createCompleteWheelsData() {
        this.completeWheels = this._shoppingCartService.buildRimSetFromWheelArticle(this.completeWheelData, false) as WheelDetail[];
    }

    private listenToChanges() {
        this._viewPortSubscription = this._viewPortService.getViewportChangedObserver().subscribe((newViewPort) => {
            this.isMobile = newViewPort === 'mq1' || newViewPort === 'mq2';
        });
    }
}
