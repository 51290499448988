<article class="pdp">
      <div class="page-wrapper pdp-wrapper"
           *ngIf="articleExists; else articleNotFound">
          <wb-spinner *ngIf="showPageLoadingSpinner" class="loading-spinner" theme="light"></wb-spinner>

          <!-- Left Detail Part -->
            <div class="pdp-left">
                <wb-link (click)="goBack()" variant="standalone">
                    <wb-icon name="bds/chevron-left/24" slot="icon" style="--size: 20px"></wb-icon>
                    <span zkTranslate="PRODUCTDETAILS.BACK"></span>
                    <span>&nbsp;{{mainCategoryName}}</span>
                </wb-link>

                <div *ngIf="isMobile">
                    <ng-container *ngTemplateOutlet="gallery"></ng-container>
                </div>

                <wb-heading tag="h1" size="l" class="pdp_headline">{{article?.headline}}</wb-heading>
                <!-- If the article has no variants we show the headline2 as description. Otherwise a dropdown to select the variant of an article -->
                <wb-text tag="span" size="l"
                      *ngIf="!article?.variants || article?.variants === 0">{{article?.headline2}}
                </wb-text>

                <wb-select class="pdp_dropdown" *ngIf="article?.variants > 0" [label]="dropdownLabel">
                    <select (change)="switchVariant($event)">
                        <option selected [value]="article.headline2">{{article.headline2}}</option>
                        <option *ngFor="let variant of article.variantArticles"
                                [value]="variant.articleId">
                            {{variant.headline2}}
                        </option>
                    </select>
                </wb-select>

                <div *ngIf="marketData.showPrice"
                     class="pdp-left_price">
                    <wb-heading tag="span" size="m" *ngIf="pricePrefix"
                          class="pdp-left_price_prefix">{{pricePrefix}}
                    </wb-heading>
                    <wb-heading tag="span" size="m">{{article?.grossPriceText}}</wb-heading>

                    <!--// TODO: Copied from Lib - check when available Article has data    -->
                    <div class="content-volume-info" *ngIf="article?.contentsVolumeInfo">
                        <span>{{article.contentsVolumeInfo.contentText}}</span>
                        <span>{{article.contentsVolumeInfo.contentsVolumeInMl}}</span>
                        <span>{{article.contentsVolumeInfo.contentMilliliterText}}</span>
                        <span> ({{article.contentsVolumeInfo.grossPricePerContentsVolumeText}}</span>
                        <span>{{article.contentsVolumeInfo.contentProLiterText}}</span><span>)</span>
                    </div>
                </div>

                <div class="action-buttons">
                    <wb-button variant="primary"
                               (click)="onClickAddToCart(article, isCompleteWheels)"
                               [disabled]="addToCartButtonDisabled || article?.fit === false || showLoadingSpinner || showPageLoadingSpinner">
                        <wb-icon src="ui/assets/icons/misc/star-filled.svg" *ngIf="wasAddedToCart" class="wb-button__icon"></wb-icon>
                        <wb-icon src="ui/assets/icons/misc/star-outline.svg" *ngIf="!wasAddedToCart" class="wb-button__icon"></wb-icon>
                        <span zkTranslate="GENERAL.ADDTOWISHLIST"></span>
                    </wb-button>

                    <wb-button variant="secondary"
                               (click)="printPDF()"
                               zkTrackClick="pdf-download"
                               zkTrackClickPosition="product"
                               zkTrackClickValue="{{article?.articleId}}">
                        <wb-icon name="bds/download-print/24"></wb-icon>
                        <span zkTranslate="PRODUCTDETAILS.PDFDOWNLOAD"></span>
                    </wb-button>
                </div>

                <!-- Hazard warning for care products - legal requirement to be near CTA button -->
                <div *ngIf="article?.productType === 'CARE_PRODUCT' && article.hazardSymbols && article.hazardSymbols.length > 0"
                     class="pdp-left_pictogram">
                    <img *ngFor="let pictogram of article.hazardSymbols"
                         class="pdp-left_pictogram_icon"
                         alt="hazardSymbols"
                         [src]="pictogram.symbolUrl">
                </div>

                <wb-text size="m" *ngIf="displayErrorMessage"
                        class="is-error pdp-left_feedback"
                        zkTranslate="ERRORMESSAGE.SERVICEUNAVAILABLE">
                </wb-text>
                <wb-text size="m" *ngIf="statusMessage"
                        class="pdp-left_feedback">
                        {{statusMessage}}
                </wb-text>

                <wb-card class="wb-card hydrated compatibility-card"
                         tabindex="-1"
                         interactive
                         (click)="onClickCompatibilityCheck()"
                         zkTrackClick="compatibility-check-start"
                         zkTrackClickPosition="pdp">

                    <wb-card-layout-nba class="wb-card-layout-nba hydrated">
                        <ng-container *ngIf="article?.fit; else checkIfFits">
                            <wb-icon src="ui/assets/icons/misc/ic-car-check-compatible.svg" style="--size: 40px;" class="wb-icon hydrated"></wb-icon>
                            <wb-text size="l" strong class="wb-text hydrated info" tag="div"
                                     zkTranslate="PRODUCTDETAILS.COMPATIBLE_ALT">
                            </wb-text>
                            <wb-text size="m" class="wb-text hydrated info" tag="div">
                                {{ vin }}
                            </wb-text>
                        </ng-container>

                        <ng-template #checkIfFits>
                            <wb-icon src="ui/assets/icons/misc/ic-car-check-suitable.svg" style="--size: 40px;" class="wb-icon hydrated"></wb-icon>
                            <wb-text size="l" strong class="wb-text hydrated headline" tag="div"
                                     zkTranslate="PRODUCTDETAILS.FITSCAR">
                            </wb-text>
                            <wb-text size="m" class="wb-text hydrated info" tag="div">
                                <span zkTranslate="PRODUCTDETAILS.VEHICLECOMPATIBILITYINFO"></span>
                                <span zkTranslate="PRODUCTDETAILS.COMPATIBILITYCHECK"></span>
                            </wb-text>
                        </ng-template>

                    </wb-card-layout-nba>
                </wb-card>

                <div [ngClass]="marketHasShopLink() ? 'cards-wrapper has-two-cards' : 'cards-wrapper'">
                    <zk-shop-link *ngIf="marketHasShopLink()" class="card"></zk-shop-link>
                    <zk-dealer-locator-link class="card"></zk-dealer-locator-link>
                </div>

                <ng-container *ngIf="isMobile">
                    <ng-container *ngTemplateOutlet="accordion"></ng-container>
                </ng-container>
            </div>

            <!-- Right Detail Part -->
            <div class="pdp-right" *ngIf="!isMobile">

                <ng-container *ngTemplateOutlet="gallery"></ng-container>

                <ng-container *ngTemplateOutlet="accordion"></ng-container>
            </div>
      </div>

      <!-- Error Feedback -->
      <ng-template #articleNotFound>
          <div class="wb-grid-container page-wrapper">
              <wb-heading tag="h1" size="l" zkTranslate="PRODUCTDETAILS.DOESNTEXISTHEADLINE"></wb-heading>
              <wb-text size="l" class="spacing-s">
                  <span zkTranslate="PRODUCTDETAILS.DOESNTEXISTINFO"></span>
                  <small>&nbsp;</small>
                  <wb-link (click)="routeToStartOrModelPage()" variant="standalone" zkTranslate="GENERAL.PAGES.STARTPAGE"></wb-link>
              </wb-text>
          </div>
      </ng-template>

    <!-- Gallery template -->
    <ng-template #gallery>
        <div class="gallery" *ngIf="article?.images.length || article?.image">

            <!--   Gallery MQ3+  -->
            <ng-container *ngIf="!isMobile; else mobile">

                <!--   If more than 1 img, show WB gallery  -->
                <wb-gallery *ngIf="article.images?.length > 1" #wbGalleryEl>
                    <wb-gallery-item *ngFor="let image of article.images"
                                     [src]="image.big">
                    </wb-gallery-item>
                </wb-gallery>

                <!--   If only 1 img, show single image,
                       because WB shows thumbs and arrows also with 1 image,
                       which is not wanted  -->
                <img *ngIf="article.images.length === 1"
                     [src]="article.images[0].big"
                     [alt]="article.headline">

            </ng-container>

            <!--   Gallery Mobile (mq1, mq2)  -->
            <ng-template #mobile>

                <wb-gallery *ngIf="article.images?.length > 1" #wbGalleryEl>
                    <wb-gallery-item *ngFor="let image of article.images"
                                     [src]="image.medium">
                    </wb-gallery-item>
                </wb-gallery>

                <img *ngIf="article.images.length === 1"
                     [src]="article.images[0].medium"
                     [alt]="article.headline">

            </ng-template>
        </div>
    </ng-template>

    <!-- Accordion - article details template -->
    <ng-template #accordion>
        <wb-accordion *ngIf="!showPageLoadingSpinner"
                      prevent-mobile-flyout class="pdp-right_details">

            <!--Complete-wheels special -->
            <wb-accordion-item *ngIf="isCompleteWheels"
                               zkTrackClick="article_info"
                               zkTrackClickPosition="pdp"
                               zkTrackClickLabel="complete-wheels"
                               zkTrackClickValue="{{article?.articleNumber}}"
                               open>
                <span slot="header"><span zkTranslate="PRODUCTDETAILS.COMPLETE_WHEELS"></span></span>
                <span slot="mobile-header">Close</span>
                <div slot="content">
                    <wb-button variant="secondary"
                               class="pdp-left_btn"
                               [disabled]="addToCartButtonDisabled || article?.fit === false"
                               (click)="onClickAddToCart(article, isCompleteWheels)">
                        <span zkTranslate="WHEELS.TOWISHLIST"></span>
                    </wb-button>

                    <zk-complete-wheels-info [completeWheelData]="article"></zk-complete-wheels-info>
                </div>
            </wb-accordion-item>

            <wb-accordion-item *ngIf="article?.description"
                               zkTrackClick="article_info"
                               zkTrackClickPosition="pdp"
                               zkTrackClickLabel="description"
                               zkTrackClickValue="{{article?.articleNumber}}"
                               #accordionDescription>
                <span slot="header"><span zkTranslate="PRODUCTDETAILS.DESCRIPTION"></span></span>
                <span slot="mobile-header">Close</span>
                <div slot="content">
                    <wb-text size="l" [innerHtml]="article.description"></wb-text>

                    <wb-text size="l">

                        <!-- Show hazard warning pictograms only for Care Products -->
                        <div *ngIf="article?.productType === 'CARE_PRODUCT' && article.hazardWarnings && article.hazardWarnings.length > 0"
                             class="pdp-right_pictograms">
                            <wb-text size="l" strong *ngFor="let headline of article?.hazardHeadlines" class="pdp-right_pictograms_headline">
                                {{headline + "! "}}
                            </wb-text>
                            <wb-text size="l" *ngFor="let warning of article.hazardWarnings">{{warning.designation}}</wb-text>
                            <div *ngIf="article.hazardSymbols && article.hazardSymbols.length > 0"
                                 class="pdp-right_pictograms_list">
                                <img *ngFor="let pictogram of article.hazardSymbols"
                                     class="pdp-right_pictograms_list_icon"
                                     alt="hazardSymbols"
                                     [src]="pictogram.symbolUrl">
                            </div>
                            <wb-text size="l" class="pdp-right_pictograms_info" zkTranslate="PRODUCTDETAILS.PICTOGRAMHINT"></wb-text>
                        </div>

                        <!-- Safety infos for care products -->
                        <ul *ngIf="article?.productType === 'CARE_PRODUCT' && article?.noticeLinks"
                            class="pdp-right_notice-links">
                            <li *ngFor="let link of article.noticeLinks; let i = index">
                                <a href="{{link}}"
                                   target="_blank"
                                   rel="noopener"
                                   class="ui-standalone-link">
                                    <wb-icon slot="icon" class="icon" name="bds/chevron-right/16"></wb-icon>
                                    <!-- Show the index only if there is more than one link -->
                                    <wb-text tag="span" size="l"
                                             [zkTranslate]="'PRODUCTDETAILS.SAFETYDATASHEET'">
                                    </wb-text>
                                    <wb-text tag="span" size="l" *ngIf="article.noticeLinks.length > 1"> {{i + 1}}</wb-text>
                                </a>
                            </li>
                        </ul>
                    </wb-text>
                </div>
            </wb-accordion-item>

            <wb-accordion-item *ngIf="article?.articleNumber || article?.propertiesCollection || article?.dimensions"
                               zkTrackClick="article_info"
                               zkTrackClickPosition="pdp"
                               zkTrackClickLabel="more-information"
                               zkTrackClickValue="{{article?.articleNumber}}">
                <span slot="header"><span zkTranslate="PRODUCTDETAILS.FURTHER_INFO"></span></span>
                <span slot="mobile-header">Close</span>
                <div slot="content">
                    <div class="more-info" *ngIf="article?.articleNumber">
                        <wb-text size="l">
                            <span zkTranslate="PRODUCTDETAILS.ARTICLENUMBER"></span>:
                        </wb-text>
                        <wb-text size="l">{{article.articleNumber}}</wb-text>
                    </div>
                    <div class="more-info" *ngIf="article?.propertiesCollection?.materials && article.propertiesCollection.materials.length > 0">
                        <wb-text size="l">
                            <span zkTranslate="PRODUCTDETAILS.MATERIAL"></span>:
                        </wb-text>
                        <wb-text size="l">{{article.propertiesCollection.materials.join(', ')}}</wb-text>
                    </div>
                    <div class="more-info" *ngIf="article?.propertiesCollection?.colors && article.propertiesCollection.colors.length > 0">
                        <wb-text size="l">
                            <span zkTranslate="PRODUCTDETAILS.COLOUR"></span>:
                        </wb-text>
                        <wb-text size="l">{{article.propertiesCollection.colors.join(', ')}}</wb-text>
                    </div>
                    <div class="more-info" *ngIf="article?.dimensions?.item.weight">
                        <wb-text size="l">
                            <span zkTranslate="PRODUCT.PROP.WEIGHT"></span>:
                        </wb-text>
                        <wb-text size="l">{{article.dimensions.item.weight}}</wb-text>
                    </div>
                    <div class="more-info"
                         *ngIf="article?.dimensions?.item.length && article?.dimensions?.item.width && article?.dimensions?.item.height">
                        <wb-text size="l">
                            <span zkTranslate="PRODUCT.PROP.DIMENSIONS"></span>:
                        </wb-text>
                        <wb-text size="l">{{ article.dimensions.item.length }} / {{ article.dimensions.item.width }} / {{ article.dimensions.item.height }}</wb-text>
                    </div>
                    <div class="more-info"
                         *ngIf="article?.dimensions?.shipping?.length && article?.dimensions?.shipping?.width && article?.dimensions?.shipping?.height">
                        <wb-text size="l">
                            <span zkTranslate="PRODUCT.PROP.DIMENSIONS"></span>:
                        </wb-text>
                        <wb-text size="l">{{ article.dimensions.shipping.length }} / {{ article.dimensions.shipping.width }} / {{ article.dimensions.shipping.height }}</wb-text>
                    </div>
                </div>
            </wb-accordion-item>
        </wb-accordion>
    </ng-template>
</article>

<section *ngIf="recommendedArticles?.length > 0" class="page-wrapper recent_articles">
    <div class="wb-grid-container">
        <wb-heading size="m" zkTranslate="PRODUCTDETAILS.CAROUSELHEADLINE"></wb-heading>
        <wb-slider class="recent_articles_slider"
                   scroll-snap grid-gutter show-dot-navigation show-arrow-navigation>
            <wb-slider-item *ngFor="let article of recommendedArticles" mq1="6" mq3="4" mq5="3">
                <zk-product-teaser-card [article]="article"
                                        [isCollection]="false"
                                        [limitWidth]="true"
                                        zkTrackClick="highlights_click"
                                        zkTrackClickPosition="pdp">
                </zk-product-teaser-card>
            </wb-slider-item>
        </wb-slider>
    </div>
</section>
