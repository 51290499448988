class MediaQueryChangeEvent {
  constructor(current, previous) {
    this.current = current;
    this.previous = previous;
  }
}
const MEDIA_QUERY_SIZES = {
  mq1: 320,
  mq2: 480,
  mq3: 768,
  mq4: 1024,
  mq5: 1280,
  mq6: 1440
};
const WB_RESIZE_EVENT_NAME = 'wbresize';
// note: mq1 is implicit
const MEDIA_QUERIES_TO_OBSERVE = ['mq2', 'mq3', 'mq4', 'mq5', 'mq6'];
class MediaQueryListener {
  constructor(window, mq, callbackFn) {
    this.window = window;
    this.mq = mq;
    this.callbackFn = callbackFn;
    const mediaQueryList = this.window.matchMedia(`(min-width: ${MEDIA_QUERY_SIZES[mq]}px)`);
    const eventListenerFn = event => this.callbackFn(this.mq, event.matches);
    mediaQueryList.addListener(eventListenerFn);
    this.remove = () => mediaQueryList.removeListener(eventListenerFn);
  }
}
/**
 * @name MediaQueryService
 *
 * @description Class that informs about current media query
 *
 * @example
 * import { MediaQueryService } from '@workbench/core';
 *
 * const myMediaQueryService = new MediaQueryService(window);
 *
 * // to get the current media query
 * const currentMediaQuery: MediaQuery = myMediaQueryService.getCurrentMediaQuery();
 *
 * // to observe media query changes across time
 * window.addEventListener('wbresize', ((e: CustomEvent<MediaQueryChangeEvent>) => {
 *   console.log(`Media query changed from ${e.detail.previous} to ${e.detail.current}`);
 * }) as EventListener);
 *
 * // pass in `true` if you are interested in the media query in the moment of calling the function
 * myMediaQueryService.observe(true);
 *
 * // when you don't need to observe anymore
 * myMediaQueryService.disconnect();
 */
class MediaQueryService {
  constructor(window) {
    this.window = window;
    this.listeners = [];
    /**
     * determines if a change is new and unique and should be emitted
     */
    this.onMediaQueryChange = (mq, matches) => {
      if (matches) {
        this.activeMediaQuery = mq;
      } else {
        const previousMediaQuery = this.activeMediaQuery;
        this.activeMediaQuery = this.getCurrentMediaQuery();
        if (previousMediaQuery === this.activeMediaQuery) {
          // note: not emitting duplicates
          return;
        }
      }
      this.emit();
    };
  }
  /**
   * returns the currently active media query
   */
  getCurrentMediaQuery() {
    // note: using for loop to immediately return when a first match was found
    for (let i = MEDIA_QUERIES_TO_OBSERVE.length - 1; i >= 0; i--) {
      if (this.window.matchMedia(`(min-width: ${MEDIA_QUERY_SIZES[MEDIA_QUERIES_TO_OBSERVE[i]]}px)`).matches) {
        return MEDIA_QUERIES_TO_OBSERVE[i];
      }
    }
    return 'mq1';
  }
  /**
   * starts observing all media query changes and dispatches `wbresize` events on `window`
   * @param emitCurrentValue set to true if you are interested in the media query in the moment of calling the function
   */
  observe(emitCurrentValue = false) {
    if (emitCurrentValue) {
      this.activeMediaQuery = this.activeMediaQuery || this.getCurrentMediaQuery();
      this.emit();
    }
    MEDIA_QUERIES_TO_OBSERVE.filter(mq => !this.listeners.find(listener => listener.mq === mq)) //
    .forEach(mq => this.listeners.push(new MediaQueryListener(this.window, mq, this.onMediaQueryChange)));
  }
  /**
   * cleans up all remaining event listeners
   */
  disconnect() {
    this.listeners.forEach(listener => listener.remove());
    this.listeners = [];
  }
  /**
   * emits the wbresize event on window as a custom event
   */
  emit() {
    this.window.dispatchEvent(new CustomEvent(WB_RESIZE_EVENT_NAME, {
      detail: new MediaQueryChangeEvent(this.activeMediaQuery, this.previousMediaQuery)
    }));
    // note: active media query becomes previous media query after next media query change
    this.previousMediaQuery = this.activeMediaQuery;
  }
}
export { MediaQueryService as M, WB_RESIZE_EVENT_NAME as W, MediaQueryChangeEvent as a, MEDIA_QUERY_SIZES as b };

