// ng
import { Component, Input, OnInit } from '@angular/core';
import { Subscription} from 'rxjs';
// services
import { AppService } from '../../../app.service';
import { TranslationService } from "@shared/shared-services/translate/translation.service";
import { SelectedCarService } from "@shared/shared-services/selected-car/selected-car.service";
import { SearchService } from "@shared/header/header-base/search.service";
import { ProductDetailsService } from 'app/assortment/accessories/pages/product-details-page/product-details.service';
import { TeaserService } from '@shared/shared-services/teaser/teaser.service';
// model
import { Article } from '@shared/global-models/article/article.model';
import { MODE } from '@shared/global-models/mode.enum';
import { SearchResult } from "@shared/global-models/search-result.model";

@Component({
    selector: 'zk-search-not-found-page',
    templateUrl: './search-not-found-page.component.html',
    styleUrls: ['./search-not-found-page.component.scss']
})
export class SearchNotFoundPageComponent implements OnInit {
    @Input()
    set term (val: string) {
        this._term = val;
        this.updateHeadline();
    }

    get term() {
        return this._term;
    }

    @Input()
    searchResult: SearchResult;

    @Input()
    isError: boolean;

    headline = '';
    recommendedArticles: Article[];
    isAccessoriesMode = true;

    private _term: string;

    constructor(private _appService: AppService,
				private _productDetailsService: ProductDetailsService,
                private _teaserService: TeaserService,
                private _translationService: TranslationService,
                private _selectedCarService: SelectedCarService,
                private _searchService: SearchService) {

        this.isAccessoriesMode = this._appService.appMode === MODE.ACCESSORIES;
    }

    ngOnInit(): void {
        // endpoint expects vehicle type / car line ID - needs refactoring if wanted for collection mode
        if (this.isAccessoriesMode) {
            this.loadArticlesForTeaserCarousel();
        }
    }

    /**
     * Remove logged vehicle and start a search with same search query.
     */
    removeVehicle() {
        this._selectedCarService.resetLoggedCarData();
        window.location.reload();
    }

    /**
     * Redirects to the search results page of the respective other mode.
     */
    searchInOtherMode() {
        this._searchService.redirectToOtherSearchPage(this.isAccessoriesMode, this._term)
    }

    private updateHeadline() {
        if (this.isAccessoriesMode) {
            this.headline = this._translationService.translate('SEARCH.NO_RESULTS_ACC') + ' "' + this._term + '"';
            if (this._selectedCarService?.carLine) {
                this.headline += ' ' + this._translationService.translate('SEARCH.FOR') + ' ' + this._selectedCarService.getSelectedCarName();
            }
        } else {
            this.headline = this._translationService.translate('SEARCH.NO_RESULTS_COL') + ' "' + this._term + '" ' + this._translationService.translate('SEARCH.IN') + ' ' + this._translationService.translate('HEADER.COLLECTION_MODE');
        }
    }

    private loadArticlesForTeaserCarousel() {
        const sub: Subscription = this._teaserService.getAllHighlightsArticles(null).subscribe(
            (value: Article[]) => {
                sub.unsubscribe();
                value.forEach((x) => this._productDetailsService.addContentVolumeInfoTranslation(x));
                this.recommendedArticles = value;
            },
            () => {
                sub.unsubscribe();
            }
        );
    }
}
