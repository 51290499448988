<section id="zk-product-overview"
         class="category-list">

    <wb-heading *ngIf="showDefaultHeadline" tag="h3" size="m" zkTranslate="GENERAL.ALLCATEGORIES"></wb-heading>

    <div class="categories spacing-s">
        <div class="category-item" *ngFor="let category of categories">
            <div class="category-link"
                 (click)="routeToCategory(category.url)">
                <svg class="category-icon"><use [attr.href]="'assets/icons/icons.svg#' + category.iconName"></use></svg>
                <wb-heading size="m" class="category-title">{{ category.title }}</wb-heading>
                <wb-text size="l" class="category-sub-title">{{ category.subTitle }}</wb-text>
            </div>
        </div>
    </div>
</section>
