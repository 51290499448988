// ng
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
// service
import { SelectedCarService } from '@shared/shared-services/selected-car/selected-car.service';
import { TranslationService } from '@shared/shared-services/translate/translation.service';
import { GroupMapperService } from '@shared/shared-services/group-mapper/group-mapper.service';
// model
import { ProductGroup } from '@shared/global-models/productGroup.model';
import { MODE, SUBMODE } from '@shared/global-models/mode.enum';

@Injectable()
export class ProductGroupsService {
    appMode: MODE;
    appSubMode: SUBMODE;
    groups: ProductGroup[] = [];

    get productGroups() {
        return this.groups;
    }

    private _groupsChangedInfo = new Subject<ProductGroup[]>();

    constructor(
        private _httpClient: HttpClient,
        private _translationService: TranslationService,
        private _selectedCarService: SelectedCarService,
        private _groupMapperService: GroupMapperService
    ) {
        // react to internal vehicle type car change/removal - a new car requires new product groups
        this._selectedCarService.carLineSubscriber.subscribe(() => {
            this.getAllProductGroups().subscribe();
        });

        // react to global vehicle type (assortment) change - a new vehicle type requires new product groups
        this._selectedCarService.vehicleTypeChangedInfo.subscribe(() => {
            this.getAllProductGroups().subscribe();
        });
    }

    /**
     * Subscribe to get informed when groups changed due to vehicle type or car line change
     */
    get groupsChangedInfo() {
        return this._groupsChangedInfo.asObservable();
    }

    /**
     * Fetches complete groups structure for accessories or collection mode.
     * CarLine or vehicle type specific.
     * @returns {Observable<ProductGroup[]>}
     */
    getAllProductGroups(): Observable<ProductGroup[]> {
        let mode = 'car'; // default mode
        const vehicleType = this._selectedCarService?.vehicleType?.vehicleTypeId;
        const carLineId = this._selectedCarService?.carLine?.carLineId;
        const modelDesignId = this._selectedCarService?.modelDesign?.modelDesignId;

        if (this.appMode === MODE.LIFESTYLE) {
            mode = SUBMODE.COLLECTION;
        } else if (this.appSubMode === SUBMODE.AMG && carLineId && modelDesignId) {
            mode = `${vehicleType}/${carLineId}/${modelDesignId}`;
        } else if (carLineId) {
            mode = carLineId;
        } else if (vehicleType)  {
            mode = vehicleType;
        }

        return this._httpClient.get<ProductGroup[]>('api/groups/all/' + this._translationService.currentLang + '/' + mode).pipe(
            map((response: ProductGroup[]) => {
                // add collection icons ref
                if (mode === SUBMODE.COLLECTION) {
                    this.addMainCategoryIcons(response);
                }

                // update product groups and inform
                this.groups = response;
                this._groupMapperService.groupMaps = response;
                this._groupsChangedInfo.next(response);

                return response;
            })
        );
    }

    private addMainCategoryIcons(groups: ProductGroup[]): void {
        for (const mainGroup of groups) {
            mainGroup.iconReference = this._groupMapperService.groupIdIconMap.get(mainGroup.groupId);
        }
    }
}
