<div class="product-list page-wrapper no-top-padding">
    <zk-subnavigation [showBackButton]="false"
                      [activeProductGroup]="activeProductGroup"
                      (clicked)="scrollToCategory($event)">
    </zk-subnavigation>

    <zk-error-message *ngIf="errorCode" [errorCode]="errorCode"></zk-error-message>

    <!-- Sub Categories -->
    <section *ngFor="let singleCategory of productSubGroups" class="spacing-m">
        <wb-spinner *ngIf="isLoading" class="loading-spinner" theme="light"></wb-spinner>

        <wb-heading size="l" [id]="singleCategory.urlName" class="word-break scroll-margin">
            {{singleCategory.name}}
        </wb-heading>

        <zk-product-teaser-cards class="spacing-xs">
            <zk-product-teaser-card *ngFor="let article of singleCategory.articles"
                                    [article]="article"
                                    [isCollection]="false">
            </zk-product-teaser-card>
        </zk-product-teaser-cards>

        <zk-typo-teaser *ngIf="singleCategory.articleInfo.more" class="spacing-xs"
                        (click)="routeToSubcategoryPage(singleCategory.urlName)">
            <wb-heading size="m">
                {{typoTeaserText + ' ' + singleCategory.name}}
            </wb-heading>
        </zk-typo-teaser>
    </section>
</div>
