import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'mba-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
    /**
     * Event that is emitted when a click on the button instance happens.
     * Note: Use this instead of the (click) listener on the component wrapper itself to
     * ensure that a button is interactive depending on its 'disabled' property.
     */
    @Output() clicked: EventEmitter<Event> = new EventEmitter<Event>();

    /**
     * Name of the SVG icon inside your applications sprite sheet file with name 'icons.svg'.
     * Default used icon is 'arrow-right-default'.
     * Pass 'none' if no icon should be rendered.
     */
    @Input() icon: string = 'arrow-right-default';

    /**
     * Different appearance via CSS class. Uses 'is-light'
     */
    @Input() darkPictures: boolean = false;

    /**
     * Different appearance via CSS class. Uses 'is-dark'
     */
    @Input() darkBackground: boolean = false;

    /**
     * Different appearance via CSS class. Uses 'is-darker'
     */
    @Input() darkerBackground: boolean = false;

    /**
     * Transparent appearance via CSS class. Uses 'is-link'
     */
    @Input() isLink: boolean = false;

    /**
     * Transparent appearance via CSS class. Uses 'is-link_light'
     */
    @Input() isLinkLight: boolean = false;

    /**
     * Transparent appearance via CSS class. Uses 'is-link_red': Text and icon are red. Hover is cyan.
     */
    @Input() isLinkRed: boolean = false;

    /**
     * Defines the disabled property. Uses CSS class 'is-disabled'.
     * If true stops the event propagation of the native event.
     * If false allows the event propagation of the native event and additionally emits the (clicked) output event.
     * Default is false.
     */
    @Input() disabled: boolean = false;

    /**
     * Positions the icon on the right side inside the button
     */
    @Input() iconRight: boolean = false;

    /**
     * Defines if the icon on MQ1 should be shown.
     * Default is false according to workbench style guide.
     */
    @Input() hasIconOnMobile: boolean = false;

    /**
     * Shows only the icon as button.
     */
    @Input() hasIconOnly: boolean = false;

    /**
     * Set button as selected. F.e. in multiselect component
     */
    @Input() isActive: boolean = false;

    /**
     * Uses Css-class 'is-fullsize'. F.e. to use it in display:flex in multiselect component
     */
    @Input() isFullSize: boolean = false;

    /**
     * Defines if the button link has an error message according to the validation
     */
    @Input() hasError: boolean = false;

    /**
     * Optional: Defines if "loading spinner" icon is shown in the button. It replaces the displayed icon or adds it if no icon is present.
     * Useful to indicate loading processes. Default is false.
     */
    @Input() isLoading: boolean = false;

    /**
     * Optional: Defines if icon is displayed bigger then the default size. Default is false.
     */
    @Input() hasBiggerIcon: boolean = false;

    constructor() {}

    ngOnInit() {}

    /**
     * Prevents event propagation if button is actually disabled.
     * Emits Output event if button is enabled.
     * @param e - Mouse Event
     */
    clickHandler(e: Event) {
        if (this.disabled) {
            e.preventDefault();
            e.stopImmediatePropagation();
        } else {
            this.clicked.emit(e);
        }
    }
}
