<div class="wheel-info">
    <wb-icon name="bds/close/24" style="--size: 34px" class="wheel-info_close" (click)="clickClose()"></wb-icon>

    <div *ngIf="wheel"
         class="wheel-info_details">

        <div class="wheel-info_details_rim-info">
            <div *ngIf="marketData.showPrice"
                 class="headline">
                <wb-text tag="span" size="l" zkTranslate="WHEELS.COMPLETEPRICENOTE"></wb-text>
                <wb-text tag="span" size="l" class="setPrice">{{wheel.setGrossPriceText}}</wb-text>
            </div>

            <div class="wheel-info_details_rim-info_details">
                <div class="rim-details">
                    <div>
                        <wb-text size="l" zkTranslate="WHEELS.FRONTAXLE"></wb-text>
                        <wb-text size="l">&nbsp;{{wheel.leftFront.wheel.rimDimensionsText}}</wb-text>
                    </div>
                    <div>
                        <wb-text size="l" zkTranslate="WHEELS.WHEELSIZEFRONT"></wb-text>
                        <wb-text size="l">&nbsp;{{wheel.leftFront.wheel.rimDiameterText}}</wb-text>
                    </div>
                    <div>
                        <wb-text size="l" zkTranslate="PRODUCTDETAILS.ARTICLENUMBER"></wb-text>:
                        <wb-text size="l">{{wheel.articleNumber}}</wb-text>
                    </div>
                    <div *ngIf="marketData.showPrice">
                        <wb-text size="l" zkTranslate="WHEELS.SINGLEPRICEFRONT"></wb-text>
                        <wb-text size="l">&nbsp;{{wheel.leftFront.grossPriceText}}</wb-text>
                    </div>
                </div>

                <div class="rim-details">
                    <div>
                        <wb-text size="l" zkTranslate="WHEELS.REARAXLE"></wb-text>
                        <wb-text size="l">&nbsp;{{wheel.leftRear.wheel.rimDimensionsText}}</wb-text>
                    </div>
                    <div>
                        <wb-text size="l" zkTranslate="WHEELS.WHEELSIZEREAR"></wb-text>
                        <wb-text size="l">&nbsp;{{wheel.leftRear.wheel.rimDiameterText}}</wb-text>
                    </div>
                    <div>
                        <wb-text size="l" zkTranslate="PRODUCTDETAILS.ARTICLENUMBER"></wb-text>:
                        <wb-text size="l">{{wheel.leftRear.articleNumber}}</wb-text>
                    </div>

                    <div *ngIf="marketData.showPrice">
                        <wb-text size="l" zkTranslate="WHEELS.SINGLEPRICEREAR"></wb-text>
                        <wb-text size="l">&nbsp;{{wheel.leftRear.grossPriceText}}</wb-text>
                    </div>
                </div>

                <div class="wheel-info_details_buttons">
                    <span class="button"
                          *ngIf="!compatibilityChecked"
                          (click)="clickCompatibilityCheck()"
                          zkTrackClick="compatibility-check-start"
                          zkTrackClickPosition="wheels-special-detail">
                          <wb-icon class="icon" name="car" style="--size: 22px"></wb-icon>
                          <wb-text class="text" tag="span" size="l">
                              <span zkTranslate="PRODUCTDETAILS.FITSCAR"></span>
                          </wb-text>
                    </span>

                    <!-- CC Feedback replaces CC button -->
                    <wb-tag *ngIf="compatibilityChecked" zkTranslate="PRODUCTDETAILS.COMPATIBLE"></wb-tag>

                    <span class="button"
                          (click)="printPDF()"
                          zkTrackClick="pdf-download"
                          zkTrackClickPosition="wheels-special-detail"
                          zkTrackClickValue="Front axle: {{wheel.articleNumber}}, Rear axle: {{wheel.leftRear.articleNumber}}">
                          <wb-icon class="icon" name="bds/download-print/24" style="--size: 24px"></wb-icon>
                          <wb-text class="text" tag="span" size="l">
                              <span zkTranslate="PRODUCTDETAILS.PDFDOWNLOAD"></span>
                          </wb-text>
                    </span>
                </div>
            </div>
        </div>
    </div>

    <div class="wheel-info_description">
        <wb-text size="l" strong class="block" zkTranslate="WHEELS.AVAILABLEWITHTIRES"></wb-text>
        <wb-text size="l" zkTranslate="WHEELS.AVAILABLEWITHTIRESDESCRIPTION"></wb-text>

        <wb-text size="l" strong class="wheel-info_description block" zkTranslate="WHEELS.FULLSETNOTE"></wb-text>
        <wb-text size="l" zkTranslate="WHEELS.FULLSETNOTETEXTWISHLIST"></wb-text>
    </div>
</div>
