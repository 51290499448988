// ng
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

// model
import { ProductGroup } from "@shared/global-models/productGroup.model";
import { MODE } from '@shared/global-models/mode.enum';

// services
import { SelectedCarService } from '@shared/shared-services/selected-car/selected-car.service';
import { ProductGroupsService } from '@shared/shared-services/product-groups/product-groups.service';
import { TranslationService } from '@shared/shared-services/translate/translation.service';
import { AppService } from '../../../../app.service';

@Component({
    selector: 'zk-product-categories',
    templateUrl: './product-categories.component.html',
    styleUrls: ['./product-categories.component.scss']
})
export class ProductCategoriesComponent implements OnInit, OnDestroy {
    productGroups: ProductGroup[] = [];
    baseUrl: string;

    private _groupsSub: Subscription = new Subscription();

    constructor(private _selectedCarService: SelectedCarService,
                private _productGroupsService: ProductGroupsService,
                private _translationService: TranslationService,
                private _appService: AppService) {}

    ngOnInit() {
        this.productGroups = this._productGroupsService.groups;
        this._groupsSub = this._productGroupsService.groupsChangedInfo.subscribe(
            (newGroups: ProductGroup[]) => {
                this.productGroups = newGroups;
                this.setUrlBase();
            }
        );

        this.setUrlBase();
    }

    ngOnDestroy(): void {
        this._groupsSub.unsubscribe();
    }

    get isAccessoriesMode() : boolean {
        return this._appService.appMode === MODE.ACCESSORIES;
    }

    private setUrlBase() {
        const currentLanguage= this._translationService.currentLang;
        const appMode= this._appService.appMode;
        const appSubMode= this._appService.appSubMode;

        this.baseUrl = `${currentLanguage}${appMode}${appSubMode}`;

        if (!this._selectedCarService.carClass || !this._selectedCarService.carLine || !this.isAccessoriesMode) {
            this.baseUrl += '/products/';
        } else {
            const selectedCar: string = this._selectedCarService.carLine.urlName;
            this.baseUrl += '/' + selectedCar + '/';
        }
    }
}
