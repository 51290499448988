import { Component, OnInit } from '@angular/core';
import { CarChooserService } from "@shared/components/car-chooser/car-chooser.service";
import { SelectedCarService } from "@shared/shared-services/selected-car/selected-car.service";

@Component({
    selector: 'zk-accessories',
    templateUrl: './accessories.component.html',
    styleUrls: ['./accessories.component.scss']
})
export class AccessoriesComponent implements OnInit {

    dataLoaded = false;

    constructor(
        private _carChooserService: CarChooserService,
        private _selectedCarService: SelectedCarService
    ) {}


    ngOnInit(): void {

        this.dataLoaded = !!this._selectedCarService.vehicleTypes;

        // If user came from SplashPage, we need to load Vehicle data
        // Otherwise those data will be preloaded in loadRequiredData method
        if (!this._selectedCarService.vehicleTypes) {
            this._carChooserService.getVehicleTypesAndClasses().subscribe(() => {
                this.dataLoaded = true;
            });
        }
    }
}
