// Ng
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// Services
import { CampaignService } from '@shared/components/campaign/campaign.service';

// Modules
import { TranslateModule } from '@shared/shared-services/translate/translate.module';
import { MultiselectModule } from "@shared/components/multiselect/multiselect.module";

// Directives
import { TrackClickDirective } from '@shared/shared-services/google-analytics/track-click.directive';
import { PaginationDirective } from '@shared/directives/pagination/pagination.directive';
import { ImgLoadedDirective } from '@shared/directives/pagination/ImgLoaded.directive';
import { BodyRefDirective, FooterRefDirective, HeaderRefDirective } from '@shared/components/campaign/template-reference.directive';
import { GetMaxHeightDirective } from '@shared/directives/get-max-height/get-max-height.directive';

// Accessories components
import { CartItemComponent } from '@shared/components/cart-item/cart-item.component';
import { CategoryTeaserMediumComponent } from '@shared/components/product-teasers/category-teaser-medium/category-teaser-medium.component';
import { ProductCategoriesComponent } from '@shared/components/product-teasers/product-categories/product-categories.component';
import { ProductOverviewComponent } from '@shared/components/product-teasers/product-overview/product-overview.component';
import { ErrorMessageComponent } from '@shared/components/error-message/error-message.component';
import { WishlistComponent } from './wishlist/wishlist.component';
import { ProductTeaserLargeComponent } from './product-teasers/product-teaser-large/product-teaser-large.component';
import { CarTeaserComponent } from './car-teaser/car-teaser.component';
import { VinLockComponent } from "@shared/components/car-chooser/vin-lock/vin-lock.component";

// Collection components
import { ProductTeaserCardsComponent } from './product-teasers/product-teaser-cards/product-teaser-cards.component';
import { ProductTeaserCardComponent } from './product-teasers/product-teaser-cards/product-teaser-card/product-teaser-card.component';
import { SubnavigationComponent } from './subnavigation/subnavigation.component';
import { CollectionCategoriesWithProductsListComponent } from './collection-categories-with-products-list/collection-categories-with-products-list.component';
import { CollectionProductsListComponent } from './collection-products-list/collection-products-list.component';
import { SingleImageStageComponent } from './single-image-stage/single-image-stage.component';
import { CollectionCategoryTeaserComponent } from './collection-category-teaser/collection-category-teaser.component';
import { NotificationComponent } from './notification/notification.component';
import { TypoTeaserComponent } from './typo-teaser/typo-teaser.component'

// Campaign components
import { StageComponent } from './campaign/stage/stage.component';
import { StageSeparatorComponent } from './campaign/stage-separator/stage-separator.component';
import { TeaserLargeComponent } from './campaign/teaser-large/teaser-large.component';
import { ProductTeaserBigComponent } from './campaign/product-teaser-big/product-teaser-big.component';
import { TextTeaserComponent } from './campaign/text-teaser/text-teaser.component';
import { TeaserMediumComponent } from './campaign/teaser-medium/teaser-medium.component';
import { TeaserItemComponent } from '@shared/components/campaign/teaser-item/teaser-item.component';
import { ProductTeaserCarouselComponent } from './campaign/product-teaser-carousel/product-teaser-carousel.component';
import { ProductTeaserMediumCampaign } from '@shared/components/campaign/product-teaser-medium/product-teaser-medium-campaign.component';
import { ProductTeaserBigWithPriceComponent } from './product-teasers/product-teaser-big-with-price/product-teaser-big-with-price.component';

// Accessories & Collection components
import { ShopLinkComponent } from '@shared/components/shop-link/shop-link.component';
import { TeaserBannerComponent } from './teaser-banner/teaser-banner.component';
import { DealerLocatorLinkComponent } from '@shared/components/dealer-locator-link/dealer-locator-link.component';
import { CarChooserTeaserComponent } from "@shared/components/car-chooser/car-chooser-teaser/car-chooser-teaser.component";
import { CarChooserComponent } from "@shared/components/car-chooser/car-chooser/car-chooser.component";
import { VehicleTypeSwitchComponent } from "@shared/components/car-chooser/vehicle-type-switch/vehicle-type-switch.component";
import { HighlightsTeaserComponent } from "@shared/components/highlights-teaser/highlights-teaser.component";


@NgModule({
    declarations: [
        TrackClickDirective,
        PaginationDirective,
		GetMaxHeightDirective,
        CartItemComponent,
        CategoryTeaserMediumComponent,
        ProductCategoriesComponent,
        ProductOverviewComponent,
        ErrorMessageComponent,
        SubnavigationComponent,
        ProductTeaserCardsComponent,
        ProductTeaserCardComponent,
        CollectionCategoriesWithProductsListComponent,
        CollectionProductsListComponent,
        SingleImageStageComponent,
        CollectionCategoryTeaserComponent,
        NotificationComponent,
        ImgLoadedDirective,
        StageComponent,
        StageSeparatorComponent,
        TeaserLargeComponent,
        ProductTeaserBigComponent,
        TextTeaserComponent,
        TeaserMediumComponent,
        ProductTeaserMediumCampaign,
        TeaserItemComponent,
        ProductTeaserCarouselComponent,
        HeaderRefDirective,
        BodyRefDirective,
        FooterRefDirective,
        WishlistComponent,
        ProductTeaserBigWithPriceComponent,
        ShopLinkComponent,
        TeaserBannerComponent,
        DealerLocatorLinkComponent,
        ProductTeaserLargeComponent,
        CarTeaserComponent,
        CarChooserTeaserComponent,
        CarChooserComponent,
        VehicleTypeSwitchComponent,
        VinLockComponent,
        TypoTeaserComponent,
        HighlightsTeaserComponent
    ],
	imports: [
		CommonModule,
		FormsModule,
		RouterModule,
		TranslateModule,
        MultiselectModule
	],
    exports: [
        TrackClickDirective,
        PaginationDirective,
		GetMaxHeightDirective,
        TranslateModule,
        CartItemComponent,
        CategoryTeaserMediumComponent,
        ProductCategoriesComponent,
        ProductOverviewComponent,
        ErrorMessageComponent,
        SubnavigationComponent,
        ProductTeaserCardsComponent,
        ProductTeaserCardComponent,
        CollectionCategoriesWithProductsListComponent,
        CollectionProductsListComponent,
        SingleImageStageComponent,
        CollectionCategoryTeaserComponent,
        NotificationComponent,
        ImgLoadedDirective,
        StageComponent,
        StageSeparatorComponent,
        TeaserLargeComponent,
        ProductTeaserBigComponent,
        TextTeaserComponent,
        TeaserMediumComponent,
        ProductTeaserMediumCampaign,
        ProductTeaserCarouselComponent,
        TeaserItemComponent,
        HeaderRefDirective,
        BodyRefDirective,
        FooterRefDirective,
        WishlistComponent,
        ProductTeaserBigWithPriceComponent,
        ShopLinkComponent,
        TeaserBannerComponent,
        DealerLocatorLinkComponent,
        ProductTeaserLargeComponent,
        CarTeaserComponent,
        CarChooserTeaserComponent,
        CarChooserComponent,
        VehicleTypeSwitchComponent,
        TypoTeaserComponent,
        HighlightsTeaserComponent
    ],
	providers: [
		CampaignService
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ComponentsModule {
}
