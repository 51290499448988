// ng
import { Directive, ElementRef, Input, OnChanges, OnInit, Renderer2, SimpleChanges } from '@angular/core';
// own
import { TranslationService } from '@shared/shared-services/translate/translation.service';

/**
 * @class Translate Directive
 * @description Takes the key and attaches a text element to the element as a child
 *
 * @example <span zkTranslate="TRANSLATION_KEY"></span>
 */
@Directive({ selector: '[zkTranslate]' })
export class TranslateDirective implements OnInit, OnChanges {
    @Input()
    zkTranslate: string;

    constructor(
        private currentElement: ElementRef,
        private translateService: TranslationService,
        private renderer: Renderer2
    ) {}

    ngOnInit() {
        this.translateKey();
    }

    ngOnChanges() {
        this.translateKey();
    }

    private translateKey() {
        const translation = this.translateService.translate(this.zkTranslate);
        if (translation) {
            const currentNative: any = this.currentElement.nativeElement;
            if (currentNative) {
                // Lets just remove all other text nodes
                while (currentNative.firstChild) {
                    currentNative.removeChild(currentNative.firstChild);
                }

                const createdChild: any = this.renderer.createText(translation);
                this.renderer.appendChild(currentNative, createdChild);
            }
        }
    }
}
