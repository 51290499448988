// ng
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
// service
import { SelectedCarService } from '@shared/shared-services/selected-car/selected-car.service';
import { ProductGroupsService } from '@shared/shared-services/product-groups/product-groups.service';
// model
import { CategoryListItem } from "@shared/global-models/categoryListItem.model";
import { ProductGroup } from "@shared/global-models/productGroup.model";

@Component({
    selector: 'zk-product-overview',
    templateUrl: 'product-overview.component.html',
    styleUrls: ['./product-overview.component.scss']
})
export class ProductOverviewComponent implements OnInit, OnDestroy {
	/**
	 * Can be used to not render the default headline. Default is true.
	 */
    @Input()
    showDefaultHeadline = true;

    categories: CategoryListItem[] = [];
    private _groupsSub: Subscription = new Subscription();

    constructor(
        private _selectedCarService: SelectedCarService,
        private _productGroupsService: ProductGroupsService,
        private _router: Router
    ) {}

    ngOnInit() {
        this.setupConfig();
        this._groupsSub = this._productGroupsService.groupsChangedInfo.subscribe(() => this.setupConfig());
    }

    private setupConfig(): void {
        this.categories = this._productGroupsService.groups.map((x: ProductGroup) => ({
            url: x.urlName,
            title: x.name,
            subTitle: this.concatSubCategories(x.subGroups),
            iconName: x.iconReference
        }));
    }

    private concatSubCategories(groups: ProductGroup[]): string {
        let str = '';
        for (let i = 0; i < groups.length; i++) {
            str += groups[i].name;
            if (groups.length > 1 && i === groups.length - 2) {
                str += ' & ';
            } else if (groups.length > 2 && i < groups.length - 1) {
                str += ', ';
            }
        }

        return str;
    }

    routeToCategory(category: string): void {
        const part: string = this._selectedCarService?.carLine ? this._selectedCarService.carLine.urlName : 'products';
        this._router.navigate(['/' + part + '/' + category]).then();
    }

    ngOnDestroy(): void {
        this._groupsSub.unsubscribe();
    }
}
