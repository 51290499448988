// ng
import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslationService } from '@shared/shared-services/translate/translation.service';
import { Subscription } from 'rxjs';

// Services
import { ShoppingCartService } from '@shared/shared-services/shopping-cart/shopping-cart.service';
import { ViewportService } from '@shared/shared-services/viewport/viewport.service';
import { PopupModalService } from "@shared/modals/popup-modal/popup-modal.service";
import { CompatibilityCheckService } from '@shared/shared-services/compatibility/compatibility.service';
import { AppService } from '../../../app.service';
import { GoogleAnalyticsService } from '@shared/shared-services/google-analytics/google-analytics.service';
import { SelectedCarService } from '@shared/shared-services/selected-car/selected-car.service';
import { CarChooserService } from "@shared/components/car-chooser/car-chooser.service";

// Models
import { Article } from '@shared/global-models/article/article.model';
import { ShoppingCartData } from '@shared/shared-services/shopping-cart/model/shopping-cart-data.model';
import { PageName } from '@shared/shared-services/google-analytics/google-analytics.model';
import { MODE } from '@shared/global-models/mode.enum';
import { Router } from "@angular/router";
import { LocalStorageService } from "@shared/shared-services/storage/local-storage.service";

@Component({
    selector: 'zk-wishlist',
    templateUrl: './wishlist.component.html',
    styleUrls: ['./wishlist.component.scss']
})
export class WishlistComponent implements OnInit, OnDestroy {
    cartData: ShoppingCartData;
    displayErrorMessage = false;
    compatibilityChecked = false;
    showLoadingSpinner = false;
    showPrice = true;
    isMobile = false;
    isSmallScreen: boolean;
    isAccessoriesMode = true;
    selectedCar = '';
    selectedVIN = '';

    accessoriesArticles: Article[] = [];
    collectionArticles: Article[] = [];

    collectionArticleHasVariousPrices: boolean = false;

    private shoppingCartChangedSubscription: Subscription;
    private shoppingCartChangedErrorSubscription: Subscription;
    private compatibilityChangedSubscription: Subscription;
    private viewPortSubscription: Subscription;

    constructor(
        private shoppingCartService: ShoppingCartService,
        private modalService: PopupModalService,
        private compatibilityService: CompatibilityCheckService,
        private _appService: AppService,
        private viewPortService: ViewportService,
        private _gaService: GoogleAnalyticsService,
        private selectedCarService: SelectedCarService,
        public translationService: TranslationService,
        private _carChooserService: CarChooserService,
        private _translationService: TranslationService,
        private _localStorageService: LocalStorageService
    ) {}

    ngOnInit() {
        this.isAccessoriesMode = this._appService.appMode === MODE.ACCESSORIES;
        this.showPrice = this._appService.currentMarket.showPrice;

        // get actual viewport
        const vp: string = this.viewPortService.getCurrentViewPort();
        this.isMobile = vp === 'mq1' || vp === 'mq2';
        this.isSmallScreen = vp === 'mq1' || vp === 'mq2' || vp === 'mq3';

        this.getCartData();

        // check if the articles were already checked for compatibility
        const VINisLogged: boolean = this.selectedCarService.VIN !== null;
        this.compatibilityChecked = this.compatibilityService.compatibilityChecked || VINisLogged;

        // tracking
        this._gaService.trackPageView(PageName.WISHLIST);
        if (this.cartData && this.cartData.items) {
            this._gaService.trackProductImpressions(this.cartData.items);
        }

        this.setSelectedCarName();

        // react to changes
        this.subscribeToObservables();

        if (this._localStorageService.getItem('openVinOnModeChange') === 'true') {
            setTimeout(() => {
                this._carChooserService.toggleLayer(true, true);
                this._localStorageService.removeItem('openVinOnModeChange');
            })
        }
    }

    ngOnDestroy() {
        if (this.shoppingCartChangedSubscription) {
            this.shoppingCartChangedSubscription.unsubscribe();
        }

        if (this.shoppingCartChangedErrorSubscription) {
            this.shoppingCartChangedErrorSubscription.unsubscribe();
        }

        if (this.compatibilityChangedSubscription) {
            this.compatibilityChangedSubscription.unsubscribe();
        }

        if (this.viewPortSubscription) {
            this.viewPortSubscription.unsubscribe();
        }
    }

    getCartData(): void {
        this.cartData = this.shoppingCartService.currentShoppingCart;
        if (this.cartData && this.cartData.items) {
            this.splitAccAndCollItems();
        }
    }

    splitAccAndCollItems(): void {
        this.accessoriesArticles = [];
        this.collectionArticles = [];

        this.cartData.items.map((item) => {
            if (item.appMode === MODE.ACCESSORIES) {
                this.accessoriesArticles.push(item);
            }

            if (item.appMode === MODE.LIFESTYLE) {
                this.collectionArticles.push(item);
            }
        });

        this.sortArticlesInCart(this.accessoriesArticles);
        this.sortArticlesInCart(this.collectionArticles);

        this.collectionArticleHasVariousPrices = this.collectionArticles?.some((article: Article) => {
            return article.variantPropertiesCollection?.prices?.various === true && !article.sizeSelected;
        });
    }

    sortArticlesInCart(arr: Article[]) {
        if (arr && arr.length > 0) {
            arr.sort((a, b) => b.dateAdded - a.dateAdded);
        }
    }

    openCompatibilityCheck() {
        if (this.isAccessoriesMode) {
            this._carChooserService.toggleLayer(true, true);
        } else {

            // Case: User clicks on CC from Coll mode, which is not possible
            // as a workaround we redirect user to Acc, but only way to know that happened
            // is to save it to localStorage. As soon redirection is done, and CC layer opens
            // item is cleared from storage - see onInit above.
            // This may be changed in future as it's not optimal UX but temporary workaround
            this._localStorageService.setItem('openVinOnModeChange', 'true');

            const url= `/${this._translationService.currentLang}${this.selectedCarService.lastVehicleType.urlPart}/wishlist`;
            window.location.replace(url);
        }
    }

    private setSelectedCarName(): void {
        this.selectedVIN = this.selectedCarService.VIN;
        const carClass = this.selectedCarService.carClass?.name;
        const bodyType = this.selectedCarService.bodyType?.name;
        const prefix = this.translationService.translate('GENERAL.FOR');

        if (!!carClass && !!bodyType) {
            this.selectedCar = this.selectedCar.concat(`${prefix} ${carClass} ${bodyType}`);
        }
    }

    printPDF(): void {
        // wait until PDF is generated
        this.shoppingCartService.shoppingCartPDFGenerationComplete.subscribe((complete: boolean) => {
            this.showLoadingSpinner = !complete;
        });

        this.showLoadingSpinner = true;
        this.shoppingCartService.proceedToDownloadPDF();
    }

    private subscribeToObservables() {
        this.shoppingCartChangedSubscription = this.shoppingCartService.shoppingCartChanged.subscribe((response) => {
            this.cartData = response;
            // update view
            this.splitAccAndCollItems();
        });

        this.shoppingCartChangedErrorSubscription = this.shoppingCartService.shoppingCartChangedError.subscribe(() => {
            this.displayErrorMessage = true;
        });

        this.compatibilityChangedSubscription = this.compatibilityService.compatibilityChanged.subscribe(() => {
            this.compatibilityChecked = true;
            setTimeout(() => this.getCartData());
        });

        this.viewPortSubscription = this.viewPortService.getViewportChangedObserver().subscribe((newViewPort) => {
            this.isMobile = newViewPort === 'mq1' || newViewPort === 'mq2';
            this.isSmallScreen = newViewPort === 'mq1' || newViewPort === 'mq2' || newViewPort === 'mq3';
        });
    }
}
