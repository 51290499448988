// Ng
import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from "rxjs";
import { WbMediaQueryService } from "@shared/shared-services/media-query/wb-media-query.service";

// Services
import { CarChooserService } from "@shared/components/car-chooser/car-chooser.service";
import { SelectedCarService } from "@shared/shared-services/selected-car/selected-car.service";
import { TranslationService } from "@shared/shared-services/translate/translation.service";
import { GoogleAnalyticsService } from "@shared/shared-services/google-analytics/google-analytics.service";

// Models
import { CarLine, ModelDesign } from "../models";
import { MediaQuery } from "@workbench/core";

import { environment } from "../../../../../environments/environment";
import { Router } from '@angular/router';


@Component({
    selector: 'zk-car-chooser-teaser',
    templateUrl: './car-chooser-teaser.component.html',
    styleUrls: ['./car-chooser-teaser.component.scss']
})
export class CarChooserTeaserComponent implements OnInit, OnDestroy {
    @ViewChild('mobileMenu') mobileMenu!: ElementRef;
    @ViewChild('mobileMenuTrigger') mobileMenuTrigger!: ElementRef;

    carChooserLayer: boolean;
    vinLayer: boolean;
    isSelectedTypeAmg: boolean;
    selectedCarName: string | null = null;
    description: string | null = null;
    highlightButton: string;
    buttonText: string;
    isCarLogged = false;
    hasVin: string | null = null;
    isMobile: boolean;
    showAccordionMenu: boolean = false;
    isModelStartPage: boolean;

    vehicleSilhouette: CarLine | ModelDesign | null = null;

    environment = environment;

    private _carLineSubscription: Subscription;
    private _mediaQuerySub: Subscription;
    private _toggleLayerSub: Subscription;
    private _modelStartPage: Subscription;

    constructor(
        private _selectedCarService: SelectedCarService,
        private _translationService: TranslationService,
        private _mediaQueryService: WbMediaQueryService,
        private _carChooserService: CarChooserService,
        private _gaService: GoogleAnalyticsService,
		private _router: Router,
	) {
    }

    ngOnInit(): void {
        this._mediaQuerySub = this._mediaQueryService.mediaQuery.subscribe((mq: MediaQuery) => {
            this.isMobile = mq === 'mq1' || mq === 'mq2' || mq === 'mq3' || mq === 'mq4';
            this.setSelectedCarName();
        });

        this.initDetails();

        this._carLineSubscription = this._selectedCarService.carLineSubscriber.subscribe(
            () => this.initDetails());

        this._toggleLayerSub = this._carChooserService.toggleCarChooserLayer.subscribe(
            (state: { open: boolean, isVin: boolean }) => {
                this.carChooserLayer = state.open;
                this.vinLayer = state.isVin;
            })

        this.highlightButton = this._translationService.translate('CARCHOOSER.HIGHLIGHT_BUTTON');

        this._modelStartPage = this._selectedCarService.isModelStartPage.subscribe(
            (value) => this.isModelStartPage = value
        )
    }

    toggleCarChooserLayer(openFin: boolean = false) {
        if (openFin && !this.isCarLogged ) return;
        this.vinLayer = openFin;
        this.carChooserLayer = !this.carChooserLayer;
    }

    openModalStartPage(position: string) {
		const url = "/" + this._translationService.currentLang + this._selectedCarService.vehicleType.urlPart + '/' + this._selectedCarService.carLine.urlName;
		this._router.navigate([url]).then();
        this._gaService.trackModelStartPageNavigate(position);
    }

    handleButtonText(): void {
        const changeButtonText = this.isMobile ? this._translationService.translate('CARCHOOSER.CHANGE_CAR_ALT') : this._translationService.translate('CARCHOOSER.CHANGE_CAR');
        this.buttonText = this.isCarLogged ? changeButtonText : this._translationService.translate('CARCHOOSER.CHOOSE_CAR');
    }

    private initDetails() {
        this.isCarLogged = !!this._selectedCarService.carLine;
        this.isSelectedTypeAmg = this._selectedCarService.vehicleType.vehicleTypeId === "amg";
        this.setSelectedCarName();
        this.setVehiclePreviewImage();

        this.hasVin = this._selectedCarService.VIN ? this._selectedCarService.VIN : null;
        this.description = this.isCarLogged ? this._translationService.translate('CARCHOOSER.PROVIDE_VIN_COMPATIBILITY_CHECK') : this._translationService.translate('CARCHOOSER.CHOOSE_CAR_ALT');
    }

    private setVehiclePreviewImage() {
        if (this.isCarLogged) {
            this.vehicleSilhouette = this._selectedCarService.carLine.image ? this._selectedCarService.carLine : this._selectedCarService.bodyType.modelDesigns[0];
        } else {
            this.vehicleSilhouette = null;
        }
    }

    private setSelectedCarName() {
        let selectedCar: string;
        const prefix = this._translationService.translate('CARCHOOSER.MY_CAR');

        if (this.isCarLogged) {
            selectedCar = `${prefix} ${this._selectedCarService.carClass.name} ${this._selectedCarService.bodyType.name} ${this._selectedCarService.carLine.name}`;
        } else {
            if (this.isMobile) {
                selectedCar = this._translationService.translate('CARCHOOSER.CHOOSE_CAR');
            } else {
                selectedCar = this._translationService.translate('CARCHOOSER.YOUR_CAR');
            }
        }

        this.selectedCarName = selectedCar;

        this.handleButtonText();
    }

    @HostListener('document:click', ['$event'])
    private listenToDocumentClick(event: { target: any; }) {
        if (this.mobileMenu &&
            !this.mobileMenu.nativeElement.contains(event.target) &&
            !this.mobileMenuTrigger.nativeElement.contains(event.target) &&
            this.showAccordionMenu) {
            this.showAccordionMenu = false;
        }
    }

    ngOnDestroy() {
        this._carLineSubscription.unsubscribe();
        this._mediaQuerySub.unsubscribe();
        this._toggleLayerSub.unsubscribe();
        this._modelStartPage.unsubscribe();
    }
}
