import { AfterViewInit, Component, OnInit } from '@angular/core';
import { SeoService } from "@shared/shared-services/seo/seo.service";
import { GoogleAnalyticsService } from "@shared/shared-services/google-analytics/google-analytics.service";
import { PageName } from "@shared/shared-services/google-analytics/google-analytics.model";

@Component({
    selector: 'zk-splash-page',
    templateUrl: './splash-page.component.html',
    styleUrls: ['./splash-page.component.scss']
})
export class SplashPageComponent implements OnInit, AfterViewInit {

    constructor(
        private _seoService: SeoService,
        private _gaService: GoogleAnalyticsService
    ) {
    }

    ngOnInit(): void {
        this.setSeoInfo();
    }

    private setSeoInfo() {
        const title: string = this._seoService.getTranslation('SPLASHPAGE.PAGE_TITLE');
        this._seoService.updatePageTitle(title);
        this._seoService.updateMetaDescription(title);
    }

    ngAfterViewInit() {
        this._gaService.trackPageView(PageName.SPLASH_PAGE);
    }
}
