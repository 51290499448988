<!-- Category not available info modal -->
<zk-popup-modal id="category-not-available-modal"
                class="not-available-modal"
                [headline]="modalHeadline"
                [closeOnClickOutside]="false"
                [showCloseButton]="false">

    <div modalContent class="content">
        <wb-text size="l" zkTranslate="PRODUCTLIST.INFO.TEXT"></wb-text>
    </div>

    <div modalFooter class="modal-footer">
        <wb-button variant="primary"
                   class="button"
                   (click)="onContinue()">
            <span zkTranslate="GENERAL.CONTINUE"></span>
        </wb-button>
    </div>
</zk-popup-modal>
