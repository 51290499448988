// NG
import { Component, OnInit, AfterViewInit } from '@angular/core';

// Models
import { PageName } from '@shared/shared-services/google-analytics/google-analytics.model';

// Services
import { SelectedCarService } from '@shared/shared-services/selected-car/selected-car.service';
import { SeoService } from '@shared/shared-services/seo/seo.service';
import { UrlParameterService } from '@shared/shared-services/url-parameters-service/url-parameter.service';
import { TeaserService } from '@shared/shared-services/teaser/teaser.service';
import { GoogleAnalyticsService } from '@shared/shared-services/google-analytics/google-analytics.service';
import { Slide } from "@shared/global-models/slide.model";

@Component({
    selector: 'zk-start-page',
    templateUrl: './start-page.component.html',
    styleUrls: ['./start-page.component.scss']
})
export class StartPageComponent implements OnInit, AfterViewInit {
    slides: Slide[];
    firstSlideHeadline: string;
    isCarLogged = false;

    theme: 'dark' | 'light' = 'light';

    constructor(
        private _teaserService: TeaserService,
        private selectedCarService: SelectedCarService,
        private _seoService: SeoService,
        private _urlParameterService: UrlParameterService,
        private _gaService: GoogleAnalyticsService,
        private _selectedCarService: SelectedCarService,
    ) {}

    ngOnInit() {
        // check for special query params entry into start page URL
        this._urlParameterService.checkForCarLineIdParamInURL();
        this._urlParameterService.checkForArticleIdParamInURL();

        this.getSliderImages();
        // scroll up on initial page load (important for mobile)
        window.scrollTo(0, 0);

        this.isCarLogged = !!this._selectedCarService.carLine;
    }

    ngAfterViewInit() {
        this._gaService.trackPageView(PageName.START_PAGE);
    }

    trackStageSlider(url: string) {
        this._gaService.trackStageSlider(url);
    }

    /**
     * Get Stage Slider images from CMS
     */
    private getSliderImages(useFallbackLang?: boolean) {
        const sub = this._teaserService.getSlides(this.selectedCarService.vehicleType.vehicleTypeId, useFallbackLang).subscribe(
            (response) => {
                sub.unsubscribe();

                if (response.slides.length > 0) {
                    this.slides = response.slides;
                    this.firstSlideHeadline = response.slides[0].headline;

                    // Set initial Slider theme
                    // Note: BE delivers theme for each slide. We set parent Slider theme also same as
                    // current slide dynamically by handleTheme() wbchange event.
                    // Note: In CMS if text color is set to black, FE need to use light theme.
                    this.theme = this.slides[0].isTextBlack ? 'light' : 'dark';

                    this.setSeoInfo();
                } else if (!useFallbackLang) {
                    console.log('No sliders found for current market. Needs to be added in CMS. Using fallback to default global market "en-MB", therefore.');
                    this.getSliderImages(true);
                }
            },
            (error) => {
                sub.unsubscribe();
                console.error('Failed to get Slider from CMS', error);
            }
        );
    }

    private setSeoInfo() {
        const title: string = this._seoService.getTranslation('GENERAL.PAGES.STARTPAGE_STATICTITLE');
        this._seoService.updatePageTitle(title + ' | ' + this.firstSlideHeadline);
        this._seoService.updateMetaDescription(this._seoService.getTranslation('SEO.METADESCRIPTION.STARTPAGE'));
    }

    handleTheme(e: any) {
        let currentSlide = e.detail - 1;
        this.theme = this.slides[currentSlide].isTextBlack ? 'light' : 'dark';
    }
}
