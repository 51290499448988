// Ng
import { Router } from "@angular/router";
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from "rxjs";

// Services
import { WbMediaQueryService } from "@shared/shared-services/media-query/wb-media-query.service";
import { Co2Service } from "@shared/shared-services/co2-label/co2.service";
import { SelectedCarService } from "@shared/shared-services/selected-car/selected-car.service";
import { TranslationService } from "@shared/shared-services/translate/translation.service";
import { ResetWarningModalService } from "@shared/modals/reset-warning-modal/reset-warning-modal.service";
import { AppService } from "../../../../app.service";
import { MediaQuery, ScrollLockService } from "@workbench/core";
import { CarChooserService } from "../car-chooser.service";
import { ShoppingCartService } from "@shared/shared-services/shopping-cart/shopping-cart.service";
import { PopupModalService } from "@shared/modals/popup-modal/popup-modal.service";
import { LocalStorageService } from "@shared/shared-services/storage/local-storage.service";
import { CurrentRouteService } from "@shared/shared-services/current-route/current-route.service";
import { CompatibilityCheckService } from "@shared/shared-services/compatibility/compatibility.service";

import { environment } from 'environments/environment';

// Models
import { BodyType, CarClass, CarLine, ModelDesign, VehicleType, NewCarDataSet } from "../models";
import { StorageEnum } from "@shared/shared-services/storage/storage.enum";

@Component({
    selector: 'zk-car-chooser',
    templateUrl: './car-chooser.component.html',
    styleUrls: ['./car-chooser.component.scss']
})

export class CarChooserComponent implements OnInit, OnDestroy {
    @Input() isVinLayer: boolean = false;

    // CarChooser selected designation states
    carClasses: CarClass[] = null;
    currentCarClass: CarClass = null;
    currentBodyType: BodyType = null;
    currentModelDesign: ModelDesign = null; // AMG only
    currentCarLines: CarLine[] | null;
    currentCarLine: CarLine | null;

    vehicleSilhouette: CarLine | ModelDesign;

    vehicleTypes: VehicleType[];
    activeVehicleType: VehicleType; // Application active VehicleType
    carChooserVehicleType: VehicleType; // carChooser active VehicleType
    vehicleTypeChanged: boolean = false;

    isSelectedTypeAmg: boolean;
    selectedCarName: string | null = null;
    carIsLogged: boolean;
    hasVin: string | null;
    loggedCarSameAsSelected: boolean;
    isMobile: boolean;
    isTablet: boolean;
    isScrolled: boolean = false;
    co2Label: string | null;

    newCar: NewCarDataSet = null;

    private _scrollLockService = new ScrollLockService();
    private _mediaQuerySub: Subscription;

    environment = environment;

    constructor(
        private _router: Router,
        private _appService: AppService,
        private _selectedCarService: SelectedCarService,
        private _translationService: TranslationService,
        private _mediaQueryService: WbMediaQueryService,
        private _carChooserService: CarChooserService,
        private _co2Service: Co2Service,
        private _shoppingCartService: ShoppingCartService,
        private _modalService: PopupModalService,
        private _resetWarningService: ResetWarningModalService,
        private _localStorageService: LocalStorageService,
        private _currentRouteService: CurrentRouteService,
        private _compatibilityCheckService: CompatibilityCheckService
    ) {
    }

    ngOnInit(): void {
        this._mediaQuerySub = this._mediaQueryService.mediaQuery.subscribe((mq: MediaQuery) => {
            this.isMobile = mq === 'mq1' || mq === 'mq2';
            this.isTablet = mq === 'mq3';
        });

        this.vehicleTypes = JSON.parse(JSON.stringify(this._selectedCarService.vehicleTypes));
        this.activeVehicleType = this._selectedCarService.vehicleType;
        this.carIsLogged = !!this._selectedCarService.carClass?.carClassId;

        this.setVehicleType(this.activeVehicleType);

        this._scrollLockService ? this._scrollLockService.lock() : null;
    }

    setVehicleType(vehicleType: VehicleType) {
        this.resetSelectedCar();

        this.vehicleTypes.forEach(type => type.isActive = type.vehicleTypeId === vehicleType.vehicleTypeId);
        this.carChooserVehicleType = vehicleType;
        this.isSelectedTypeAmg = this.carChooserVehicleType.vehicleTypeId === "amg";
        this.vehicleTypeChanged = this.carChooserVehicleType.vehicleTypeId !== this.activeVehicleType.vehicleTypeId;

        this.carClasses = this._carChooserService.getCarClasses(vehicleType);
        if (this.carIsLogged && !this.vehicleTypeChanged) {
            this.setLoggedInCarActive();
        }

        // Information needed to handle logging vehicles with VIN
        this.newCar = {
            carClasses: this.carClasses,
            carChooserVehicleType: this.carChooserVehicleType,
            vehicleTypeChanged: this.vehicleTypeChanged,
            isAmg: this.isSelectedTypeAmg
        }
    }


    setCarClass(carClass: CarClass) {
        if (carClass.isActive) return;
        this.carClasses.forEach(cc => cc.isActive = cc.carClassId === carClass.carClassId);
        this.currentCarClass = JSON.parse(JSON.stringify(carClass));

        if (this.currentBodyType) {
            this.currentBodyType = null;
            this.vehicleSilhouette = null;
            this.co2Label = null;
        }

        if (this.currentCarLines) {
            this.currentCarLines = null;
            this.currentCarLine = null;
        }

        this.disableLoginButton();
        this.getSelectedCarName();
        this._carChooserService.scrollToEl('bodyType');
    }

    setBodyType(bodyType: BodyType) {
        if (bodyType.isActive) return;
        this.currentCarClass.bodyTypes.forEach( bt => bt.isActive = bt.bodyTypeId === bodyType.bodyTypeId);
        this.currentBodyType = JSON.parse(JSON.stringify(bodyType));
        this.currentCarLines = this.currentBodyType.carLines;
        this.currentCarLine = null;


        this.setVehiclePreviewImage(this.isSelectedTypeAmg ? bodyType.modelDesigns[0] : bodyType.carLines[0]);
        this.disableLoginButton();
        this.getSelectedCarName();
        this._carChooserService.scrollToEl(this.isSelectedTypeAmg ? 'modelDesign' : 'carLine');

        if (this.isSelectedTypeAmg) {
            this.co2Label = this._co2Service.getCo2Label(this.currentBodyType.modelDesigns[0].modelDesignId, true, true);
        }
    }

    setModelDesign(modelDesign: ModelDesign) {
        if (modelDesign === null || modelDesign?.isActive) return;
        this.currentBodyType.modelDesigns.forEach( md => md.isActive = md.modelDesignId === modelDesign.modelDesignId);
        this.currentModelDesign = JSON.parse(JSON.stringify(modelDesign));
        this.currentCarLines = this.currentModelDesign.carLines;
        this.currentCarLine = null;

        this.disableLoginButton();
        this._carChooserService.scrollToEl('carLine');
    }

    setCarLine(carLine: CarLine) {
        if (carLine?.isActive) return;
        this.currentCarLines.forEach( cl => cl.isActive = cl.carLineId === carLine.carLineId);
        this.currentCarLine = JSON.parse(JSON.stringify(carLine));

        this.setVehiclePreviewImage(carLine.image ? carLine : this.currentBodyType.modelDesigns[0]);
        this.disableLoginButton();
        this.getSelectedCarName();
        this._carChooserService.scrollToEl('scroll-wrapper', true);
    }

    setVehiclePreviewImage(carLine: CarLine | ModelDesign) {
        this.vehicleSilhouette = carLine;
    }

    setLoggedInCarActive() {
        this.carClasses.forEach(cc => cc.isActive = cc.carClassId === this._selectedCarService.carClass.carClassId);
        this.currentCarClass = this._selectedCarService.carClass
        this.currentBodyType = this._selectedCarService.bodyType;
        this.currentModelDesign = this._selectedCarService.modelDesign;
        this.currentCarLines = this.isSelectedTypeAmg ? this.currentModelDesign?.carLines : this.currentBodyType.carLines;
        this.currentCarLine = this._selectedCarService.carLine;

        this.setBodyType(this.currentBodyType);
        this.setModelDesign(this.currentModelDesign);
        this.setCarLine(this._selectedCarService.carLine); // why tf this.currentCarLine can't be passed here?

        this.setVehiclePreviewImage(this.currentCarLine.image ? this.currentCarLine : this.currentBodyType.modelDesigns[0]);
        this.disableLoginButton();
        this.getSelectedCarName();
    }

    getSelectedCarName() {
        if (!this.currentCarClass.name) {
            this.selectedCarName = null;
        } else {
            this.selectedCarName = `${this.currentCarClass.name || ''} ${this.currentBodyType?.name || ''} ${this.currentCarLine?.name || ''}`;
        }
        this.hasVin = (this._selectedCarService.VIN && this.loggedCarSameAsSelected) ? this._selectedCarService.VIN : null;

        if (this.isSelectedTypeAmg) {
            this.co2Label = this._co2Service.getCo2Label(this.currentBodyType?.modelDesigns[0].modelDesignId, true, true);
        }
    }

    resetSelectedCar() {
        this.carClasses = null;
        this.currentCarClass = null;
        this.currentBodyType = null;
        this.currentModelDesign = null;
        this.currentCarLines = null;
        this.currentCarLine = null;
        this.selectedCarName = null;
        this.vehicleSilhouette = null;
        this.newCar = null;
        this.hasVin = null;
        this.co2Label = null;
        this.loggedCarSameAsSelected = false;
    }

    disableLoginButton() {
        const sameLine = this.currentCarLine?.carLineId === this._selectedCarService?.carLine?.carLineId;
        const sameModel = this.currentModelDesign?.modelDesignId === this._selectedCarService.modelDesign?.modelDesignId;
        this.loggedCarSameAsSelected = (sameLine && sameModel);
    }


    loginCar() {
        const hasShoppingCartItems = this._shoppingCartService.currentShoppingCart && this._shoppingCartService.currentShoppingCart.items.length > 0;

        /**
         * Changing VehicleType with items in Cart will inform user
         * about Cart to be removed, if continued
         */
        if (this.vehicleTypeChanged && hasShoppingCartItems) {
            this._modalService.open('reset-warning-modal');

            const sub: Subscription = this._resetWarningService.actionAllowed.subscribe((action: boolean) => {
                sub.unsubscribe();
                if (action) {
                    this._shoppingCartService.clearShoppingCart();
                    this._selectedCarService.clearVin();

                    const url= `/${this._translationService.currentLang}${this.carChooserVehicleType.urlPart}/${this.currentCarLine.urlName}`;
                    window.location.replace(url);
                }
            })
        } else if (this.vehicleTypeChanged) {
            this._selectedCarService.clearVin();

            const url= `/${this._translationService.currentLang}${this.carChooserVehicleType.urlPart}/${this.currentCarLine.urlName}`;
            window.location.replace(url);
        } else {
            this.setActiveVehicleType();
            this.saveCar();

            const url = this._currentRouteService.updateCurrentRoute(this.currentCarLine);
            this._router.navigate([url]).then();

            this._carChooserService.toggleLayer(false);
        }
    }

    saveCar() {
        this._selectedCarService.clearVin();

        this._selectedCarService.carClass = this.currentCarClass;
        this._selectedCarService.bodyType = this.currentBodyType;

        if (this.currentModelDesign) {
            this._selectedCarService.modelDesign = this.currentModelDesign;
        } else {
            this._localStorageService.removeItem(StorageEnum.MODEL_TYPE);
        }

        this._selectedCarService.carLine = this.currentCarLine;
    }

    setActiveVehicleType() {
        this._selectedCarService.vehicleType = this.carChooserVehicleType;
        this._selectedCarService.vehicleTypes.forEach((x: VehicleType) => {
            x.isActive = x.vehicleTypeId === this.carChooserVehicleType.vehicleTypeId
        });
    }

    removeCar() {
        this._selectedCarService.resetLoggedCarData();
        const url = this._translationService.currentLang + this._appService.currentAppMode + this._currentRouteService.updateCurrentRoute(null);
        window.location.replace(url);
    }

    // apply shadow if scrolled
    onScroll(event: any): void {
        if (this.isMobile || this.isTablet) {
            this.isScrolled = event.target.scrollTop > 0;
        }
    }

    closeLayer() {
        this._carChooserService.toggleLayer(false);

        const currentArticles = this._compatibilityCheckService.currentArticles;

        // Case (PDP): user logged vehicle by class, and open VinLayer from compatibilityCheck modal,
        // and close the layer without finishing CC > we need to handle redirection on previously logged in vehicle
        // PDP only: If current PDP Article not fit, redirect to its sub/category.
        // If no category, category-not-available-modal will be showed
        if (this.carIsLogged && currentArticles && currentArticles[0].fit === false) {
            this._currentRouteService.handlePDPArticleRedirect(currentArticles[0]);
        }

        // Todo: handle same case but on sub/cat (PDL) pages
    }

    ngOnDestroy(): void {
        this._scrollLockService?.isLocked ? this._scrollLockService.unlock() : null;
        this._mediaQuerySub.unsubscribe();
    }
}
