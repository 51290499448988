<div class="highlights-teaser">
    <div class="page-wrapper no-top-padding no-bottom-padding">
        <zk-car-teaser class="car-teaser" [showActionButton]="showActionButton"></zk-car-teaser>
    </div>

    <picture>
        <source srcset="ui/assets/img/teaser-background/teaser_bg_480.jpg"
                media="(max-width:480px)">
        <source srcset="ui/assets/img/teaser-background/teaser_bg_768.jpg"
                media="(max-width:768px)">
        <source srcset="ui/assets/img/teaser-background/teaser_bg_1024.jpg"
                media="(max-width:1024px)">
        <source srcset="ui/assets/img/teaser-background/teaser_bg_1280.jpg"
                media="(max-width:1280px)">
        <source srcset="ui/assets/img/teaser-background/teaser_bg_1440.jpg"
                media="(max-width:1440px)">
        <source srcset="ui/assets/img/teaser-background/teaser_bg_1680.jpg"
                media="(max-width:1680px)">
        <img src="ui/assets/img/teaser-background/teaser_bg_1920.jpg" alt="Background Image"/>
    </picture>
</div>
