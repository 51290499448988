<div class="vin-lock spacing-s">
    <div class="vin-lock_form">
        <wb-input type="text" inputmode="text" placeholder="{{placeholder}}"
                  [error]="errorMessage"
                  (keyup.enter)="onLockVin(inputEl.value)"
                  (input)="sanitizeInput($event)"
                  #inputEl>
            <span slot="label" zkTranslate="CARCHOOSER.VIN"></span>
            <wb-input-action slot="actions">
                <wb-round-button size="s" (click)="resetInputField()">
                    <wb-icon name="bds/close/24"></wb-icon>
                </wb-round-button>
            </wb-input-action>
        </wb-input>

        <wb-button *ngIf="!showLoadingSpinner"
                   variant="primary"
                   size="s"
                   [disabled]="disableLogin"
                   (click)="onLockVin(inputEl.value)"
                   zkTrackClick="fin-entered">
            <span zkTranslate="VINLOCK.SEND"></span>
        </wb-button>
    </div>

    <wb-text size="m" *ngIf="errorMessage" class="is-error">{{errorMessage}}</wb-text>

    <div *ngIf="showLoadingSpinner" class="loading-indicator">
        <wb-spinner theme="light"></wb-spinner>
        <span class="progress-text">{{loadingSpinnerText}}</span>

        <wb-text size="m" class="feedback-text">{{progressFeedbackText}}</wb-text>
    </div>

    <wb-accordion *ngIf="showDescription" [prevent-mobile-flyout]="false" class="spacing-xs">
        <wb-accordion-item>
            <span slot="header" zkTranslate="COMPATIBILITYCHECK.FINDVIN"></span>
            <div slot="content" style="text-align: left">
                <wb-text size="l" static zkTranslate="VINLOCK.FURTHERINFORMATION"></wb-text>

                <img class="spacing-xxs" src="ui/assets/img/technicalDataDetailExample.jpg" alt="VIN example image">
            </div>
        </wb-accordion-item>
    </wb-accordion>
</div>
