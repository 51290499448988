<div class="cart-item_wrapper"
     [ngClass]="{'cart-item_border': !cartItem.axle || cartItem.axle && cartItem.axle === 'front' || cartItem.axle && cartItem.axle === 'all',
                 'is-compact': isInModal}"
     *ngIf="cartItem">
    <div class="cart-item_image"
         [ngClass]="{'is-mobile-shopping-cart': !isCompatibilityCheck && !isAddedToCartFeedback && !isRimSetPreviewLayer}">
            <img [src]="cartItem.image320 ? cartItem.image320 : cartItem.images[0].small"
                 [alt]="cartItem.headline2 ? cartItem?.headline +' '+ cartItem?.headline2 : cartItem.headline"
                 (click)="routeToArticle()">
    </div>
    <div class="cart-item_main">
        <div class="cart-item_main_description"
             [ngClass]="{'has-quantity': !isAddedToCartFeedback,
                         'is-simpleview': isAddedToCartFeedback}">
            <div class="wb-type-copy cart-item_link is-bold"
                 [ngClass]="{'has-no-margin': isCompatibilityCheck}"
               (click)="routeToArticle()">
                <span>{{cartItem.headline}}</span>
            </div>
            <p *ngIf="cartItem.headline2 && cartItem.propertiesCollection?.color"
                  class="wb-type-copy">
                {{cartItem.propertiesCollection.color}}{{(!cartItem.variantArticles && cartItem.propertiesCollection.size) ? ', ' + cartItem.propertiesCollection.size : ''}}
            </p>
            <p *ngIf="cartItem.axle"
                  class="wb-type-copy">{{getRimTypeHeadline(cartItem.axle)}}
            </p>
            <p class="wb-type-copy article-number">
                {{cartItem.articleId}}
            </p>
            <div class="wb-type-copy" *ngIf="cartItem.variantPropertiesCollection?.sizes">
                <ng-container *ngFor="let sizeVariant of cartItem.variantPropertiesCollection.sizes">
                    <span *ngIf="sizeVariant.hint != 'various'"
                          class="article-size"
                          [ngClass]="{ 'is-active' : sizeVariant.isActive || (cartItem.sizeSelected && sizeVariant.hint === 'self')}"
                          (click)="getVariantArticleData(sizeVariant)">{{sizeVariant.name}}
                </span>
                </ng-container>
            </div>
            <wb-tag *ngIf="cartItem.fit && cartItem.appMode === appMode.ACCESSORIES" zkTranslate="PRODUCTDETAILS.COMPATIBLE"></wb-tag>
            <wb-tag *ngIf="cartItem.fit === false && cartItem.appMode === appMode.ACCESSORIES" class="is-red" zkTranslate="PRODUCTDETAILS.INCOMPATIBLE"></wb-tag>
        </div>
        <div *ngIf="!isAddedToCartFeedback; else addToCartFeedback"
             class="cart-item_main_prices">
            <span *ngIf="showPrice"
                  class="wb-type-copy item-price"
                  [ngClass]="{'is-bold': isCompatibilityCheck}">
                {{ cartItemPrice }}
            </span>
            <div class="item-amount" *ngIf="!isCompatibilityCheck">
                <select class="ui-dropdown no-border item-amount_dropdown"
                        name="quantity"
                        [(ngModel)]="cartItem.quantity"
                        (change)="onItemQuantityChange()">
                    <option *ngFor="let qt of itemQuantityOptions"
                            [ngValue]="qt">
                        {{ qt }}
                    </option>
                </select>
                <wb-icon slot="icon" class="arrow-down" name="bds/chevron-down/16"></wb-icon>
            </div>
            <span *ngIf="showPrice && !isCompatibilityCheck"
                  class="wb-type-copy item-price is-total-price">
                {{ isRimSetPreviewLayer ? cartItem.totalGrossPriceText : cartItemPriceTotal }}
            </span>
        </div>

        <!-- Just displays price and quantity of the article that was added to the cart and serves as feedback popup without any further interaction logic -->
        <ng-template #addToCartFeedback>
            <span *ngIf="showPrice"
                  class="item-price-simpleView">
                {{cartItem.quantityAdded > 1 ? cartItem.quantityAdded + ' x ' + cartItem.grossPriceText : cartItem.grossPriceText}}
            </span>
        </ng-template>
    </div>
    <wb-icon *ngIf="!cartItem?.notRemovable && !hideDeleteIcon"
             slot="icon" class="cart-item_icon-delete" name="bds/trash/16"
             (click)="onCartItemRemove($event, cartItem)">
    </wb-icon>
</div>
