// ng
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SelectedCarService } from '@shared/shared-services/selected-car/selected-car.service';

// Services
import { AppService } from '../../../app.service';
import { TranslationService } from '@shared/shared-services/translate/translation.service';
import { Co2Service } from '@shared/shared-services/co2-label/co2.service';
import { GoogleAnalyticsService } from "@shared/shared-services/google-analytics/google-analytics.service";

// model
import { MODE } from '@shared/global-models/mode.enum';
import { Link } from "@shared/global-models/link.model";

declare global {
    interface Window {
        UC_UI: any;
    }
}

@Component({
    selector: 'zk-footer-base',
    templateUrl: './footer-base.component.html',
    styleUrls: ['./footer-base.component.scss']
})
export class FooterBaseComponent implements OnInit {
    @ViewChild('usercentrics', { static: true })
    usercentrics: ElementRef;

    @Input()
    showPriceNotification: boolean;

    footerLinks: Link[];

	co2Label: string;

	get isAmg(): boolean {
		return this._selectedCarService.vehicleType?.vehicleTypeId === 'amg';
	}

    constructor(
        private _appService: AppService,
        private _translationService: TranslationService,
        private _router: Router,
		private _selectedCarService: SelectedCarService,
		private _co2Service: Co2Service,
        private _gaService: GoogleAnalyticsService
    ) {}

    ngOnInit() {
        this.initLegalFooter();

		this.co2Label = this._co2Service.getFooterCo2Label();
    }

    private initLegalFooter(): void {
        this.footerLinks = [
            {
                // external link
                linkName: this._translationService.translate('POLICIES.PROVIDER.NAME'),
                linkUrl: this._translationService.translate('POLICIES.PROVIDER.URL'),
                openLinkInTab: true
            },
            {
                // opens usercentrics
                linkName: this._translationService.translate('POLICIES.COOKIES.NAME'),
                linkUrl: 'usercentrics',
                openLinkInTab: false
            },
            {
                // internal route => CMS content
                linkName: this._translationService.translate('POLICIES.PRIVACY.NAME'),
                linkUrl: 'legal/privacy',
                openLinkInTab: false
            },
            {
                // external link
                linkName: this._translationService.translate('POLICIES.LEGAL.NAME'),
                linkUrl: this._translationService.translate('POLICIES.LEGAL.URL'),
                openLinkInTab: true
            }
        ];

        // extra sausage here for UK market
        if (this._appService.currentMarket.marketCode === 'GB') {
            const modernSlaveryAct: Link = {
                // internal route => CMS content
                linkName: this._translationService.translate('POLICIES.MARKETSPECIFIC.NAME'),
                linkUrl: 'legal/modernslaveryact',
                openLinkInTab: false
            };
            this.footerLinks.splice(1, 0, modernSlaveryAct);
        }
    }

    linkClicked(item: Link): void {
        // another extra sausage: Click on cookies link opens usercentrics layer to change their Privacy Settings
        if (item.linkUrl === 'usercentrics' && window.UC_UI) {
            window.UC_UI.showSecondLayer();
        } else {
            console.log('UC not defiend or not available for this staging')
        }

        if (item.linkUrl === 'legal/privacy' || item.linkUrl === 'legal/modernslaveryact') {
            this._router.navigate([item.linkUrl], { queryParamsHandling: 'preserve' });
        }
    }

    switchMode(): void {
        this._gaService.trackModeSwitch("footer");
        this._appService.switchMode();
    }

    get isAccessoriesMode() : boolean {
        return this._appService.appMode === MODE.ACCESSORIES;
    }

    get switchModeVisible() {
        return this._appService.currentMarket.collectionMarket &&
               this._appService.appMode != MODE.NONE &&
               !this._router.url.includes('/campaign/');
    }
}
