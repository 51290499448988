<div class="car-chooser" [ngClass]="{'is-Vin': isVinLayer}">

    <wb-heading class="main-headline" size="m"
                [zkTranslate]="isVinLayer ? 'CARCHOOSER.PROVIDE_VIN' : 'CARCHOOSER.CHOOSE_CAR_MODEL'">
    </wb-heading>

    <ng-container *ngIf="isVinLayer">
        <wb-text size="l" zkTranslate="VINLOCK.DESCRIPTION" class="sub-headline"></wb-text>

        <zk-vehicle-type-switch [vehicleTypes]="vehicleTypes" class="brands-vin"
                                (onTypeChanged)="setVehicleType($event)">
        </zk-vehicle-type-switch>

        <zk-vin-lock [newCar]="newCar"></zk-vin-lock>
    </ng-container>

    <div *ngIf="!isVinLayer" class="car-designations-wrapper">

        <div class="preview-image" [ngClass]="{'has-shadow': isScrolled}">
            <img *ngIf="!vehicleSilhouette" loading="lazy"
                 src="ui/assets/img/silhouette-placeholder.jpg" alt="vehicle_fallback_image">

            <!-- TODO: Remove 'is-scaled' class, once AMG images are corrected -->
            <img *ngIf="vehicleSilhouette"
                 [ngClass]="{'is-scaled': isSelectedTypeAmg}"
                 loading="lazy"
                 #carImage
                 [src]="environment.apiUrl + vehicleSilhouette.image"
                 (error)="carImage.src='ui/assets/img/fallback-565.png'"
                 [alt]="currentCarClass?.name">

            <wb-heading *ngIf="selectedCarName" size="xs">{{selectedCarName}}</wb-heading>
            <wb-text size="m" *ngIf="hasVin" class="vin">{{hasVin}}</wb-text>
            <wb-text size="l" *ngIf="co2Label && !isMobile" class="co2-label" [innerHTML]="co2Label"></wb-text>
            <wb-heading *ngIf="!selectedCarName && isMobile" size="xs" zkTranslate="CARCHOOSER.CHOOSE_CAR_MODEL"></wb-heading>

            <wb-text *ngIf="carIsLogged" class="button-remove-car is-amg-red" size="m"
                     (click)="removeCar()">
                <wb-icon name="bds/close/16" class="close-icon"></wb-icon>
                <span zkTranslate="CARCHOOSER.LOGOUT_CAR"></span>
            </wb-text>
        </div>

        <div class="car-designations" (scroll)="onScroll($event)">
            <zk-vehicle-type-switch [vehicleTypes]="vehicleTypes"
                                    (onTypeChanged)="setVehicleType($event)">
            </zk-vehicle-type-switch>

            <div class="scroll-wrapper-desktop" id="scroll-wrapper">

                <!--    CAR CLASS     -->
                <wb-heading class="headline" size="xs" zkTranslate="CARCHOOSER.CARCLASS"></wb-heading>
                <span class="designation is-center is-car_class"
                      *ngFor="let carClass of carClasses"
                      [ngClass]="{'is-active': carClass.isActive}"
                      (click)="setCarClass(carClass)">
                    {{carClass.shortName}}
                </span>

                <!--    BODY TYPES (Karosserie)     -->
                <wb-heading class="headline" size="xs" [ngClass]="{'is-inactive': !currentCarClass}"
                            id="bodyType" zkTranslate="CARCHOOSER.BODYTYPE">
                </wb-heading>

                <span class="designation"
                      [ngClass]="{'is-inactive': !currentCarClass, 'is-active': bodyType.isActive}"
                      *ngFor="let bodyType of currentCarClass ? currentCarClass?.bodyTypes : ['', '', '']"
                      (click)="setBodyType(bodyType)">

                    <ng-container *ngIf="currentCarClass">{{bodyType.name}}</ng-container>
                </span>

                <!--    MODEL DESIGNS - AMG Only    -->
                <ng-container *ngIf="isSelectedTypeAmg">
                    <wb-heading class="headline" size="xs" [ngClass]="{'is-inactive': !currentBodyType}"
                                id="modelDesign" zkTranslate="CARCHOOSER.MODELDESIGN">
                    </wb-heading>

                    <span class="designation"
                          [ngClass]="{'is-inactive': !currentBodyType, 'is-active': modelDesign.isActive}"
                          *ngFor="let modelDesign of currentBodyType ? currentBodyType?.modelDesigns : ['', '', '']"
                          (click)="setModelDesign(modelDesign)">

                        <ng-container *ngIf="currentBodyType">{{modelDesign.name}}</ng-container>
                    </span>
                </ng-container>

                <!--    CAR LINES (Baujahr)    -->
                <wb-heading class="headline" size="xs" [ngClass]="{'is-inactive': !currentBodyType}"
                            id="carLine" zkTranslate="CARCHOOSER.CARLINE">
                </wb-heading>

                <span class="designation"
                      [ngClass]="{'is-inactive': !currentCarLines, 'is-active': carLine.isActive}"
                      *ngFor="let carLine of currentCarLines ? currentCarLines : ['', '', '']"
                      (click)="setCarLine(carLine)">

                    <ng-container *ngIf="currentCarLines">{{carLine.name}}</ng-container>
                </span>

                <wb-text size="l" *ngIf="co2Label && isMobile" class="co2-label" [innerHTML]="co2Label"></wb-text>

                <wb-button variant="primary" size="s" class="button-login-car" id="loginButton"
                           [disabled]="loggedCarSameAsSelected || !this.currentCarLine"
                           (click)="loginCar()"
                           zkTranslate="CARCHOOSER.LOGIN_CAR">
                </wb-button>
            </div>
        </div>
    </div>

    <div class="footer">
        <wb-button variant="secondary" class="button-vin-layer"
                   (click)="isVinLayer = !isVinLayer"
                   [zkTranslate]="isVinLayer ? 'CARCHOOSER.CHOOSE_CAR_MODEL' : 'CARCHOOSER.PROVIDE_VIN'">
        </wb-button>

        <wb-tooltip position="top" class="info-icon">
            <wb-icon name="bds/info-circle/24" ></wb-icon>

            <div slot="content" [zkTranslate]="isVinLayer ? 'CARCHOOSER.TOOLTIP_VIN' : 'CARCHOOSER.TOOLTIP_CARCHOOSER'"></div>
        </wb-tooltip>


        <wb-button *ngIf="!isVinLayer" variant="tertiary" class="button-remove-car"
                   [disabled]="!carIsLogged"
                   (click)="removeCar()">
            <wb-icon slot="icon" name="bds/close/24"></wb-icon>
            <span zkTranslate="CARCHOOSER.LOGOUT_CAR"></span>
        </wb-button>

        <wb-button *ngIf="!isVinLayer" variant="primary" class="button-login-car"
                   [disabled]="loggedCarSameAsSelected || !this.currentCarLine"
                   (click)="loginCar()"
                   zkTranslate="CARCHOOSER.LOGIN_CAR">
        </wb-button>

    </div>

    <div class="close-layer" (click)="closeLayer()">
        <wb-round-button [size]="isMobile ? 's' : 'm'">
            <wb-icon name="close"></wb-icon>
        </wb-round-button>
    </div>
</div>
