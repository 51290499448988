<article class="product" *ngIf="article">

    <!--    LEFT SIDE / Gallery  -->
    <section class="product_gallery_wrapper" [style.min-height.px]="galleryMinHeightMobile">
        <wb-spinner *ngIf="!galleryLoaded" class="loading-spinner" theme="light"></wb-spinner>

        <div class="product_gallery" [ngClass]="{'gallery-loaded': galleryLoaded}">

            <!--   Gallery MQ3+  -->
            <ng-container *ngIf="!(mq1 || mq2)">
                <img class="product_gallery_item"
                     *ngFor="let image of activeArticle.images"
                     [src]="image.big" [alt]="activeArticle.headline"
                     imgLoaded
                     (loaded)="image.loaded = $event">
            </ng-container>

            <!--   Gallery Mobile  -->
            <ng-container *ngIf="mq1 || mq2">
                <wb-slider scroll-snap show-arrow-navigation show-dot-navigation #galleryEl>
                    <wb-slider-item *ngFor="let image of activeArticle.images" class="is-full-width">
                        <img class="wb-slider__item product_gallery_item"
                             imgLoaded
                             [src]="image.medium" [alt]="activeArticle.headline">
                    </wb-slider-item>
                </wb-slider>
            </ng-container>
        </div>
    </section>

    <!--    RIGHT SIDE / Product details    -->
    <section class="product_details_wrapper">

        <!--    Sticky area     -->
        <div class="product_details">

            <!--    Color Variants Mobile    -->
            <div class="product_details_variant"
                 *ngIf="(mq1 || mq2) && article.variantPropertiesCollection?.colors">
                <div class="variant_color_thumb" [ngClass]="{'is-active' : colorVariant.isActive}"
                     *ngFor="let colorVariant of article.variantPropertiesCollection.colors"
                     (click)="getVariantArticleData(colorVariant, variantType.COLOR)">
                    <img [src]="colorVariant.image.thumb" [alt]="colorVariant.name">
                </div>
            </div>

            <nav class="breadcrumbs">
                <wb-link *ngFor="let group of breadcrumbs" [routerLink]="group.groupUrl"
                         variant="tiny" class="breadcrumb-item">{{ group.groupName }}</wb-link>
            </nav>

            <div class="product_details_headline_and_price">
                <h1 class="product_details_headline">{{ activeArticle.headline }}</h1>

                <div class="product_details_price wb-price wb-price--large">
                    <wb-price size="l" price="{{ articlePrice }}"></wb-price>
                </div>
            </div>

            <!--    Color Variants Desktop    -->
            <div class="product_details_variant">
                <p *ngIf="article.propertiesCollection.color">
                    <span class="label" zkTranslate="PRODUCTDETAILS.COLOUR"></span>: {{ activeArticle.propertiesCollection.color }}
                </p>

                <ng-container *ngIf="!(mq1 || mq2) && article.variantPropertiesCollection?.colors">
                    <div class="variant_color_thumb" [ngClass]="{'is-active' : colorVariant.isActive}"
                         *ngFor="let colorVariant of article.variantPropertiesCollection.colors"
                         (click)="getVariantArticleData(colorVariant, variantType.COLOR)">
                        <img [src]="colorVariant.image.thumb" [alt]="colorVariant.name">
                    </div>
                </ng-container>
            </div>

            <!--    Size Variants    -->
            <div class="product_details_variant">
                <ng-container *ngIf="article.variantPropertiesCollection?.sizes">
                    <p class="label" zkTranslate="LIFESTYLE.GENERAL.SIZES"></p>

                    <ng-container *ngFor="let sizeVariant of activeArticle.variantPropertiesCollection.sizes">
                        <span *ngIf="sizeVariant.hint != 'various'"
                              [ngClass]="{ 'is-active' : sizeVariant.isActive }"
                              class="variant_size"
                              (click)="getVariantArticleData(sizeVariant, variantType.SIZE)">
                            {{ sizeVariant.name }}
                        </span>
                    </ng-container>
                </ng-container>

                <p *ngIf="!article.variantPropertiesCollection?.sizes && article.propertiesCollection.size">
                    <span class="label">Size: </span>{{ article.propertiesCollection.size }}
                </p>

            </div>

            <!--    Size Charts available    -->
            <div *ngIf="article.propertiesCollection?.sizeChart" class="product_details_variant">
                <wb-link [href]="article.propertiesCollection.sizeChart" variant="standalone"
                         zkTrackClick="size_chart_download"
                         zkTrackClickPosition="pdp"
                         zkTrackClickValue="{{article.articleId}}">
                    <wb-icon name="bds/download/24" slot="icon" style="--size: 24px"></wb-icon>
                    <span zkTranslate="LIFESTYLE.SIZECHART"></span>
                </wb-link>
            </div>

            <div class="action_buttons">
                <wb-button variant="primary"
                        (click)="addRemoveFromCart()">
                    <wb-icon src="ui/assets/icons/misc/star-filled.svg" *ngIf="wasAddedToCart" class="wb-button__icon"></wb-icon>
                    <wb-icon src="ui/assets/icons/misc/star-outline.svg" *ngIf="!wasAddedToCart" class="wb-button__icon"></wb-icon>
                    <span *ngIf="!wasAddedToCart" zkTranslate="GENERAL.ADDTOWISHLIST"></span>
                    <span *ngIf="wasAddedToCart" zkTranslate="LIFESTYLE.GENERAL.REMOVE"></span>
                </wb-button>
                <wb-button variant="secondary"
                        [disabled]="pdfDownloadInProgress"
                        (click)="printPDF()"
                           zkTrackClick="pdf-download"
                           zkTrackClickPosition="pdp"
                           zkTrackClickValue="{{article.articleId}}">
                    <wb-icon *ngIf="!pdfDownloadInProgress" class="wb-button__icon" name="bds/download-print/24"></wb-icon>
                    <wb-spinner *ngIf="pdfDownloadInProgress" class="loading-spinner" theme="light"></wb-spinner>
                    <span zkTranslate="PRODUCTDETAILS.PDFDOWNLOAD"></span>
                </wb-button>
            </div>

            <div [ngClass]="marketHasShopLink() ? 'cards-wrapper has-two-cards' : 'cards-wrapper'">
                <zk-shop-link *ngIf="marketHasShopLink()" class="card"></zk-shop-link>
                <zk-dealer-locator-link class="card"></zk-dealer-locator-link>
            </div>

            <wb-accordion prevent-mobile-flyout class="accordion">
                <wb-accordion-item *ngIf="activeArticle.description"
                                   zkTrackClick="article_info"
                                   zkTrackClickPosition="pdp"
                                   zkTrackClickLabel="description"
                                   zkTrackClickValue="{{article.articleId}}">
                    <span slot="header"><span zkTranslate="PRODUCTDETAILS.DESCRIPTION"></span></span>
                    <span slot="mobile-header">Close</span>
                    <div slot="content"><wb-text size="l" [innerHtml]="activeArticle.description"></wb-text></div>
                </wb-accordion-item>

                <wb-accordion-item *ngIf="activeArticle.propertiesCollection?.careInstructions ||
                                          activeArticle.propertiesCollection?.materialWithProportion ||
                                          activeArticle.materialText"
                                   zkTrackClick="article_info"
                                   zkTrackClickPosition="pdp"
                                   zkTrackClickLabel="care-instructions"
                                   zkTrackClickValue="{{article.articleId}}">
                    <span slot="header"><span zkTranslate="LIFESTYLE.PDP.MATERIAL.CAREINSTRUCTIONS"></span></span>
                    <span slot="mobile-header">Close</span>
                    <div slot="content">
                        <wb-text *ngIf="activeArticle.materialText" size="l">{{ activeArticle.materialText }}</wb-text>

                        <ng-container *ngIf="activeArticle.propertiesCollection.materialWithProportion">
                            <span *ngFor="let item of activeArticle.propertiesCollection.materialWithProportion;
                                          let i = index">
                                {{ item.proportion }}% {{ item.material }}{{ i < (activeArticle.propertiesCollection.materialWithProportion.length - 1) ? ',' : '' }}
                            </span>
                        </ng-container>

                        <ng-container *ngIf="activeArticle.propertiesCollection.careInstructions">
                            <div class="care-instruction"
                                 *ngFor="let item of activeArticle.propertiesCollection.careInstructions">
                                <img src="ui/assets/icons/care-instructions/{{item.key}}.png" alt="">
                                <span>{{ item.text }}</span>
                            </div>
                        </ng-container>
                    </div>
                </wb-accordion-item>

                <wb-accordion-item *ngIf="activeArticle.propertiesCollection ||
                                          activeArticle.dimensions ||
                                          activeArticle.articleId"
                                   zkTrackClick="article_info"
                                   zkTrackClickPosition="pdp"
                                   zkTrackClickLabel="more-information"
                                   zkTrackClickValue="{{article.articleId}}">
                    <span slot="header"><span zkTranslate="LIFESTYLE.PDP.PRODUCTINFORMATION"></span></span>
                    <span slot="mobile-header">Close</span>
                    <div slot="content" class="product-information">
                        <div *ngIf="activeArticle.articleId">
                            <wb-text size="l" zkTranslate="PRODUCTDETAILS.ARTICLENUMBER"></wb-text>
                            <wb-text size="l">{{ activeArticle.articleId }}</wb-text>
                        </div>
                        <div *ngIf="activeArticle.propertiesCollection.ageRange">
                            <wb-text size="l" zkTranslate="LIFESTYLE.PDP.AGERANGE"></wb-text>
                            <wb-text size="l">{{ activeArticle.propertiesCollection.ageRange }}</wb-text>
                        </div>
                        <div *ngIf="activeArticle.propertiesCollection.selection">
                            <wb-text size="l" zkTranslate="LIFESTYLE.PDP.SELECTION"></wb-text>
                            <wb-text size="l">{{ activeArticle.propertiesCollection.selection }}</wb-text>
                        </div>
                        <div *ngIf="activeArticle.propertiesCollection.collection">
                            <wb-text size="l" zkTranslate="LIFESTYLE.GENERAL.COLLECTION"></wb-text>
                            <wb-text size="l">{{ activeArticle.propertiesCollection.collection }}</wb-text>
                        </div>
                        <div *ngIf="activeArticle.propertiesCollection.sport">
                            <wb-text size="l" zkTranslate="LIFESTYLE.PDP.SPORT"></wb-text>
                            <wb-text size="l">{{ activeArticle.propertiesCollection.sport }}</wb-text>
                        </div>
                        <div *ngIf="activeArticle.propertiesCollection.manufacturer">
                            <wb-text size="l" zkTranslate="LIFESTYLE.PDP.MANUFACTURER"></wb-text>
                            <wb-text size="l">{{ activeArticle.propertiesCollection.manufacturer }}</wb-text>
                        </div>
                        <div *ngIf="activeArticle.dimensions.item.weight">
                            <wb-text size="l" zkTranslate="PRODUCT.PROP.WEIGHT"></wb-text>
                            <wb-text size="l">{{ activeArticle.dimensions.item.weight }}</wb-text>
                        </div>
                        <div *ngIf="activeArticle.dimensions.item.length && activeArticle.dimensions.item.width && activeArticle.dimensions.item.height">
                            <wb-text size="l" zkTranslate="PRODUCT.PROP.DIMENSIONS"></wb-text>
                            <wb-text size="l">
                                {{ activeArticle.dimensions.item.length }} / {{ activeArticle.dimensions.item.width }} / {{ activeArticle.dimensions.item.height }}
                            </wb-text>
                        </div>
                        <div *ngIf="activeArticle.dimensions.shipping.length && activeArticle.dimensions.shipping.width && activeArticle.dimensions.shipping.height">
                            <wb-text size="l" zkTranslate="PRODUCT.PROP.PACKAGINGSIZE"></wb-text>
                            <wb-text size="l">
                                {{ activeArticle.dimensions.shipping.length }} / {{ activeArticle.dimensions.shipping.width }} / {{ activeArticle.dimensions.shipping.height }}
                            </wb-text>
                        </div>
                    </div>
                </wb-accordion-item>
            </wb-accordion>
        </div>
    </section>
</article>
