import { Component } from '@angular/core';
import { ProductGroupsService } from "@shared/shared-services/product-groups/product-groups.service";
import { Subscription } from "rxjs";

@Component({
    selector: 'zk-lifestyle',
    templateUrl: './lifestyle.component.html',
    styleUrls: ['./lifestyle.component.scss']
})
export class LifestyleComponent {
    groupsLoaded = false;

    constructor(private _productGroupsService: ProductGroupsService) {}

    ngOnInit(): void {
        const sub: Subscription = this._productGroupsService.getAllProductGroups().subscribe(() => {
            sub.unsubscribe();
            this.groupsLoaded = true;
        });
    }
}
