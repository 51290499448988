<zk-popup-modal id="dealer-locator-modal"
                [isWider]="true"
                (onOpen)="initComponent = true">

    <div modalContent class="content">
        <iframe *ngIf="initComponent"
                [src]="url"
                width="100%"
                height="720"
                style="border: none">
        </iframe>
    </div>
</zk-popup-modal>
