// ng
import { AfterContentChecked, Directive, ElementRef, EventEmitter, Input, Output } from '@angular/core';

@Directive({
    selector: '[mbaGetMaxHeight]'
})
export class GetMaxHeightDirective implements AfterContentChecked {
    /**
     * CSS class name of the child items (markup elements) that should be checked for their height.
     * This is mandatory.
     */
    @Input('mbaGetMaxHeight') cssClassName: string;

    /**
     * Event that will pass the height of the tallest child inside the container.
     * Useful to calculate the target height of containers with different content (like accordion) for animations.
     * Note: Event is emitted only when there is a value change.
     */
    @Output() public maxHeightChanged: EventEmitter<number> = new EventEmitter();

    /**
     * Event that will pass the complete sum of all height values from each child.
     * Useful to calculate the full target height of a container (like dropdown) for animations.
     * Note: Event is emitted only when there is a value change.
     */
    @Output() public sumOfHeight: EventEmitter<number> = new EventEmitter();

    private _lastMaxHeight: number = 0;
    private _lastSumOfHeight: number = 0;

    constructor(private el: ElementRef) {}

    ngAfterContentChecked(): void {
        this.checkHeightOfChildren(this.el.nativeElement, this.cssClassName);
    }

    private checkHeightOfChildren(parent: HTMLElement, cssClassName: string) {
        if (!parent) {
            return;
        }

        // find all the child elements with the selected class name
        const children = parent.getElementsByClassName(cssClassName);

        // failsafe
        if (!children) {
            return;
        }

        // get all the child elements heights
        const itemHeights = Array.from(children).map((x) => x.getBoundingClientRect().height);

        // failsafe
        if (itemHeights.length === 0) {
            return;
        }

        // find out the tallest element
        const maxHeight = itemHeights.reduce((prev, curr) => {
            return curr > prev ? curr : prev;
        }, 0);

        // get sum of heights
        const sumOfHeight = itemHeights.reduce((pv, cv) => pv + cv, 0);

        // inform
        if (maxHeight !== this._lastMaxHeight) {
            this._lastMaxHeight = maxHeight;
            this.maxHeightChanged.emit(maxHeight);
        }

        if (sumOfHeight !== this._lastSumOfHeight) {
            this._lastSumOfHeight = sumOfHeight;
            this.sumOfHeight.emit(sumOfHeight);
        }
    }
}
