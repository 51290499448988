// ng
import { Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

// model
import { Article } from '@shared/global-models/article/article.model';
import { VariantPropertyCollection } from '@shared/global-models/article/articleVariantCollection.model';
import {
	Notification,
	NotificationActions,
	NotificationIcons,
	NotificationTypes
} from '@shared/components/notification/notification.model';

// services
import { CurrentRouteService } from '@shared/shared-services/current-route/current-route.service';
import { ShoppingCartService } from '@shared/shared-services/shopping-cart/shopping-cart.service';
import { ProductListService } from '@shared/shared-services/product-list/product-list.service';
import { NotificationService } from '@shared/components/notification/notification.service';
import { TranslationService } from '@shared/shared-services/translate/translation.service';
import { ViewportService } from "@shared/shared-services/viewport/viewport.service";

@Component({
    selector: 'zk-product-teaser-card',
    templateUrl: './product-teaser-card.component.html',
    styleUrls: ['./product-teaser-card.component.scss']
})
export class ProductTeaserCardComponent implements OnInit, OnDestroy {
    @Input() article: Article;
    @Input() isCollection = true;
    @Input() limitWidth = false;

    @ViewChild('headline') headline: ElementRef
    @ViewChild('tooltip') toolTip: ElementRef

    imageTarget = '';
    wasAddedToCart = false;
	articleVariantsInCart: Article[] = [];
    readonly VARIANTS_LIMIT: number = 4;

	private _shoppingCartChangedSubscription: Subscription;

    constructor(private _currentRouteService: CurrentRouteService,
                private _shoppingCartService: ShoppingCartService,
				private _pdlService: ProductListService,
				private _notificationService: NotificationService,
				private _translateService: TranslationService,
                private _vpService: ViewportService,
                private _renderer: Renderer2) {}

	ngOnInit(): void {
		this.imageTarget = this.isCollection ? this.article.images[0].medium : this.article.image480;
		this.checkIfArticleExistsInCart();

		if (this.article.variantPropertiesCollection?.colors) {
			this.sortVariantImages(this.article.variantPropertiesCollection.colors);
		}

		this._shoppingCartChangedSubscription = this._shoppingCartService.shoppingCartChanged.subscribe(() => {
			this.checkIfArticleExistsInCart();
		});

        // limit displayed amount of accessories article variants
        if (!this.isCollection && this.article?.variantArticles?.length > 0) {
            this.article.variantArticles = this.article.variantArticles.slice(0, this.VARIANTS_LIMIT);
        }
	}

	addRemoveFromCart(): void {
		// Remove this.article if already in cart and not in cartItems articleVariants
		if (this.wasAddedToCart && this.articleVariantsInCart.length === 0) {
			this._shoppingCartService.removeItemFromShoppingCart([this.article.articleId], true);

		// Remove articleVariant if only one in cart and this.article not in cart
		} else if (!this.wasAddedToCart && this.articleVariantsInCart.length === 1) {
			this._shoppingCartService.removeItemFromShoppingCart([this.articleVariantsInCart[0].articleId], true);

		// If there are more article variants in cart, we are not able to unselect active article
		} else if (this.wasAddedToCart && this.articleVariantsInCart.length >= 1 || this.articleVariantsInCart.length > 1) {
			this._notificationService.triggerNotification(new Notification(
				NotificationTypes.NONE,
				NotificationIcons.ADD_TO_CART,
				this._translateService.translate('LIFESTYLE.NOTIFICATION.MOREVARIANTSINWISHLIST'),
				this._translateService.translate('LIFESTYLE.NOTIFICATION.OPENWISHLIST'),
				NotificationActions.GOTO_CART));
			return;

		// If no item in cart nor in cartItems variants, add it to the cart
		// Note: we need to fetch article again because current one have not articleVariants
		} else if (this.isCollection) {
			const sub: Subscription = this._pdlService.getCollectionArticleDetails(this.article.articleId).subscribe(
				(response: Article) => {
					if (response) {
						let cartArticle: Article = response;

						cartArticle.sizeSelected = this.article.sizeSelected ? this.article.sizeSelected : this.selectItemWithSingleSize();

						// Date will serve as sort helper in the wishlist
						cartArticle.dateAdded = Date.now();
						this._shoppingCartService.addItemsToShoppingCart([cartArticle], 1, true);
					}

					sub.unsubscribe();
				},
				(error) => {
					sub.unsubscribe();
					this._notificationService.triggerNotification(
						new Notification(NotificationTypes.ERROR, NotificationIcons.WARNING, this._translateService.translate('ERRORMESSAGE.SERVICEUNAVAILABLE')));

					console.log('Failed to fetch article data: ', error);
				}
			);
		}
        // case: ACC mode
        else {
            this._shoppingCartService.addItemsToShoppingCart([this.article], 1, true);
        }
	}

	checkIfArticleExistsInCart() {
		const cartItems = this._shoppingCartService.currentShoppingCart;

		this.articleVariantsInCart = [];

		// First we check if this.article exists in cart
		this.wasAddedToCart = !!this._shoppingCartService.getArticleFromCartById(this.article.articleId);

		// Then we check if this.article exists in cartItems articleVariants
		if (cartItems && cartItems.items) {
			cartItems.items.map((article: Article) => {
				article.variantArticles?.map((articleVariant: Article) => {
					if (articleVariant.articleId === this.article.articleId) {
						// Save articles which contain this article in its articleVariants
						// in order to remove articleVariant if this article is not in the cart
						this.articleVariantsInCart.push(article);
					}
				});
			});
		}
	}

    /**
     * Builds the route to the Product Detail Page when article is clicked
     */
    routeToProductDetails(variantArticle: Article = null): void {
        this._currentRouteService.routeToProductDetails(variantArticle ? variantArticle : this.article);
    }

    onHoverMainImage(hovered: boolean): void {
        if (this.isCollection) {
            this.imageTarget = hovered
                ? this.article?.images.length > 1
                    ? this.article.images[1].medium // side image
                    : this.article.images[0].medium // main image
                : this.article.images[0].medium; // main image
        }
    }

    onHoverSideImage(hovered: boolean, variant: VariantPropertyCollection, index: number): void {
		if (index != 0) {
			this.imageTarget = hovered ? variant.image.medium : this.article.images[0].medium;
		}
    }

	setPrice() {
		return this.article.variantPropertiesCollection?.prices?.various ? this._translateService.translate('LIFESTYLE.GENERAL.FROM') + ' ' +
			this.article.variantPropertiesCollection.prices.minGrossText : this.article.grossPriceText;
	}

    isMobile(): boolean {
        return this._vpService.getCurrentViewPort() === 'mq1';
    }

    onHoverVariant(hovered: boolean, variant?: Article) {
        this.imageTarget = hovered ? variant.image480 : this.article.image480;
    }

    /**
     * Checks if the headline is truncated via CSS and needs a tooltip to display the full length of the headline text
     * @param hovered - Shows a tooltip if needed or removes an eventual shown tooltip
     */
    onHoverHeadline(hovered: boolean) {
        if (this.isMobile()) {
            return;
        }

        if (!hovered) {
            this._renderer.removeAttribute(this.toolTip.nativeElement, 'open');

            return;
        }

        const isEllipsisActive = (e) => {
            return (e.offsetWidth < e.scrollWidth);
        }

        const showTooltip = isEllipsisActive(this.headline.nativeElement);
        if (showTooltip) {
            this._renderer.setAttribute(this.toolTip.nativeElement, 'open', '');
        }
    }

	/**
	 * UseCase: If article have one size only
	 * select that size in order to show its price in cart
	 * and not price 'Ab' if item would not be selected and have various prices
	 */
	private selectItemWithSingleSize(): string {
		if(this.article.variantPropertiesCollection?.sizes?.length === 1) {
			return this.article.variantPropertiesCollection.sizes[0].name;
		}
	}

	private sortVariantImages(array: VariantPropertyCollection[]) {
		const selfColorIndex = array.findIndex(color => color.hint === 'self');

		// Reorder only if not already on 1st place
		if (selfColorIndex != 0) {
			const selfColorItem = array.splice(selfColorIndex, 1)[0];
			array.splice(0, 0, selfColorItem);
		}
	}

	ngOnDestroy(): void {
		if (this._shoppingCartChangedSubscription) {
			this._shoppingCartChangedSubscription.unsubscribe();
		}
	}
}
