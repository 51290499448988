// ng
import { Component, OnInit } from '@angular/core';
// services
import { TranslationService } from '@shared/shared-services/translate/translation.service';
import { AppService } from "../../../app.service";
// mode
import { MODE } from "@shared/global-models/mode.enum";


@Component({
    selector: 'zk-shop-link',
    templateUrl: './shop-link.component.html',
    styleUrls: ['./shop-link.component.scss']
})
export class ShopLinkComponent implements OnInit {
    url = '';
    isAccessories: boolean;

    constructor(private _appService: AppService,
                private _translate: TranslationService) {
    }

    ngOnInit(): void {
        this.isAccessories = this._appService.appMode === MODE.ACCESSORIES;

        const key = 'SHOP_LINK_URL_' + (this.isAccessories ? 'ACC' : 'COLL');
        this.url = this._translate.translate(key);
    }
}
