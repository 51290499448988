// ng
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

// services
import { TeaserService } from '@shared/shared-services/teaser/teaser.service';
import { SeoService } from '@shared/shared-services/seo/seo.service';
import { GoogleAnalyticsService } from "@shared/shared-services/google-analytics/google-analytics.service";

// models
import { Slider } from '@shared/global-models/wb-models/wb-slider.model';
import { ProductTeaserBig } from '@shared/global-models/wb-models/wb-product-teaser-big.model';
import { TextTeaser } from '@shared/global-models/textTeaser.model';
import { PageName } from "@shared/shared-services/google-analytics/google-analytics.model";

@Component({
    selector: 'zk-collection-start-page',
    templateUrl: './collection-start-page.component.html',
    styleUrls: ['./collection-start-page.component.scss']
})
export class CollectionStartPageComponent implements OnInit, AfterViewInit {
    slides: Slider[];
    textTeaser: TextTeaser;
    productTeaserBig: ProductTeaserBig;
    firstSlideHeadline: string;

    theme: 'dark' | 'light' = 'light';

    constructor(private _teaserService: TeaserService,
                private _seoService: SeoService,
                private _gaService: GoogleAnalyticsService) {}

    ngOnInit(): void {
        this.getSliderImages();
        this.getTextTeaser();
        this.getProductTeaserBig();
    }

    ngAfterViewInit() {
        this._gaService.trackPageView(PageName.START_PAGE);
    }

    trackStageSlider(url: string) {
        this._gaService.trackStageSlider(url);
    }

    /**
     * Get Stage Slider images from CMS
     */
    private getSliderImages(useFallbackLang?: boolean): void {
        const sub = this._teaserService.getSlides(undefined, useFallbackLang, true).subscribe(
            (response: any) => {
                sub.unsubscribe();

                if (response.slides.length > 0) {
                    this.slides = response.slides;
                    this.firstSlideHeadline = response.slides[0].headline;
                    this.setSeoInfo();

                    // Set initial Slider theme
                    // Note: BE delivers theme for each slide. We set parent Slider theme also same as
                    // current slide dynamically by handleTheme() wbchange event.
                    // Note: In CMS if text color is set to black, FE need to use light theme.
                    this.theme = this.slides[0].isTextBlack ? 'light' : 'dark';

                } else if (!useFallbackLang) {
                    console.log('No sliders found for current market. Needs to be added in CMS. Using fallback to default global market "en-MB", therefore.');
                    this.getSliderImages(true);
                }
            },
            (error) => {
                sub.unsubscribe();
                console.error('Failed to get Slider from CMS', error);
            }
        );
    }

    private getTextTeaser(): void {
        const sub: Subscription = this._teaserService.getCmsTextForTextTeaser().subscribe(
            (response: TextTeaser) => {
                sub.unsubscribe();
                this.textTeaser = response;
            },
            (error) => {
                sub.unsubscribe();
                console.log('Error: ', error);
            }
        );
    }

    private getProductTeaserBig(): void {
        const sub: Subscription = this._teaserService.getCmsDataForProductTeaserBig().subscribe(
            (response: ProductTeaserBig) => {
                sub.unsubscribe();
                this.productTeaserBig = response;
            },
            (error) => {
                sub.unsubscribe();
                console.log('Error: ', error);
            }
        );
    }

    private setSeoInfo() {
        const title: string = this._seoService.getTranslation('ACCESSORIES.FLYOUT.TEASER.TITEL');
        this._seoService.updatePageTitle(title + ' | ' + this.firstSlideHeadline);
        this._seoService.updateMetaDescription(this._seoService.getTranslation('LIFESTYLE.SEO.METADESCRIPTION.STARTPAGE'));
    }

    handleTheme(e: any) {
        let currentSlide = e.detail - 1;
        this.theme = this.slides[currentSlide].isTextBlack ? 'light' : 'dark';
    }
}
