// NG
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

// Modules
import { LegalPagesModule } from './legal/legal-pages.module';
import { SharedModule } from '@shared/shared.module';
import { WheelCategoriesModule } from "../assortment/accessories/pages/sub-category-page/wheel-category/wheel-categories.module";

// components
import { FileNotFoundPageComponent } from './404/file-not-found-page.component';
import { CampaignComponent } from './campaign/campaign.component';
import { SearchResultsPageComponent } from "./search-results-page/search-results-page.component";
import { SearchResultsListPageComponent } from "./search-results-page/search-results-list-page/search-results-list-page.component";
import { SearchNotFoundPageComponent } from "./search-results-page/search-not-found-page/search-not-found-page.component";

@NgModule({
  declarations: [
    FileNotFoundPageComponent,
    CampaignComponent,
    SearchResultsPageComponent,
    SearchResultsListPageComponent,
    SearchNotFoundPageComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule,
    LegalPagesModule,
    SharedModule,
    WheelCategoriesModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PagesModule { }
