// NG
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

// Modules
import { ComponentsModule } from '@shared/components/components.module';

// Components
import { ArticleListComponent } from '@shared/modals/compatibility-feedback-modal/article-list/article-list.component';
import { PopupModalComponent } from "@shared/modals/popup-modal/popup-modal.component";
import { CategoryNotAvailableModalComponent } from '@shared/modals/category-not-available-modal/category-not-available-modal.component';
import { CompatibilityFeedbackModalComponent } from '@shared/modals/compatibility-feedback-modal/compatibility-feedback-modal.component';
import { ResetWarningModalComponent } from '@shared/modals/reset-warning-modal/reset-warning-modal.component';
import { RimSetQuantityModalComponent } from '@shared/modals/rim-set-quantity-modal/rim-set-quantity-modal.component';
import { DealerLocatorModalComponent } from '@shared/modals/dealer-locator-modal/dealer-locator-modal.component';

// Services
import { ResetWarningModalService } from '@shared/modals/reset-warning-modal/reset-warning-modal.service';
import { PopupModalService } from "@shared/modals/popup-modal/popup-modal.service";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        ComponentsModule
    ],
    declarations: [
        PopupModalComponent,
        CategoryNotAvailableModalComponent,
        CompatibilityFeedbackModalComponent,
        ResetWarningModalComponent,
        RimSetQuantityModalComponent,
        DealerLocatorModalComponent,
        ArticleListComponent
    ],
    exports: [
        CategoryNotAvailableModalComponent,
        CompatibilityFeedbackModalComponent,
        ResetWarningModalComponent,
        RimSetQuantityModalComponent,
        DealerLocatorModalComponent
    ],
    providers: [
        ResetWarningModalService,
        PopupModalService
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ModalModule {}
