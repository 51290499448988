import { Component, Input, OnInit } from '@angular/core';
import { Article } from '@shared/global-models/article/article.model';
import { MODE } from "@shared/global-models/mode.enum";
import { SelectedCarService } from "@shared/shared-services/selected-car/selected-car.service";
import { VIN } from "@shared/components/car-chooser/models";
import { GroupMapperService } from "@shared/shared-services/group-mapper/group-mapper.service";
import { ProductGroup } from "@shared/global-models/productGroup.model";
import { ProductGroupsService } from "@shared/shared-services/product-groups/product-groups.service";
import { PopupModalService } from "@shared/modals/popup-modal/popup-modal.service";

@Component({
    selector: 'zk-article-list',
    templateUrl: './article-list.component.html',
    styleUrls: ['./article-list.component.scss']
})
export class ArticleListComponent implements OnInit {
    @Input() articles: Article[];

    vinVehicle: VIN = null;
    selectedCar: string;

    get appMode() {
        return MODE;
    }

    constructor(private _selectedCarService: SelectedCarService,
                private _groupMapperService: GroupMapperService,
                private _productGroupsService: ProductGroupsService,
                private _modalService: PopupModalService) {
    }

    ngOnInit(): void {
        this.vinVehicle = this._selectedCarService.vinVehicle;
        this.selectedCar = this._selectedCarService.carLine.urlName;
    }

    // Defines visibility for "Search for Alternative" button
    // if a group for a new car available or not
    checkArticleGroupAvailability(article: Article) {
        return this._productGroupsService.groups.find((group: ProductGroup) => group.groupId === article.groupIdPath[0]);
    }

    // Build button URL based on new car and its category groups
    buildAlternativeGroupUrl(article: Article): string {
        const categoryUrl = this.buildArticleCategories(article);

        return `${categoryUrl}`;
    }

    /**
     * Builds the path for the category that belongs to clicked article
     * @param article item for which an alternative should be found
     */

    buildArticleCategories(article: Article): string {
        let groupName = this._groupMapperService.getGroupUrlNameFromGroupId(article.groupIdPath[0]);
        if (groupName) {
            const subGroupName = this._groupMapperService.getGroupUrlNameFromGroupId(article.groupIdPath[1]);
            if (subGroupName) {
                groupName = `${groupName}/${subGroupName}`
            }
        }

        // After a new car is logged in, there is a possibility that the group
        // the unfitting article belongs to, not exist for the new car
        return groupName ? `/${this.selectedCar}/${groupName}` : `/${this.selectedCar}`;
    }

    closeLayer() {
        this._modalService.close('compatibility-feedback');
    }
}
