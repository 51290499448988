// Ng
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// Services
import { AppService } from '../../../app.service';
import { TranslationService } from '@shared/shared-services/translate/translation.service';

// Model
import { MODE, SUBMODE } from "@shared/global-models/mode.enum";

// TODO refactor to v17

@Injectable()
export class CanActivateAmgGuard implements CanActivate {
    constructor(private _appService: AppService,
                private _router: Router,
                private _translationService: TranslationService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean|UrlTree>|Promise<boolean|UrlTree>|boolean|UrlTree {
        if (!this._appService.currentMarket) {
            return this._appService.marketsLoaded.pipe(map( () => {
                return this.redirectToAccessories();
            }));
        } else {
            return this.redirectToAccessories();
        }
    }

    /**
     * Redirect to passenger cars mode of Accessories if market has no AMG mode support / configuration.
     * @private
     */
    private redirectToAccessories(): boolean {
        if (!this._appService.currentMarket.amgMarket) {
            console.log('AMG mode not supported for market - redirect to Accessories');
            let url = this._translationService.currentLang + MODE.ACCESSORIES;
            // US market has only vans enabled
            url += this._appService.currentMarket.marketCode === 'US' ? SUBMODE.VANS : SUBMODE.PASSENGERCARS

            this._router.navigate([url]).then(() => { return false });
        } else {
            return true;
        }
    }
}
