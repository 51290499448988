// NG
import { Component, OnInit, Input, OnDestroy } from '@angular/core';

// Components
import { MultiselectComponent } from '../multiselect.component';
import { ComponentTheme } from '../model/ComponentTheme';

@Component({
    selector: 'mba-multiselect-item',
    templateUrl: 'multiselect-item.component.html',
    styleUrls: ['./multiselect-item.component.scss']
})
export class MultiselectItemComponent implements OnInit, OnDestroy {

    /**
     * Set the title for this multiselect.
     */
    @Input() title: string = '';

    /**
     * Set the id for this multiselect to use for better comparasion.
     */
    @Input() id: string = '';

    /**
     * Define object or property from object which will be emitted when multiselect is clicked.
     */
    @Input() eventOnActive: any;

    /**
     *  Defines if the multiselect is selected initially. Default is false.
     */
    @Input() isSelected: boolean = false;

    /**
     * Change theme (light, grey, dark). Default is light.
     */
    @Input() theme: string = ComponentTheme.LIGHT;

    /**
     * Shares enum to view
     */
    ComponentTheme = ComponentTheme;

    /**
     * Inject the parent component
     * @param multiselectHolder MultiselectComponent
     */
    constructor(private multiselectHolder: MultiselectComponent) {}

    /**
     * Add this item to the parent
     */
    ngOnInit() {
        this.multiselectHolder.addItem(this);
    }

    /**
     * Toggle select state
     */
    toggleItem() {
        this.multiselectHolder.toggleItem(this);
    }

    /**
     * Removes this item
     */
    ngOnDestroy(): void {
        this.multiselectHolder.removeItem(this);
    }
}
