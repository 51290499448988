// ng
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
// service
import { TranslationService } from '@shared/shared-services/translate/translation.service';
import { SelectedCarService } from '@shared/shared-services/selected-car/selected-car.service';
// model
import { Article } from '@shared/global-models/article/article.model';
import { CollectionCategoryTeaser } from '@shared/components/collection-category-teaser/collection-category-teaser.model';
import { ProductTeaserBig } from '@shared/global-models/wb-models/wb-product-teaser-big.model';
import { TextTeaser } from '@shared/global-models/textTeaser.model';
import { Image } from '@shared/global-models/image.model';

@Injectable()
export class TeaserService {
    mainCategoryImage: Image;

    get isAmg(): boolean {
        return this._selectedCarService.vehicleType?.vehicleTypeId === 'amg';
    }

    constructor(
        private httpClient: HttpClient,
        private _translationService: TranslationService,
        private _selectedCarService: SelectedCarService
    ) {}

    // *** ACCESSORIES *** //

    /**
     * Returns an image that is car related and can be used for the Single Stage Wide Component - Used for the model start page.
     * @param {string} carLineId
     * @returns {Observable<Image>}
     */
    getHighlightsImageData(carLineId: string): Observable<Image> {
        const param = this.isAmg ? `amg/${carLineId}/${this._selectedCarService.modelDesign.modelDesignId}` : carLineId;
        return this.httpClient.get<Image>(`api/highlights/vehicle-image/${this._translationService.currentLang}/${param}`);
    }

    /**
     * Returns an Article with first highest click count (defined by backend).
     * @param {string} carLineId
     * @returns {Observable<Article>}
     * deprecated
     */
    getHighlightsArticleData(carLineId: string): Observable<Article> {
        const param = this.isAmg ? `amg/${carLineId}/${this._selectedCarService.modelDesign.modelDesignId}` : carLineId;
        return this.httpClient.get<Article>(`api/highlights/article/${this._translationService.currentLang}/${param}`);
    }

    /**
     * Returns a list of Articles starting with second highest click count (defined by backend), followed by articles with lower click counts.
     * Maximum number of articles is 10.
     * @param {string} carLineId
     * @returns {Observable<Article[]>}
     * deprecated
     */
    getHighlightsArticlesListData(carLineId: string): Observable<Article[]> {
        const param = this.isAmg ? `amg/${carLineId}/${this._selectedCarService.modelDesign.modelDesignId}` : carLineId;
        return this.httpClient.get<Article[]>(`api/highlights/articles/${this._translationService.currentLang}/${param}`);
    }

    /**
     * Returns a list of Articles starting with first highest click count, followed by articles with lower click counts.
     * Maximum number of articles is 16.
     * Maximum number of articles in same top level category is 2.
     * Articles from many categories (and lower click counts) instead of articles with highest click counts (and same categories) is intended behavior.
     * @param relatedArticleId. Optional.
     */
    getAllHighlightsArticles(relatedArticleId: string = null): Observable<Article[]> {
        let carLineIdOrType: string = this._selectedCarService.carLine ?
            this._selectedCarService.carLine.carLineId : this._selectedCarService.vehicleType.vehicleTypeId;

        if (this.isAmg && this._selectedCarService?.modelDesign?.modelDesignId ) {
            carLineIdOrType = `amg/${this._selectedCarService.carLine.carLineId}/${this._selectedCarService.modelDesign.modelDesignId}`;
        }

        return this.httpClient.get<Article[]>(`api/highlights/all-articles/${this._translationService.currentLang}/${carLineIdOrType}?articleId=${relatedArticleId}`);

    }

    /**
     * Returns a list of wheel group highlight images that can be used for the Category Teaser Medium Component
     * @param {string} groupId Id of the main category.
     */
    getHighlightsWheelGroupImagesData(groupId: string): Observable<any[]> {
        const carLineId: string = this._selectedCarService.carLine
            ? this._selectedCarService.carLine.carLineId
            : this._selectedCarService.vehicleType.vehicleTypeId;

        return this.httpClient.get<any[]>(
            `api/highlights/wheel-group-images/${groupId}/${this._translationService.currentLang}/${carLineId}`
        );
    }

    /**
     * Gets the content from CMS for Acc or Coll WB Stage Slider
     * @param vehicleType Type of the vehicle (car or transporter)
     * @param useFallbackLang Optional. If true it will use global market "en-MB" as fallback.
     * @param isCollection Optional. Defines mode usage.
     */
    getSlides(vehicleType?: string, useFallbackLang?: boolean, isCollection?: boolean) {
        const langMarket = useFallbackLang ? 'en-MB' : this._translationService.currentLang;
        const endpoint = isCollection ? 'collection' : vehicleType;

        return this.httpClient.get<any>(`api/slider/${langMarket}/${endpoint}`);
    }

    /**
     * Get highlight image (single image) for “Single Stage Wide Component” for car or general.
     * @param groupId Id of the main category.
     */
    getMainCategoryImage(groupId: string): Observable<Image> {
        let param: string = this._selectedCarService.carLine
            ? this._selectedCarService.carLine.carLineId
            : this._selectedCarService.vehicleType.vehicleTypeId;

        // case: amg car is logged in
        if (this.isAmg && this._selectedCarService?.modelDesign?.modelDesignId) {
            param = `${this._selectedCarService.carLine.carLineId}/${this._selectedCarService.modelDesign.modelDesignId}`;
        }

        return this.httpClient
            .get<Image>(`api/highlights/group-image/${groupId}/${this._translationService.currentLang}/${param}`)
            .pipe(
                map(
                    (response: Image) => {
                        if (response) {
                            this.mainCategoryImage = response;
                            // category images always display text as white - this property is not delivered by backend for this type
                            if (!this.mainCategoryImage.textColor) {
                                this.mainCategoryImage.textColor = 'white';
                            }

                            return this.mainCategoryImage;
                        }
                    },
                    () => {
                        // Pictures are not available for every carLineId - component renders text then.
                        // In other words: Error is used here on purpose :)
                        this.mainCategoryImage = null;

                        return this.mainCategoryImage;
                    }
                )
            );
    }

    /**
     * Get the headline for the single stage wide component for main category.
     * Also displayed for sub groups of main category wheels.
     */
    getHeadlineForSingleStageWide(mainGroupName: string): string {
        if (this._selectedCarService.carClass && this._selectedCarService.bodyType) {
            return (
                mainGroupName +
                ' ' +
                this._translationService.translate('GENERAL.FOR') +
                ' ' +
                this._selectedCarService.carClass.name +
                ' ' +
                this._selectedCarService.bodyType.name
            );
        } else {
            return mainGroupName;
        }
    }

    // *** COLLECTION ***

    /**
     * Gets the content from CMS for the text teaser component on collection start page.
     */
    getCmsTextForTextTeaser(): Observable<TextTeaser> {
        return this.httpClient.get<TextTeaser>(`api/intro-texts/${this._translationService.currentLang}/collection`);
    }

    /**
     * Gets the content from CMS for the category teaser component on collection start page.
     */
    getCmsDataForCategoryTeaser(): Observable<CollectionCategoryTeaser> {
        return this.httpClient.get<CollectionCategoryTeaser>(`api/category-teaser/${this._translationService.currentLang}/collection`);
    }

    /**
     * Gets the content from CMS for the product teaser big component on collection start page.
     */
    getCmsDataForProductTeaserBig(): Observable<ProductTeaserBig> {
        return this.httpClient.get<ProductTeaserBig>(`api/product-teaser-big/${this._translationService.currentLang}/collection`);
    }

    /**
     * Gets the image data from CMS for the single image stage component on the product list page.
     * @param groupId
     */
    getCmsDataForCategoryStageTeaser(groupId: string): Observable<Image> {
        return this.httpClient.get<Image>(`api/productlist-stage/${groupId}/${this._translationService.currentLang}`);
    }
}
