// NG
import { Component, EventEmitter, Input, Output } from '@angular/core';
// model
import { ComponentTheme } from './model/ComponentTheme';
// component
import { MultiselectItemComponent } from './multiselect-item/multiselect-item.component';

@Component({
    selector: 'mba-multiselect',
    templateUrl: './multiselect.component.html',
    styleUrls: [ './multiselect.component.scss' ]
})
export class MultiselectComponent {

    // TODO: This component is old MBCS Lib component - should be replaced with new WB

    /**
     * Emits an event with the passed data for input 'eventOnActive'.
     */
    @Output() clicked: EventEmitter<MultiselectItemComponent[]> = new EventEmitter<MultiselectItemComponent[]>();

    /**
     * Change theme (light, grey, dark). Default is light.
     */
    @Input() theme: ComponentTheme = ComponentTheme.LIGHT;

    /**
     * Optional behaviour that automatically deselects all options when all options are selected.
     * Useful if search/filter logic follows the pattern "no selected option equals all options selected".
     * Provides better UX in this case. Default is false.
     */
    @Input() allSelectedEqualsNoSelection: boolean = false;

    multiselectItems: MultiselectItemComponent[] = [];
    multiselectItemsActive: MultiselectItemComponent[] = [];

    constructor() {}

    /**
     * Adds the multiselect
     * @param multiselectItem MultiselectItem to add
     */
    addItem(multiselectItem: MultiselectItemComponent) {
        if (multiselectItem) {
            // Fill the list which is being iterated through in the template
            multiselectItem.theme = this.theme;
            this.multiselectItems.push(multiselectItem);
        }
    }

    /**
     * Removes the multiselect
     * @param multiselectItem MultiselectItem to remove
     */
    removeItem(multiselectItem: MultiselectItemComponent) {
        if (multiselectItem) {
            const idx: number = this.multiselectItems.findIndex(x => x === multiselectItem);
            if (idx > -1) {
                this.multiselectItems.splice(idx, 1);
            }
        }
    }

    /**
     * Toggles selected
     * @param multiselectItem MultiselectItemComponent
     */
    toggleItem(multiselectItem: MultiselectItemComponent) {
        if (multiselectItem) {
            multiselectItem.isSelected = !multiselectItem.isSelected;
            this.multiselectItemsActive = this.multiselectItems.filter(x => x.isSelected === true);

            // reset if activated
            if (this.allSelectedEqualsNoSelection && this.multiselectItemsActive.length === this.multiselectItems.length) {
                this.multiselectItems.forEach(x => x.isSelected = false);
                this.multiselectItemsActive = [];
            }

            // inform who´s interested in
            this.clicked.emit(this.multiselectItemsActive);
        }
    }
}
