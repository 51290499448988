// ng
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from "rxjs";
// services
import { AppService } from '../../../app.service';
import { TranslationService } from '@shared/shared-services/translate/translation.service';
import { SelectedCarService } from "@shared/shared-services/selected-car/selected-car.service";
import { SearchService } from "@shared/header/header-base/search.service";
import { ViewportService } from "@shared/shared-services/viewport/viewport.service";

// model
import { MODE } from '@shared/global-models/mode.enum';
import { SearchResult } from "@shared/global-models/search-result.model";
import { ArticlesPaginated, WheelArticlesPaginated } from "@shared/global-models/article/articlePaginated.model";

@Component({
    selector: 'zk-search-results-list-page',
    templateUrl: './search-results-list-page.component.html',
    styleUrls: ['./search-results-list-page.component.scss']
})
export class SearchResultsListPageComponent implements OnInit, OnDestroy {
    @Input()
    term: string = '';

    @Input()
    set searchResult (val: SearchResult) {
        this.accArticles = val.articles;
        this.wheelArticles = val.wheels;
        this._searchResult = val;

        this.updateHeadline();
        this.updateTeaserBannerText();
        this.checkWhichCategoryHasResult();
    }

    get searchResult(): SearchResult {
        return this._searchResult;
    }

    headline = '';
    tabHeadlineWheels = this._translationService.translate('WHEELS.LIGHTALLOY');
    tabHeadlineAcc = this._translationService.translate('HEADER.ACCESSORIES_MODE');
    isMobile = false;
    isAccessoriesMode = true;
    activeTab = '';
    accArticles: ArticlesPaginated;
    wheelArticles : WheelArticlesPaginated;
    teaserBannerText = '';

    private _searchResult: SearchResult;
    private _vpSubscription: Subscription;

    constructor(private _appService: AppService,
                private _translationService: TranslationService,
                private _selectedCarService: SelectedCarService,
                private _vpService: ViewportService,
                private _searchService: SearchService
    ) {

        this.isAccessoriesMode = this._appService.appMode === MODE.ACCESSORIES;
    }

    ngOnInit(): void {
        this.isMobile = this._vpService.getCurrentViewPort() === 'mq1';
        this._vpSubscription = this._vpService.getViewportChangedObserver().subscribe((vp: string) => (this.isMobile = vp === 'mq1'));
    }

    ngOnDestroy(): void {
        this._vpSubscription.unsubscribe();
    }

    handlePagination(currentPage: number, isWheel: boolean) {
        const sub: Subscription = this._searchService.fetchNextPageOfArticles(this.term, isWheel, --currentPage, 10).subscribe(
            (response: SearchResult) => {
                sub.unsubscribe();
                isWheel ? this.wheelArticles = response.wheels : this.accArticles = response.articles;
                this._vpService.scrollTop(0, true);
            }, (error) => {
                sub.unsubscribe();
                console.log('Error: ', error);
            }
        )
    }

    /**
     * Redirects to the search results page of the respective other mode.
     */
    searchInOtherMode() {
        this._searchService.redirectToOtherSearchPage(this.isAccessoriesMode, this.term);
    }

    private updateHeadline() {
        const count = this.accArticles.articleInfo.items + this.wheelArticles.articleInfo.items;
        this.headline = count + ' ' + this._translationService.translate('SEARCH.RESULTS_FOR') + ' "' + this.term + '"';

        if (this.isAccessoriesMode && this._selectedCarService?.carLine) {
            this.headline += ' ' + this._translationService.translate('SEARCH.FOR') + ' ' + this._selectedCarService.getSelectedCarName();
        } else if (!this.isAccessoriesMode) {
            this.headline += ' ' + this._translationService.translate('SEARCH.IN') + ' ' + this._translationService.translate('HEADER.COLLECTION_MODE');
        }
    }

    private updateTeaserBannerText() {
        const count = this.isAccessoriesMode ? this._searchResult.numberOfCollectionArticles : this._searchResult.numberOfAccessoryArticles;
        this.teaserBannerText = count + ' ' + this._translationService.translate(this.isAccessoriesMode ? 'SEARCH.N_RESULTS_FOR_COLL' : 'SEARCH.N_RESULTS_ALL_VEHICLE');
    }

    /**
     * Show Accessories or Collection articles is the default (acc) because always exist in active mode.
     * Light-alloy articles (wheel) are only for ACC mode possible and also optional: market + vehicle type + carLineId dependent.
     */
    private checkWhichCategoryHasResult() {
        if (this.isAccessoriesMode && this.accArticles.articleInfo.items === 0 && this.wheelArticles.articleInfo.items > 0) {
            this.activeTab = 'wheel';
        } else if (this.isAccessoriesMode && this.wheelArticles.articleInfo.items > this.accArticles.articleInfo.items) {
            this.activeTab = 'wheel';
        } else {
            this.activeTab = 'acc';
        }
    }
}
