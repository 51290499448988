// ng
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
// services
import { ProductListService } from '@shared/shared-services/product-list/product-list.service';
import { SelectedCarService } from '@shared/shared-services/selected-car/selected-car.service';
import { TranslationService } from '@shared/shared-services/translate/translation.service';
import { GoogleAnalyticsService } from '@shared/shared-services/google-analytics/google-analytics.service';
import { ViewportService } from "@shared/shared-services/viewport/viewport.service";

// models
import { WheelFilterbar, WheelFilterTireType, WheelFilterTyrePressureControl, WheelFilterDiameter } from '../light-alloy-wheel/wheel-filter/wheel-filterbar.model';
import { Article } from '@shared/global-models/article/article.model';
import { CompleteWheelsCategory } from '@shared/global-models/wheelsCategory.model';
import { PageName } from '@shared/shared-services/google-analytics/google-analytics.model';
import { ArticleInfo } from "@shared/global-models/article/articleInfo.model";

// component
import { MultiselectItemComponent } from "@shared/components/multiselect/multiselect-item/multiselect-item.component";

@Component({
    selector: 'zk-complete-wheel',
    templateUrl: './complete-wheel.component.html',
    styleUrls: ['./complete-wheel.component.scss']
})
export class CompleteWheelComponent implements OnInit, OnDestroy {
    @Input()
    subGroupId: string;

    filters: WheelFilterbar;
    articles: Article[] = [];
    pagination: ArticleInfo;

    isLoading = false;
    isMobile = false;

    private _carSelectionSubscription: Subscription;
    private _vpSubscription: Subscription;
    private _activeFilters: WheelFilterbar = {
        diameters: [],
        tyreTypes: [],
        tyrePressureControls: []
    };

    constructor(
        private _productListService: ProductListService,
        private _selectedCarService: SelectedCarService,
        private _translationService: TranslationService,
        private _gaService: GoogleAnalyticsService,
        private _vpService: ViewportService
    ) {}

    ngOnInit(): void {
        this.isMobile = this._vpService.getCurrentViewPort() === 'mq1';
        this.getCompleteWheelArticles(0);

        // User selects first time or changes the car - reload wheel articles and filters for the new car
        this._carSelectionSubscription = this._selectedCarService.carLineSubscriber.subscribe(() => {
            this.resetActiveFilters();
            this.getCompleteWheelArticles(0)
        });

        this._vpSubscription = this._vpService.getViewportChangedObserver().subscribe((vp: string) => (this.isMobile = vp === 'mq1'));
    }

    ngOnDestroy(): void {
        this._carSelectionSubscription.unsubscribe();
        this._vpSubscription.unsubscribe();
    }

    handleFilterTyreSizeEvent(items: MultiselectItemComponent[]): void {
        this._activeFilters.diameters = [];

        items.forEach((element: MultiselectItemComponent) =>
            this._activeFilters.diameters.push({
                diameterId: element.id,
                active: true,
                // rest is not needed for comparing/filtering
                diameterTextCm: null,
                diameterTextIn: null,
                diameterText: null
            })
        );
        this.getCompleteWheelArticles(0);
    }

    handleFilterTyrePressureSystemEvent(items: MultiselectItemComponent[] | any): void {
        this._activeFilters.tyrePressureControls = [];

        items.forEach((element) =>
            this._activeFilters.tyrePressureControls.push({
                hasPressure: element.id,
                active: true,
                resourceKey: element.title
            })
        );
        this.getCompleteWheelArticles(0);
    }

    handleFilterTyreTypeEvent(items: MultiselectItemComponent[]): void {
        this._activeFilters.tyreTypes = [];

        items.forEach((element: MultiselectItemComponent) =>
            this._activeFilters.tyreTypes.push({
                tyreType: element.id,
                active: true,
                resourceKey: element.title
            })
        );
        this.getCompleteWheelArticles(0);
    }

    handlePagination(currentPage: number) {
        this.getCompleteWheelArticles(--currentPage);
    }

    private getCompleteWheelArticles(page: number): void {
        // for better UX
        this.articles = [];
        this.isLoading = true;
        window.scrollTo(0, 0);

        const sub: Subscription = this._productListService.getCompleteWheelsWithFilter(this.subGroupId, page, 10, this._activeFilters).subscribe(
            (response: CompleteWheelsCategory) => {
                this.articles = response.articles;
                this.pagination = response.articleInfo;
                this.updateFilters(response.cwFilter);
                // clean up
                sub.unsubscribe();
                this.isLoading = false;
                // tracking
                this._gaService.trackPageView(PageName.WHEEL_SPECIAL_PAGE);
                this._gaService.trackProductImpressions(this.articles);
            },
            (error) => {
                sub.unsubscribe();
                this.isLoading = false;
                console.log('Error:', error);
            }
        );
    }

    /**
     * Sets the translation for filters "tyre pressure control" and "tyre types" since defined by Localization service.
     * Exception: "Rim diameter" filter arrives already in language/market specific formatted way by BE.
     * Sets the new filter data to active if it was active before.
     * @param response
     */
    private updateFilters(response: WheelFilterbar): void {
        response.tyrePressureControls.forEach((x: WheelFilterTyrePressureControl) => {
            x.resourceKey = this._translationService.translate(x.resourceKey.toUpperCase());
            x.active = this.setLastUsedFilter('tyrePressureControls', x);
        });

        response.tyreTypes.forEach((x: WheelFilterTireType) => {
            x.resourceKey = this._translationService.translate(x.resourceKey.toUpperCase());
            x.active = this.setLastUsedFilter('tyreTypes', x);
        });

        response.diameters.forEach((x: WheelFilterDiameter) => {
            x.active = this.setLastUsedFilter('diameters', x);
        })

        this.filters = response;
    }

    /**
     * Sets the filter of the new data set to the state that it had before.
     * Updates the view of the filter component.
     */
    private setLastUsedFilter(filterProperty: string, item:any): boolean {
        const filterType: any = this._activeFilters[filterProperty];
        let wasActive;

        if (filterType.length > 0) {
            switch (filterProperty) {
                case 'tyreTypes':            wasActive = filterType.find(x => x.tyreType === item.tyreType); break;
                case 'tyrePressureControls': wasActive = filterType.find(x => x.resourceKey === item.resourceKey); break;
                case 'diameters':            wasActive = filterType.find(x => x.diameterId === item.diameterId); break;
            }
        }

        return !!wasActive;
    }

    /**
     * Resets all active filters.
     * Use case: On car change because we want to request from API the complete set of new articles and not limit it by previous filters.
     * @private
     */
    private resetActiveFilters() {
        this._activeFilters = {
                diameters: [],
                tyreTypes: [],
                tyrePressureControls: []
            };
        }
}
