import { Component, EventEmitter, Input, Output } from '@angular/core';
import { VehicleType } from "../models/vehicle-switch.model";

@Component({
    selector: 'zk-vehicle-type-switch',
    templateUrl: './vehicle-type-switch.component.html',
    styleUrls: ['./vehicle-type-switch.component.scss']
})
export class VehicleTypeSwitchComponent {
    @Input() vehicleTypes: VehicleType[];
    @Output() onTypeChanged = new EventEmitter<VehicleType>();

    setVehicleType(vehicleType: VehicleType) {
        this.onTypeChanged.emit(vehicleType);
    }
}
