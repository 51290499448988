// ng
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';

// services
import { SelectedCarService } from '@shared/shared-services/selected-car/selected-car.service';
import { WbMediaQueryService } from "@shared/shared-services/media-query/wb-media-query.service";
import { GoogleAnalyticsService } from "@shared/shared-services/google-analytics/google-analytics.service";

// models
import { BodyType, CarLine, ModelDesign } from "@shared/components/car-chooser/models";
import { TranslationService } from '@shared/shared-services/translate/translation.service';
import { Router } from '@angular/router';
import { MediaQuery } from "@workbench/core";



@Component({
  selector: 'zk-car-teaser',
  templateUrl: './car-teaser.component.html',
  styleUrls: ['./car-teaser.component.scss']
})
export class CarTeaserComponent implements OnInit, OnDestroy {
	carLine: CarLine | ModelDesign;
	environment = environment;
    isMobile: boolean;

    @Input() showActionButton = true;

	private _carLineSubscription: Subscription;
    private _mediaQuerySub: Subscription;

	constructor(private _selectedCarService: SelectedCarService,
                private _translationService: TranslationService,
                private _mediaQueryService: WbMediaQueryService,
                private _gaService: GoogleAnalyticsService,
                private _router: Router) { }

	ngOnInit(): void {
	 	this.setCarLinePreviewImage();

		// react to car change
		this._carLineSubscription = this._selectedCarService.carLineSubscriber.subscribe((value: CarLine) => {
			if (value) {
				this.setCarLinePreviewImage();
			}
		});

        this._mediaQuerySub = this._mediaQueryService.mediaQuery.subscribe((mq: MediaQuery) => {
            this.isMobile = mq === 'mq1' || mq === 'mq2';
        });
	}

	ngOnDestroy() {
        this._carLineSubscription.unsubscribe();
        this._mediaQuerySub.unsubscribe();
	}


	/**
	 * Set the most recent car line silhouette image - Car line or model design (AMG)
	 */
	private setCarLinePreviewImage() {
		const car: BodyType = this._selectedCarService.bodyType;
		if (this._selectedCarService.vehicleType.vehicleTypeId === 'amg') {
			this.carLine = car.modelDesigns[0];
		} else {
			this.carLine = car.carLines[0];
		}
	}

    openModalStartPage(position: string) {
        const url = "/" + this._translationService.currentLang + this._selectedCarService.vehicleType.urlPart + '/' + this._selectedCarService.carLine.urlName;
        this._router.navigate([url]).then();
        this._gaService.trackModelStartPageNavigate(position);
    }
}
