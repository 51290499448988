import { Component, Input } from '@angular/core';

@Component({
    selector: 'zk-highlights-teaser',
    templateUrl: './highlights-teaser.component.html',
    styleUrl: './highlights-teaser.component.scss'
})
export class HighlightsTeaserComponent {
    @Input() showActionButton: boolean = true;
}
